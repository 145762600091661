import {BssNoteFragment} from "../../../lib";
import {createContext, PropsWithChildren, useContext, useState} from "react";
import {BssNoteItem} from "../../state/bssTypes/bssTypes";

type NotesDrawerContext = {
    filter?: Partial<BssNoteFragment>
    list?: BssNoteItem[]
    currentEditMessage?: BssNoteFragment | null
    setMessage: (message: BssNoteFragment | null) => void
    isAllNotes?: boolean
    triggerClose: () => void
}
export const NotesContext = createContext<NotesDrawerContext>({} as NotesDrawerContext)

export const useNotesContext = () => useContext(NotesContext)

export function NotesContextProvider({children, options, list, isAllNotes, triggerClose}: PropsWithChildren<{
    options?: Partial<BssNoteFragment>
    list?: BssNoteItem[]
    isAllNotes?: boolean
    triggerClose: () => void
}>) {
    const [currentEditMessage, setMessage] = useState<BssNoteFragment | null>()
    return (
        <NotesContext.Provider value={{
            filter: options,
            currentEditMessage,
            setMessage,
            list,
            isAllNotes,
            triggerClose
        }}>
            {children}
        </NotesContext.Provider>
    )
}