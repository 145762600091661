import {useTranslation} from "react-i18next";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Stack, Table, TableBody, TableContainer, Typography} from "@mui/material";
import {Project_Bss_Data_Type_Enum} from "../../../lib";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";

type CropsTableOverviewExpenditureProps = {
    seasonId: string
}

export function CropsTableOverviewExpenditure({seasonId}: CropsTableOverviewExpenditureProps) {
    const {cropsBySeason} = useBaselineOverviewContext();
    const {t} = useTranslation();
    const expenditureData = cropsBySeason[seasonId]?.[Project_Bss_Data_Type_Enum.CropsExpenditure] ?? {}
    return (
        <Stack spacing={2} marginTop={2}>
            <Typography variant={'subtitle2'}>
                {t('crops.expenditure')}
            </Typography>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        {Object.keys(expenditureData).map((key, index) => {
                            const {data, term} = expenditureData[key]
                            return (
                                <BaselineTableRow key={key}
                                                  type={Project_Bss_Data_Type_Enum.CropsExpenditure}
                                                  term={term}
                                                  seasonId={seasonId}
                                                  isEven={index % 2 === 0} data={data}/>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}