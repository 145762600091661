import {PopperContainer} from "../../common/popper/PopperContainer";
import {FormControlLabel, FormGroup, Stack, Switch} from "@mui/material";
import {useBssDefaults} from "../state/bssState";
import {Settings} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {BssNotesDrawer} from "./notes/BssNotesDrawer";

function HideResolvedSwitch() {
    const {hideResolved, setHideResolved} = useBssDefaults()
    const {t} = useTranslation()
    return (
        <FormGroup>
            <FormControlLabel value={hideResolved}
                              control={<Switch checked={hideResolved} onChange={(_, checked) => {
                                  setHideResolved(checked)
                              }}/>} label={t('hideResolved')}/>
        </FormGroup>
    )
}

export function BaselineOverviewSettings() {
    return (
        <Stack direction={'row'} justifyContent={'flex-end'} marginBottom={2} spacing={2}>
            <BssNotesDrawer/>
            <BssNotesDrawer isAllNotes={true}/>
            <PopperContainer variant={'popover'} trigger={{
                children: 'Settings',
                color: 'inherit',
                startIcon: <Settings/>
            }}>
                <Stack spacing={2} padding={2}>
                    <HideResolvedSwitch/>
                </Stack>
            </PopperContainer>
        </Stack>
    )
}