import {BssBaselineDetailQuery, Project_Bss_Data_Type_Enum, Project_Interview_Data_Type_Enum} from "../../../lib";
import groupBy from "lodash/groupBy";
import {processDataRow} from "../baselineGroupHelper";
import {staticTermId} from "../staticTermId";
import {BaselineOverviewContextProps} from "../baselineOverviewTypes";

const groupData = ({data, interviewCountByWealthGroup, type}: {
    data: BssBaselineDetailQuery['bss_baseline_view'][number]['data']
    type: Project_Bss_Data_Type_Enum
    interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']
}) => {
    const grouped = groupBy(data, 'project_interview_base.project_interview.wealth_group_id')
    return Object.keys(grouped).reduce((prev, wealthGroupId) => {
        return {
            ...prev,
            [wealthGroupId]: processDataRow({
                data: grouped[wealthGroupId] as any,
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount,
                type
            })
        }
    }, {})
}

type PrepareBssData = {
    bss_baseline_view: BssBaselineDetailQuery['bss_baseline_view']
    interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']
    schoolfeeding_data: BssBaselineDetailQuery['schoolfeeding_data']
    migration_data: BssBaselineDetailQuery['migration_data']
};

export function prepareBssData({
                                   bss_baseline_view,
                                   interviewCountByWealthGroup,
                                   schoolfeeding_data,
                                   migration_data
                               }: PrepareBssData) {
    // all baseline view data
    const groupedByTypeData = groupBy(bss_baseline_view, 'type') //as BaselineOverviewContextProps['dataByType']
    const mergedData: {
        [type in Project_Bss_Data_Type_Enum]: {
            [termId: string]: {
                term: BssBaselineDetailQuery['bss_baseline_view'][0]['term']
                type: Project_Bss_Data_Type_Enum
                data: any
            }
        }
    } = {} as any


    Object.keys(groupedByTypeData).forEach((type) => {
        const currentType = type as Project_Interview_Data_Type_Enum
        const groupedData = groupedByTypeData[currentType]
        groupedData.forEach(item => {
            if (currentType === Project_Interview_Data_Type_Enum.RemittancesCredit) {
                // need to split in income and expenditure
                const expenditureData = item.data.filter(d => d.is_expenditure)
                const incomeData = item.data.filter(d => !d.is_expenditure)

                if (expenditureData.length) {
                    mergedData[Project_Bss_Data_Type_Enum.RemittancesCreditExpenses] = mergedData[Project_Bss_Data_Type_Enum.RemittancesCreditExpenses] || {}
                    mergedData[Project_Bss_Data_Type_Enum.RemittancesCreditExpenses][staticTermId.REMITTANCES_CREDIT_EXPENSES] = {
                        term: item.term,
                        type: Project_Bss_Data_Type_Enum.RemittancesCreditExpenses,
                        data: groupData({
                            data: expenditureData,
                            interviewCountByWealthGroup: interviewCountByWealthGroup,
                            type: Project_Bss_Data_Type_Enum.RemittancesCreditExpenses
                        })
                    }
                }
                if (incomeData.length) {
                    mergedData[Project_Bss_Data_Type_Enum.RemittancesCreditIncome] = mergedData[Project_Bss_Data_Type_Enum.RemittancesCreditIncome] || {}
                    mergedData[Project_Bss_Data_Type_Enum.RemittancesCreditIncome][staticTermId.REMITTANCES_CREDIT_INCOME] = {
                        term: item.term,
                        type: Project_Bss_Data_Type_Enum.RemittancesCreditIncome,
                        data: groupData({
                            data: incomeData,
                            interviewCountByWealthGroup,
                            type: Project_Bss_Data_Type_Enum.RemittancesCreditIncome
                        })
                    }
                }
                return
            }
            if (type === Project_Bss_Data_Type_Enum.FoodAid && !item.term) {
                mergedData[Project_Bss_Data_Type_Enum.FoodAid] = mergedData[Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding] || {}
                mergedData[Project_Bss_Data_Type_Enum.FoodAid][staticTermId.FOOD_AID_SCHOOL_FEEDING] = {
                    term: {
                        id: staticTermId[Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding],
                        en: 'School Feeding',
                        term_category_mns: []
                    },
                    type: Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding,
                    data: groupData({
                        data: schoolfeeding_data,
                        interviewCountByWealthGroup,
                        type: Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding
                    })
                }
                return;
            }
            if (type === Project_Bss_Data_Type_Enum.Migration) {
                mergedData[Project_Bss_Data_Type_Enum.Migration] = mergedData[Project_Bss_Data_Type_Enum.Migration] || {}
                mergedData[Project_Bss_Data_Type_Enum.Migration][staticTermId.MIGRATION] = {
                    term: {
                        id: staticTermId[Project_Bss_Data_Type_Enum.Migration],
                        en: 'Migration',
                        term_category_mns: []
                    },
                    type: Project_Bss_Data_Type_Enum.Migration,
                    data: groupData({
                        data: migration_data,
                        type: Project_Bss_Data_Type_Enum.Migration,
                        interviewCountByWealthGroup
                    })
                }
                return
            }
            mergedData[currentType] = mergedData[currentType] || {}
            mergedData[currentType][item.term_id] = {
                term: item.term,
                type: type as Project_Bss_Data_Type_Enum,
                data: groupData({
                    data: item.data,
                    interviewCountByWealthGroup,
                    type: type as Project_Bss_Data_Type_Enum
                })
            }
        })
    })
    return mergedData
}