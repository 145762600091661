import {useTranslation} from "react-i18next";
import {SingleProject} from "../overviewTypes";
import {CardRow} from "./CardRowItem";

type MaleFemaleCalculationProps = {
    interviews: SingleProject['project_interviews']
}

export function MaleFemaleCalculation({interviews = []}: MaleFemaleCalculationProps) {
    const {t} = useTranslation()
    const counts = interviews.reduce(
        (accumulator, obj) => {
            const interviewees_male = obj?.project_interview_base?.interviewees_male ?? 0;
            const interviewees_female = obj?.project_interview_base?.interviewees_female ?? 0;
            return {
                maleCount: accumulator.maleCount + interviewees_male,
                femaleCount: accumulator.femaleCount + interviewees_female
            };
        },
        {maleCount: 0, femaleCount: 0}
    );
    const interviewsCount = interviews.length
    const totalCount = counts.femaleCount + counts.maleCount;
    const malePercentage = (counts.maleCount / totalCount) * 100;
    const femalePercentage = (counts.femaleCount / totalCount) * 100;
    const maleAverage = Math.round(counts.maleCount / interviewsCount);
    const femaleAverage = Math.round(counts.femaleCount / interviewsCount);
    return (
        <>
            <CardRow left={`${t('femaleInterviewees')} (${femalePercentage ? femaleAverage : 0})`}
                     right={femalePercentage ? femalePercentage.toFixed(1) + '%' : '-'}/>
            <CardRow left={`${t('maleInterviewees')} (${malePercentage ? maleAverage : 0})`}
                     right={malePercentage ? malePercentage.toFixed(1) + '%' : '-'}/>
        </>
    )
}