import {Button, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import {CONFIG} from "../../lib/config";
import {useTranslation} from "react-i18next";
import {SingleProject} from "./overviewTypes";
import {CardRow} from "./partials/CardRowItem";
import {MaleFemaleCalculation} from "./partials/MaleFemaleCalculation";
import {ResolveButtonBssList} from "./partials/OfflineStatusBssItem";

type BssProjectListItemProps = {
    project: SingleProject
}


export function BssProjectListItem({project}: BssProjectListItemProps) {
    const {t} = useTranslation()
    const totalCount = project.interviewsTotal?.aggregate?.count;
    const lockedCount = project.interviewsLocked?.aggregate?.count ?? 0;
    const isResolvable = lockedCount > 0 && totalCount === lockedCount;
    const reviewCount = project.interviewsDone?.aggregate?.count ?? 0;
    return (
        <Card variant={'outlined'}>
            <CardHeader
                subheader={(
                    <>
                        <Typography variant={'h4'}>
                            {project.interviewZone?.[0]?.location?.zone?.name}
                        </Typography>
                        <Typography variant={'body1'} fontWeight={'bold'} color={'black'}>
                            {project.user?.user_profile?.first_name} {project?.user?.user_profile?.last_name}
                        </Typography>
                    </>
                )}
                titleTypographyProps={{variant: 'subtitle2'}}
                title={project.interviewZone?.[0]?.location.country_region.country.en}/>
            <CardContent sx={{paddingTop: 0}}>
                <Stack spacing={2} marginBottom={2}>
                    <Button fullWidth={true} variant={'outlined'}
                            color={'success'}
                            disabled={isResolvable}
                            href={`${CONFIG.tssDomain}/baseline?id=${project.id}`}
                            target={'_blank'}>{t('review')} ({reviewCount})</Button>
                    <ResolveButtonBssList projectId={project.id}/>
                </Stack>
                <CardRow left={t('teamleader')}
                         right={project.userTeamleader ? `${project.userTeamleader?.user_profile?.first_name} ${project?.userTeamleader?.user_profile?.last_name}` : '-'}/>
                <CardRow left={t('locations')}
                         right={project.locationTotal.aggregate?.count}/>

                <CardRow left={t('interviews')}
                         right={totalCount}/>
                <CardRow left={t('complete')}
                         right={`${lockedCount} / ${totalCount}`}/>

                <CardRow left={'For review'}
                         right={`${reviewCount} / ${totalCount}`}/>
                <MaleFemaleCalculation interviews={project.project_interviews}/>
            </CardContent>
        </Card>
    )
}