import {Stack, Table, TableBody, TableContainer} from "@mui/material";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum} from "../../../lib";
import {useTranslation} from "react-i18next";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {LivestockTotalHerdCalculated} from "./LivestockTotalHerdCalculated";
import {hasSecondaryMilkOnLivestockType, hideMilkOnLivestockType} from "../../../tss/livestock/steps/helpers/calculate";
import {staticTermId} from "../staticTermId";
import {headlineTranslationMapping} from "../translationHelper";

export function LivestockHerdSlaughter({livestockType}: { livestockType: Livestock_Type_Enum }) {
    const {t} = useTranslation()
    const {livestock} = useBaselineOverviewContext()
    const groupedBaseData = livestock.grouped[livestockType]?.base
    return (
        <Stack spacing={2}>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>

                        <BaselineTableRow type={Project_Bss_Data_Type_Enum.LivestockHerd}
                                          livestock_type={livestockType}
                                          term={{
                                              id: staticTermId[Project_Bss_Data_Type_Enum.LivestockHerd],
                                              en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.LivestockHerd])
                                          }} isEven={false}
                                          data={groupedBaseData?.[Project_Bss_Data_Type_Enum.LivestockHerd]}
                                          TopRow={<LivestockTotalHerdCalculated livestockType={livestockType}/>}
                        />

                        {!hideMilkOnLivestockType(livestockType) && (
                            <>
                                <BaselineTableRow type={Project_Bss_Data_Type_Enum.MilkProductionPeak}
                                                  livestock_type={livestockType}
                                                  term={{
                                                      id: staticTermId[Project_Bss_Data_Type_Enum.MilkProductionPeak],
                                                      en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.MilkProductionPeak])
                                                  }} isEven={false}
                                                  data={groupedBaseData?.[Project_Bss_Data_Type_Enum.MilkProductionPeak]}
                                />
                                <BaselineTableRow type={Project_Bss_Data_Type_Enum.MilkProductionOffPeak}
                                                  livestock_type={livestockType}
                                                  term={{
                                                      id: staticTermId[Project_Bss_Data_Type_Enum.MilkProductionOffPeak],
                                                      en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.MilkProductionOffPeak])
                                                  }} isEven={false}
                                                  data={groupedBaseData?.[Project_Bss_Data_Type_Enum.MilkProductionOffPeak]}
                                />
                                {hasSecondaryMilkOnLivestockType(livestockType) && (
                                    <>
                                        <BaselineTableRow
                                            type={Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary}
                                            livestock_type={livestockType}
                                            term={{
                                                id: staticTermId[Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary],
                                                en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary])
                                            }} isEven={false}
                                            data={groupedBaseData?.[Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]}
                                        />
                                        <BaselineTableRow
                                            type={Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary}
                                            livestock_type={livestockType}
                                            term={{
                                                id: staticTermId[Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary],
                                                en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary])
                                            }} isEven={false}
                                            data={groupedBaseData?.[Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        <BaselineTableRow type={Project_Bss_Data_Type_Enum.LivestockSlaughter}
                                          livestock_type={livestockType}
                                          term={{
                                              id: staticTermId[Project_Bss_Data_Type_Enum.LivestockSlaughter],
                                              en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.LivestockSlaughter])
                                          }} isEven={true}
                                          data={groupedBaseData?.[Project_Bss_Data_Type_Enum.LivestockSlaughter]}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}