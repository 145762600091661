import {Badge, Button, Drawer, FormControlLabel, FormGroup, IconButton, Stack, Switch} from "@mui/material";
import {Comment, Message, SpeakerNotes} from "@mui/icons-material";
import {PropsWithChildren, useState} from "react";
import {BssNotesContent} from "./BssNotesContent";
import {useTranslation} from "react-i18next";
import {BssNoteFragment} from "../../../lib";
import {useBssComments} from "../../state/bssState";
import {NotesContextProvider} from "./NotesContextProvider";


type BssNotesProps = {
    options?: Partial<BssNoteFragment>
    insideDialog?: boolean
    insideTableRow?: boolean
    isAllNotes?: boolean
};

function BssNotesDrawerContainer({
                                     children,
                                     options,
                                     insideTableRow,
                                     isAllNotes
                                 }: PropsWithChildren<BssNotesProps>) {
    const [showResolved, setShowResolved] = useState(false)
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()
    const list = useBssComments({...options, showAll: isAllNotes, showResolved})

    return (
        <>
            {insideTableRow ? list?.length ? (
                <IconButton size={'small'} color={'inherit'} onClick={() => setOpen(val => !val)}>
                    <Badge badgeContent={list?.length} color={'warning'} variant={'dot'}>
                        <Comment fontSize={'small'}/>
                    </Badge>
                </IconButton>
            ) : null : (
                <Button color={'inherit'} startIcon={isAllNotes ? <Message/> : <SpeakerNotes/>}
                        onClick={() => setOpen(val => !val)}>
                    <Badge badgeContent={list?.length} color={'warning'}>
                        {isAllNotes ? t('bssNotesAll') : (options?.type ? t('bssNotesGeneral') : t('bssNotes'))}
                    </Badge>
                </Button>
            )}
            <Drawer anchor={'bottom'}
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        zIndex: 10000
                    }}
            >
                <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} paddingRight={2}
                       paddingTop={1}>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            checked={showResolved}
                            onChange={(_, checked) => {
                                setShowResolved(checked)
                            }}/>} label={t('showResolved')} labelPlacement={'start'}/>
                    </FormGroup>
                </Stack>
                <NotesContextProvider options={options}
                                      list={list}
                                      isAllNotes={isAllNotes}
                                      triggerClose={() => setOpen(false)}
                >
                    <Stack padding={2}>
                        {children}
                    </Stack>
                </NotesContextProvider>
            </Drawer>
        </>
    )
}

export function BssNotesDrawer(props: BssNotesProps) {
    return (
        <BssNotesDrawerContainer {...props}>
            <BssNotesContent/>
        </BssNotesDrawerContainer>
    )
}