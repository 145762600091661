import {Button, DialogActions} from "@mui/material";
import {useBaselineWorkbook} from "./BaselineCsvWorkbookProvider";
import {Delete, Download} from "@mui/icons-material";

export function BaselineCsvDIalogActions() {

    return (
        <DialogActions sx={{gap: 2}}>
            <DownloadXlsxButton/>
        </DialogActions>
    )
}


function DownloadXlsxButton() {
    const {selectedResolver, unsetSelection, getExcelDataBlob} = useBaselineWorkbook()
    if (!selectedResolver?.length) {
        return null
    }
    return <>
        <Button onClick={() => unsetSelection()} variant={"outlined"} color={"error"} startIcon={<Delete/>}>Clear
            Table</Button>
        <Button
            variant={"contained"}
            startIcon={<Download/>}
            onClick={async () => {
                const blob = await getExcelDataBlob()
                // const buffer = await workbook.xlsx.writeBuffer();
                // const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            }}>download xlsx</Button></>
}