import {PopperContainer} from "../../../common/popper/PopperContainer";
import {TableChart} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {BaselineSummarizeTableContent} from "./BaselineSummarizeTableContent";

export function BaselineSummarizeTable() {
    const {t} = useTranslation()
    return (
        <PopperContainer trigger={{
            color: 'inherit',
            startIcon: <TableChart/>,
            children: t('bssSummarize')
        }}
                         dialogTitle={t('bssSummarize')}
                         appBarProps={{
                             color: 'transparent'
                         }}
                         dialogProps={{
                             PaperProps: {
                                 sx: {
                                     maxWidth: 'inherit',

                                 }
                             }
                         }}>
            <BaselineSummarizeTableContent/>
        </PopperContainer>
    )
}