import {
    FormControlLabel,
    FormGroup,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {ResolveDataSectionProps} from "../baselineOverviewTypes";
import {useTranslation} from "react-i18next";
import {SwitchElement} from "react-hook-form-mui";
import {Fragment, useState} from "react";
import {UnfoldLess, UnfoldMore} from "@mui/icons-material";
import {ResolveDataSectionDetail} from "./ResolveDataSectionDetail";
import {SwitchStartAverages} from "./partials/SwitchStartAverages";
import {DisableWealthGroupSwitch} from "./DisableWealthGroupSwitch";
import {useDisabledHook} from "./useDisabledHook";
import {ComputedReadonlyFields} from "./ComputedReadonlyFields";
import {GroupColumnsOnType} from "../baselineDataFields";
import {TextFieldNumberFormat} from "../../../common/form/TextFieldNumberFormat";
import {DividerAfterColName, getDataField} from "../baselineGroupHelper";

const fixedCellWidth = 140

export function ResolveDataSection({
                                       groupedByWealthGroupData,
                                       type,
                                       term,
                                       livestock_type,
                                       stitchedElements
                                   }: ResolveDataSectionProps) {
    const {t} = useTranslation()
    const {wealthGroups} = useBaselineOverviewContext()
    const [openCell, setOpenCell] = useState<{ [k: string]: boolean }>({})
    const disabledWealthGroups = useDisabledHook()

    // const [disabledWealthGroup, setDisabled] = useState<{ [k: string]: boolean }>(initialDisabled)
    const [showOneCard, setShowOneCard] = useState<boolean>(true)
    const [outliers, setOutliers] = useState<number>(1)
    const showAsBoolean = (col: string) => col === 'get_skim_bool'

    return (
        <Stack spacing={2}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked onChange={(_, checked) => {
                        setShowOneCard(checked)
                    }}/>} label="Show One Card View"/>
                </FormGroup>
                <SwitchStartAverages/>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography>Remove outliers</Typography>
                    <RadioGroup
                        row
                        aria-labelledby="remove outliers radio group"
                        name="row-radio-buttons-group"
                        value={`${outliers}`}
                        onChange={(_, value) => {
                            setOutliers(Number(value))
                        }}
                    >
                        <FormControlLabel value="0" control={<Radio/>} label="0"/>
                        <FormControlLabel value="1" control={<Radio/>} label="1"/>
                        <FormControlLabel value="2" control={<Radio/>} label="2"/>
                    </RadioGroup>
                </Stack>
            </Stack>
            <TableContainer>
                <Table size={'small'}>
                    <TableHead>
                        {!showOneCard && (
                            <TableRow>
                                <TableCell colSpan={wealthGroups.length} align="center"
                                           sx={{
                                               bgcolor: 'success.main',
                                               color: 'white',
                                               borderRadius: '12px 12px 0px 0px'
                                           }}
                                >
                                    <Typography variant={'h4'}>
                                        Results
                                    </Typography>
                                </TableCell>
                                <TableCell>

                                </TableCell>
                                <TableCell colSpan={wealthGroups.length} align="center" sx={{
                                    bgcolor: 'primary.main',
                                    color: 'white',
                                    borderRadius: '12px 12px 0px 0px'
                                }}>
                                    <Typography variant={'h4'}>
                                        Raw Data
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            {!showOneCard && wealthGroups.map(item => (
                                <TableCell key={item.id} align={'center'}>
                                    {item.short}
                                </TableCell>
                            ))}
                            <TableCell/>
                            {wealthGroups.map(wealthGroup => (
                                <TableCell key={wealthGroup.id} align={'center'}>
                                    <Stack alignItems={'center'}>

                                        <Typography variant={'body1'} fontWeight={'bold'}>
                                            {wealthGroup.short}&nbsp;<Typography
                                            variant={'caption'}>({groupedByWealthGroupData[wealthGroup.id]?.data?.length ?? 0})</Typography>
                                        </Typography>
                                        <DisableWealthGroupSwitch wealthGroupId={wealthGroup.id} type={type}/>
                                    </Stack>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {GroupColumnsOnType[type]?.['large']
                            .filter(columnName => !GroupColumnsOnType[type]?.computed.find(i => i.name === columnName))
                            .map((columnName, index) => (
                                <Fragment key={columnName}>
                                    <TableRow sx={{
                                        bgcolor: index % 2 === 0 ? 'rgba(0,0,0,0.025)' : undefined,
                                    }}>
                                        {!showOneCard && wealthGroups.map((wealthGroup, wealthGroupIndex) => (
                                            <TableCell key={wealthGroup.id} width={fixedCellWidth}
                                                       sx={{borderBottom: DividerAfterColName.includes(columnName) ? '1px solid black' : undefined}}>
                                                {showAsBoolean(columnName) ? (
                                                    <SwitchElement
                                                        label={''}
                                                        name={`${wealthGroup.id}.${columnName}`}
                                                    />
                                                ) : (
                                                    <TextFieldNumberFormat
                                                        name={`${wealthGroup.id}.${columnName}`}
                                                        type={'number'}
                                                        size={'small'}
                                                        inputProps={{
                                                            tabIndex: index + ((wealthGroupIndex + 1) * 10)
                                                        }}
                                                        disabled={disabledWealthGroups[wealthGroup.id]}
                                                    />
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell align={'center'} variant={'head'}
                                                   sx={{borderBottom: DividerAfterColName.includes(columnName) ? '1px solid black' : undefined}}>
                                            <Stack direction={'row'} justifyContent={'space-between'}
                                                   alignItems={'center'}>
                                                <span/>
                                                <span>{t('rowType.' + columnName)}</span>
                                                <IconButton size={'small'}
                                                            onClick={() => {
                                                                setOpenCell(prevState => ({
                                                                    ...prevState,
                                                                    [columnName]: !prevState[columnName]
                                                                }))
                                                            }}>
                                                    {openCell[columnName] ? <UnfoldLess/> : <UnfoldMore/>}
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                        {wealthGroups.map((wealthGroup, wealthGroupIndex) => (
                                            <TableCell key={wealthGroup.id} align={'center'}
                                                       sx={{borderBottom: DividerAfterColName.includes(columnName) ? '1px solid black' : undefined}}>
                                                <Stack spacing={1} direction={'column'} alignItems={'center'}>
                                                    {showOneCard && !disabledWealthGroups[wealthGroup.id] ? (
                                                        showAsBoolean(columnName) ? (
                                                            <SwitchElement
                                                                label={''}
                                                                name={`${wealthGroup.id}.${columnName}`}
                                                            />
                                                        ) : (
                                                            <TextFieldNumberFormat
                                                                name={`${wealthGroup.id}.${columnName}`}
                                                                type={'number'}
                                                                size={'small'}
                                                                inputProps={{
                                                                    tabIndex: index + ((wealthGroupIndex + 1) * 10)
                                                                }}
                                                                sx={{width: fixedCellWidth}}
                                                            />
                                                        )
                                                    ) : (
                                                        <span/>
                                                    )}
                                                    <Typography
                                                        fontWeight={disabledWealthGroups[wealthGroup.id] ? 'lighter' : 'normal'}
                                                        variant={disabledWealthGroups[wealthGroup.id] ? 'caption' : 'body2'}>
                                                        {getDataField(groupedByWealthGroupData[wealthGroup.id]?.[columnName]?.[`avg${outliers}` as 'avg1'], columnName)}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {openCell[columnName] && (
                                        <ResolveDataSectionDetail groupedByWealthGroupData={groupedByWealthGroupData}
                                                                  showOneCard={showOneCard}
                                                                  dataName={columnName}/>
                                    )}
                                </Fragment>
                            ))}
                        <ComputedReadonlyFields type={type}
                                                term={term}
                                                livestock_type={livestock_type}
                                                showOneCard={showOneCard}
                                                disabledWealthGroups={disabledWealthGroups}
                                                outliers={outliers}
                                                groupedByWealthGroupData={groupedByWealthGroupData}/>

                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}