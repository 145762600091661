import {useBaselineWorkbook} from "./BaselineCsvWorkbookProvider";
import {
    Alert,
    Card,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export function BaselineCsvCopyTable() {
    const {t} = useTranslation()
    const {selectedResolver, removeSelected, selectedHeaders} = useBaselineWorkbook()
    if (!selectedResolver.length) {
        return (
            <Card>
                <Alert severity={"info"}>
                    {t('bssBaselineCsvCopyTableNoData')}
                </Alert>
            </Card>
        )
    }

    return (
        <Card sx={{height: "100%"}}>
            <TableContainer sx={{height: "100%"}}>
                <Table size={"small"} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {selectedHeaders[0]?.map((cell, index) => {
                                return (
                                    <>
                                        <TableCell key={index}
                                                   sx={{bgcolor: "#fff", zIndex: 3}}
                                                   variant={"head"}
                                                   align={"center"}>
                                            <Stack>
                                                <Typography variant={"caption"}>
                                                    {cell}
                                                </Typography>
                                                <Typography variant={"body2"} whiteSpace={"nowrap"}>
                                                    {selectedHeaders[1][index]}
                                                </Typography>
                                            </Stack>

                                        </TableCell>
                                    </>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedResolver.map((item, sectionIndex) => {
                            return item.arrayData.map((row, rowIndex) => {
                                return (
                                    <TableRow key={rowIndex}
                                              sx={{bgcolor: sectionIndex % 2 === 0 ? undefined : "rgba(0,0,0,0.08)"}}>
                                        {row.map((cell, cellIndex) => {
                                            // const isStickyRow = sectionIndex === 0 && (rowIndex === 0 || rowIndex === 1);
                                            const isStickyColumn = cellIndex === 0;
                                            const showActions = cellIndex === 0 && rowIndex === 0;
                                            return (
                                                <TableCell
                                                    key={cellIndex}
                                                    align={cellIndex === 0 ? "right" : "center"}
                                                    sx={{
                                                        position: isStickyColumn ? 'sticky' : undefined,
                                                        // top: isStickyRow ? 0 : undefined,
                                                        left: isStickyColumn ? 0 : undefined,
                                                        zIndex: isStickyColumn ? 2 : 1,
                                                        // backgroundColor: isStickyRow ? '#fff' : undefined,
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        boxShadow: isStickyColumn ? '2px 0px 2px -1px rgba(0,0,0,0.1)' : undefined
                                                    }}
                                                >
                                                    {cell}
                                                    {showActions && (
                                                        <Stack position={"absolute"} top={0} left={0}>
                                                            <IconButton
                                                                size={"small"}
                                                                color={"error"}
                                                                onClick={() => {
                                                                    removeSelected(sectionIndex)
                                                                }}><Delete/></IconButton>
                                                        </Stack>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}