import {useController, useFormContext} from "react-hook-form-mui";
import {Switch} from "@mui/material";
import {GroupColumnsOnType} from "../baselineDataFields";
import {BaselineDataOverviewType} from "../baselineOverviewTypes";

export function DisableWealthGroupSwitch({wealthGroupId, type}: {
    wealthGroupId: string,
    type: BaselineDataOverviewType
}) {
    const {field: {onChange, value}} = useController({
        name: `${wealthGroupId}.disabled`,
    })
    const {setValue} = useFormContext()
    return (
        <Switch size={'small'} checked={!value} onChange={(_, checked) => {
            onChange(!checked)
            if (!checked) {
                GroupColumnsOnType[type]?.['large'].forEach(dataName => {
                    setValue(`${wealthGroupId}.${dataName}`, null)
                })
            }
        }}/>
    )
}