import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableContainer,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {BaselineOverviewTableSectionProps} from "../baselineOverviewTypes";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {ExpandMore} from "@mui/icons-material";
import {headlineTranslationMapping} from "../translationHelper";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import orderBy from "lodash/orderBy";


export function InterviewDataTableSection({
                                              type,
                                          }: BaselineOverviewTableSectionProps) {
    const {dataByType,} = useBaselineOverviewContext()
    const dataOfType = dataByType[type]
    const {t} = useTranslation()

    if (!dataOfType) {
        return null
    }
    const preparedList = Object.keys(dataOfType).map((termId) => {
        const {data, type, term} = dataOfType[termId]
        return {
            data,
            type,
            term: {
                ...term,
                kcal: term?.kcal ?? 0
            },
            termId
        }
    })
    const sortedList = orderBy(preparedList, ['term.kcal', 'term.en'], ['desc', 'asc'])
    return (
        <Accordion TransitionProps={{unmountOnExit: true}}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
            >
                <Typography variant={'h5'}>
                    {// @ts-ignore
                        t(headlineTranslationMapping[type as any])}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table size={'small'} padding={'checkbox'}>
                        <TableBody>
                            {sortedList.map(({data, term, type, termId}, i) => (
                                    <BaselineTableRow type={type}
                                                      term={term}
                                                      isEven={i % 2 === 0}
                                                      data={data}
                                                      key={type + '_' + termId + '_' + i}/>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}