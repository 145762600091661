import {Stack} from "@mui/material";
import {BssNotesContentList} from "./BssNotesContentList";
import {BssNotesContentCreate} from "./BssNotesContentCreate";

export function BssNotesContent() {
    return (
        <Stack spacing={2}>
            <BssNotesContentList/>
            <BssNotesContentCreate/>
        </Stack>
    )
}