import {useTranslation} from "react-i18next";
import {useGetTermsSimple} from "../../lib/state/allVariablesState";
import {Autocomplete, AutocompleteProps, TextField} from "@mui/material";
import {useBaselineOverviewContext} from "../../bss/baseline/BaselineOverviewProvider";
import {useShallow} from "zustand/react/shallow";
import {useBssState} from "../../bss/state/bssState";

type TermsSelectorSimpleProps = Pick<AutocompleteProps<any, any, any, any>, 'defaultValue' | 'onChange'> & {}


export function TermsSelectorBssMerging({...rest}: TermsSelectorSimpleProps) {
    const {i18n} = useTranslation()
    const locale = i18n.language || 'en'
    const terms = useGetTermsSimple()
    const {baselineId} = useBaselineOverviewContext()
    const state = useBssState(useShallow(state => state.baselineData))
    const mergedTerms = state[baselineId]?.data?.COMBINED_DATA ?? {}
    const opts = terms
        .filter(term => !mergedTerms?.[term.id])
        .map(t => ({
                ...t, id: t.id,
                // @ts-ignore
                label: t[locale as any]
            })
        )
    // const opts = getTerms([], locale);
    return (
        <Autocomplete
            {...rest}
            sx={{
                minWidth: '300px'
            }}
            renderInput={(params) => (
                <TextField {...params} fullWidth size={'small'}/>
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            options={opts}
        />
    )
}