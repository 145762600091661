import {Frequency} from "../state";

type DurationProps = {
    duration: {
        frequency_months_multi?: string[]
        frequency_months?: string | number
        frequency_type?: string | number
        frequency_days?: string | number
        frequency_weeks?: string | number
    }
}

export function durationGetTimesPerMonths({duration = {}}: DurationProps) {
    const {
        frequency_months_multi,
        frequency_months,
        frequency_type,
        frequency_days,
        frequency_weeks
    } = duration
    if (frequency_type === Frequency.DAY) {
        return Number(frequency_days || (365 / 12));
    } else if (frequency_type === Frequency.WEEK) {
        return Number(frequency_weeks || 4.3452381);
    } else if (frequency_type === Frequency.YEAR) {
        return 1 / 12
    } else if (frequency_type === Frequency.OTHER) {
        return (365 / 12) / Number(frequency_days || 0)
    }
    return 1
}

export function durationCalculator({duration = {}}: DurationProps) {
    const {
        frequency_months_multi,
        frequency_months,
        frequency_type,
        frequency_days,
        frequency_weeks
    } = duration

    const numberMonths = frequency_months_multi?.length ?? 1;
    const frequencyMonth = Number(frequency_months || 1)
    let frequency = 0
    if (frequency_type === Frequency.DAY) {
        frequency = Number(frequency_days || 1) * numberMonths;
    } else if (frequency_type === Frequency.WEEK) {
        frequency = Number(frequency_weeks) * numberMonths;
    } else if (frequency_type === Frequency.MONTH) {
        frequency = numberMonths
    } else if (frequency_type === Frequency.YEAR) {
        frequency = 1
    }
    return {frequency, numberMonths, frequencyMonth}
}

export const durationDefaultedCalculation = (quantity: number, {duration = {}}: DurationProps) => {
    const {
        frequency_months_multi,
        frequency_months,
        frequency_type,
        frequency_days,
        // frequency_weeks,
    } = duration

    let amount = 0
    let frequencyMonths = Array.isArray(frequency_months_multi) ? frequency_months_multi.length || 0 : Number(frequency_months || 0);
    if (frequency_type === Frequency.OTHER) {
        amount = ((frequencyMonths * (365 / 12)) / Number(frequency_days || 0)) * quantity
    } else if (frequency_type === Frequency.WEEK) {
        amount = (frequencyMonths * (4.3452381)) * quantity
    } else if (frequency_type === Frequency.MONTH) {
        amount = frequencyMonths * quantity
    } else if (frequency_type === Frequency.DAY) {
        amount = (frequencyMonths * (365 / 12)) * quantity
    } else if (frequency_type === Frequency.YEAR) {
        amount = quantity
    }
    // amount = Math.round(amount)
    return amount
}