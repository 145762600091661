import {
    BaselineDataRows,
    BaselineDataWithComputed,
    BaselineOverviewContextProps,
    BaselineOverviewResolveDialogProps
} from "../baselineOverviewTypes";
import groupBy from "lodash/groupBy";
import {processDataRow} from "../baselineGroupHelper";
import {Project_Bss_Data_Type_Enum} from "../../../lib";

export const mergeStitchedInterviews = (elements: NonNullable<BaselineOverviewResolveDialogProps['stitchedElements']>, countByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']): BaselineOverviewResolveDialogProps['groupedByWealthGroupData'] => {
    const wealthGroupData: {
        [wealthGroupId: string]: BaselineDataRows
    } = {}
    elements.forEach((element) => {
        Object.keys(element.groupedByWealthGroupData).forEach((wealthGroupId) => {
            const {data} = element.groupedByWealthGroupData[wealthGroupId]
            wealthGroupData[wealthGroupId] = wealthGroupData[wealthGroupId] || []
            wealthGroupData[wealthGroupId].push(...data)
        })
    })

    return Object.keys(wealthGroupData).reduce((previousValue, wealthGroupId) => {
        const groupedData = wealthGroupData[wealthGroupId] as BaselineDataWithComputed[];
        const groupedByLocationId = groupBy(groupedData, 'project_interview_base.project_interview.location_id')
        const summarizedByLocation = Object.keys(groupedByLocationId).reduce((previousValue, locationId) => {
            const data = groupedByLocationId[locationId]
            const summedData = data.reduce((previousValue, currentValue) => {
                Object.keys(currentValue).forEach((key) => {
                    // @ts-ignore
                    if (typeof currentValue[key] === 'number') {
                        // @ts-ignore
                        previousValue[key] = (previousValue[key] || 0) + currentValue[key];
                    } else {
                        // @ts-ignore
                        previousValue[key] = currentValue[key];
                    }
                });
                return previousValue;
            }, {} as BaselineDataWithComputed);
            return [...previousValue, summedData]
        }, [] as BaselineDataWithComputed[])

        return {
            ...previousValue,
            [wealthGroupId]: processDataRow({
                data: summarizedByLocation,
                type: Project_Bss_Data_Type_Enum.CombinedData,
                locationCount: countByWealthGroup[wealthGroupId].interviewsCount
            })
        }
    }, {} as BaselineOverviewResolveDialogProps['groupedByWealthGroupData'])
}