import {Button, Stack} from "@mui/material";
import {LmTipTapRichtextRhf} from "../../../common/form/richText/TipTapRhf";
import {AddComment, Clear, Send} from "@mui/icons-material";
import {BssNoteFragment} from "../../../lib";
import {useUserId} from "@nhost/react";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useSetBssComment} from "../../state/bssState";
import uuidv4 from "../../../theme/utils/uuidv4";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNotesContext} from "./NotesContextProvider";

type BssNotesContentCreateProps = {
    options?: BssNoteFragment
    onSubmit?: () => void
}

export function BssNotesRichtext({onSubmit}: BssNotesContentCreateProps) {
    const {t} = useTranslation()
    const {currentEditMessage, filter, setMessage} = useNotesContext()

    const {handleSubmit, control, resetField} = useForm({
        defaultValues: {
            message: currentEditMessage?.message
        }
    })

    const userId = useUserId()
    const {baselineId} = useBaselineOverviewContext()
    const setComment = useSetBssComment()
    return (
        <form onSubmit={handleSubmit(data => {
            if (data.message) {
                const form = {
                    ...filter,
                    ...currentEditMessage,
                    message: data.message,
                    user_id: userId,
                    project_id: baselineId,
                    ...(currentEditMessage?.id ? {
                        updated_at: new Date().toISOString()
                    } : {
                        id: uuidv4(),
                        created_at: new Date().toISOString()
                    })
                } as BssNoteFragment
                setComment(form)
                onSubmit?.()
            }
        })}>
            <Stack spacing={2}>
                <LmTipTapRichtextRhf name={'message'} control={control} rules={{required: true}}/>
                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                    <Button type={'button'} variant={'outlined'} onClick={() => setMessage(null)} color={'inherit'}
                            startIcon={<Clear/>}>{t('cancel')}</Button>
                    <Button type={'submit'} variant={'contained'} startIcon={<Send/>}>{t('submit')}</Button>
                </Stack>
            </Stack>
        </form>
    )
}

export function BssNotesContentCreate() {
    const {setMessage, currentEditMessage, list, isAllNotes} = useNotesContext()
    if (isAllNotes && !currentEditMessage) {
        return null
    }
    if (!currentEditMessage && list?.length) {
        return (
            <Button type={'submit'} fullWidth startIcon={<AddComment/>} variant={'outlined'}
                    onClick={() => setMessage({} as BssNoteFragment)}>Add Comment</Button>

        )
    }
    return <BssNotesRichtext onSubmit={() => setMessage(null)}/>
}