import {ReactNode} from "react";
import {Stack, Typography} from "@mui/material";

type CardRowProps = {
    left: ReactNode
    right: ReactNode
}

export function CardRow({left, right}: CardRowProps) {
    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>{left}</Typography>
            <Typography fontWeight={'bold'}>{right}</Typography>
        </Stack>
    )
}