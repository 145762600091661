import {
    AppBar,
    Box,
    Button,
    ButtonProps,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {Close, DoneAll, Undo} from "@mui/icons-material";
import {BaselineOverviewResolveDialogProps} from "../baselineOverviewTypes";
import {ResolveDataSection} from "./ResolveDataSection";
import {FormContainer} from "react-hook-form-mui";
import {useGetBssBaselineData, useSetBssBaselineData, useUnsetBssBaselineData} from "../../state/bssState";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useTranslation} from "react-i18next";
import {useRecalculateOnHouseholdProfile} from "../../state/useBaselineKcalData";
import {headlineTranslationMapping} from "../translationHelper";
import {BssNotesDrawer} from "../notes/BssNotesDrawer";
import {useShallow} from "zustand/react/shallow";
import {useBaselineResolverState, useSetBaselineResolverState} from "./baselineResolverState";
import {Project_Bss_Data_Type_Enum, Term} from "../../../lib";
import {useState} from "react";
import {TermsSelectorBssMerging} from "../../../common/form/TermsSelectorBssMerging";
import {mergeStitchedInterviews} from "./mergeStitchedInterviewsHelper";
import {BaselineResolveCategoryPicker} from "./BaselineResolveCategoryPicker";


function BaselineResolveDialog({
                                   term: selectedTerm,
                                   groupedByWealthGroupData,
                                   type,
                                   seasonId,
                                   livestock_type,
                                   open,
                                   onClose,
                                   stitchedElements: stitchedElementsProps
                               }: BaselineOverviewResolveDialogProps) {
    const {t} = useTranslation()
    const {baselineId, cropsBySeason, interviewCountByWealthGroup} = useBaselineOverviewContext()
    const recalculate = useRecalculateOnHouseholdProfile()
    const [term, setSelectedTerm] = useState<Term>(selectedTerm as Term)
    const baselineData = useGetBssBaselineData({
        baselineId, type, termId: term?.id, livestock_type, seasonId
    })
    const [stitchedElements, setStitchedElements] = useState<BaselineOverviewResolveDialogProps['stitchedElements']>(baselineData?.stitchedElements || stitchedElementsProps)
    const [subCategory, setSubCategory] = useState<Project_Bss_Data_Type_Enum | undefined>(baselineData?.subCategory)
    const unsetBaselineData = useUnsetBssBaselineData()
    const hasData = baselineData?.wealthGroupData
    const isResolved = baselineData?.isResolved
    const setBaselineData = useSetBssBaselineData()
    groupedByWealthGroupData = stitchedElements?.length ? mergeStitchedInterviews(stitchedElements, interviewCountByWealthGroup) : groupedByWealthGroupData
    const dialogTitle =
        livestock_type ? t('livestockLabel') + ': ' + t(`livestock.type.${livestock_type.toLowerCase()}`) + ' - ' + term?.en
            : seasonId ? t('crops.name') + ': ' + cropsBySeason[seasonId]?.season?.name + ' - ' + term?.en
                // @ts-ignore
                : `${term?.en} (${t(headlineTranslationMapping[subCategory || type])})`;

    const buttonColor: ButtonProps['color'] = isResolved ? 'success' : hasData ? 'primary' : 'inherit'
    const disableSaveClose = !!(stitchedElements?.length && (!term || !subCategory));
    return (
        <>
            <Dialog
                open={open}
                fullScreen
                onClose={() => {
                    onClose()
                }}
                PaperProps={{
                    sx: {
                        zIndex: '1000002 !important',
                        '& .MuiDialogContent-root': {
                            height: 'calc(100vh - 128px)',
                        }
                    }
                }}
            >
                <AppBar
                    position={'relative'}
                    color={(isResolved ? 'success' : hasData ? 'primary' : 'transparent') as any}
                    sx={{
                        color: (hasData || isResolved) ? 'white' : undefined
                    }}>

                    <Toolbar>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}
                               spacing={2} paddingRight={2}>
                            {stitchedElements?.length && !selectedTerm?.id ? (
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <TermsSelectorBssMerging
                                        onChange={(_, value) => {
                                            setSelectedTerm(value)
                                        }}/>
                                    <BaselineResolveCategoryPicker
                                        value={subCategory || ''}
                                        onChange={(e) => {
                                            setSubCategory(e.target.value as Project_Bss_Data_Type_Enum || undefined)
                                        }}/>
                                </Stack>
                            ) : (
                                <Typography data-id={term?.id}>
                                    {dialogTitle}
                                </Typography>
                            )}
                            <Box flexGrow={1}/>
                            <BssNotesDrawer
                                insideDialog={true}
                                options={{
                                    type,
                                    livestock_type,
                                    term_id: term?.id,
                                    season_id: seasonId
                                }}/>
                            <IconButton onClick={() => onClose()} color={'inherit'}><Close/></IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>


                <FormContainer
                    defaultValues={{
                        ...baselineData?.wealthGroupData
                    }}
                    onSuccess={(formData) => {
                        Object.keys(formData).forEach(key => {
                            if (term?.kcal) {
                                formData[key] = {
                                    ...formData[key],
                                    kcal: term.kcal
                                }
                            }
                            if (livestock_type) {
                                formData[key] = {
                                    ...formData[key],
                                    livestock_type: livestock_type as any
                                }
                            }
                        })
                        setBaselineData({
                            termId: term?.id,
                            baselineId,
                            type,
                            data: formData,
                            livestock_type,
                            seasonId,
                            term: term as Term,
                            stitchedElements,
                            subCategory
                        })
                        if (type === 'HOUSEHOLD_PROFILE_BASE') {
                            recalculate(formData as any)
                        }
                        onClose()
                    }}>
                    <DialogContent>
                        {stitchedElements?.length && (
                            <Stack direction={'row'} spacing={1} paddingBottom={2} alignItems={'center'}>
                                <Typography>Merged Data:</Typography>
                                {stitchedElements?.map((element, index) => (
                                    <Chip variant={'outlined'} label={element.term?.en}
                                          key={element.term?.id + '_' + element.type + '_' + index}
                                          onDelete={() => {
                                              setStitchedElements(s => s?.filter(i => i !== element))
                                          }}
                                    />
                                ))}
                            </Stack>
                        )}
                        <ResolveDataSection
                            groupedByWealthGroupData={groupedByWealthGroupData}
                            term={term}
                            type={type}
                            livestock_type={livestock_type}
                            stitchedElements={stitchedElements}
                        />
                    </DialogContent>
                    <DialogActions sx={{marginRight: 2}}>
                        {!!baselineData && type === Project_Bss_Data_Type_Enum.CombinedData && (
                            <Button type={'button'}
                                    variant={'outlined'}
                                    color={'error'}
                                    onClick={() => {
                                        const bool = window.confirm('Are you sure?')
                                        if (bool) {
                                            unsetBaselineData({baselineId, termId: term?.id})
                                            onClose()
                                        }
                                    }}>
                                Delete
                            </Button>
                        )}
                        <Button
                            disabled={disableSaveClose || !hasData}
                            type="button" variant="contained" color={isResolved ? 'error' : 'success'}
                            sx={{color: 'white'}}
                            startIcon={isResolved ? <Undo/> : <DoneAll/>}
                            onClick={() => {
                                setBaselineData({
                                    baselineId,
                                    isResolved: !isResolved,
                                    type,
                                    termId: term?.id,
                                    seasonId,
                                    livestock_type,
                                    stitchedElements,
                                    subCategory
                                })
                            }}
                        >
                            {isResolved ? t('markUnresolved') : t('markResolved')}
                        </Button>
                        <Button type="submit" variant="contained" color="primary"
                                disabled={disableSaveClose}>Save & Close</Button>
                    </DialogActions>
                </FormContainer>
            </Dialog>
        </>

    )
}

export function BaselineResolveOnContext() {
    const currentContext = useBaselineResolverState(useShallow(state => state.currentContext))
    const setState = useSetBaselineResolverState()
    if (!currentContext) {
        return null
    }
    return <BaselineResolveDialog {...currentContext}
                                  open={!!currentContext}
                                  onClose={() => {
                                      setState(null)
                                  }}/>
}