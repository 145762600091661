import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableContainer,
    Typography
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useBssState} from "../../state/bssState";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Project_Bss_Data_Type_Enum} from "../../../lib";
import {useShallow} from "zustand/react/shallow";
import {BaselineTableRow} from "./BaselineTableRow";
import {WealthGroupDataReturnType} from "../baselineOverviewTypes";

export function BaselineStitchingAccordion() {
    const {t} = useTranslation()
    const {baselineId} = useBaselineOverviewContext()
    const state = useBssState(useShallow(state => state.baselineData))
    const baselineData = state?.[baselineId]?.data[Project_Bss_Data_Type_Enum.CombinedData]
    if (!baselineData) {
        return null
    }
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
            >
                <Typography variant={'h5'}>
                    {t('baselineMergedData')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table size={'small'} padding={'checkbox'}>
                        <TableBody>
                            {Object.keys(baselineData).map((termId, index) => {
                                const {wealthGroupData, term, stitchedElements} = baselineData[termId]
                                return (
                                    <BaselineTableRow
                                        key={termId + '_' + index}
                                        type={Project_Bss_Data_Type_Enum.CombinedData}
                                        data={wealthGroupData as unknown as WealthGroupDataReturnType}
                                        term={term}
                                        stitchedElements={stitchedElements}
                                        isEven={index % 2 === 0}

                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}