import {Accordion, AccordionDetails, AccordionSummary, Stack, Typography} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useTranslation} from "react-i18next";
import {CropsTableOverviewExpenditure} from "./CropsTableOverviewExpenditure";
import {CropsTableOverviewProduction} from "./CropsTableOverviewProduction";
import {CropsTableOverviewRootGreen} from "./CropsTableOverviewRootGreen";
import {ExpandMore} from "@mui/icons-material";

export function CropsTableOverview() {
    const {cropsBySeason} = useBaselineOverviewContext()
    const {t} = useTranslation()
    return (
        <Stack>
            {Object.keys(cropsBySeason).map((seasonId) => {
                    const entries = cropsBySeason[seasonId]
                    return (
                        <Accordion key={seasonId}>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                <Typography variant={'subtitle1'}>
                                    {entries?.season?.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CropsTableOverviewProduction seasonId={seasonId}/>
                                <CropsTableOverviewRootGreen seasonId={seasonId}/>
                                <CropsTableOverviewExpenditure seasonId={seasonId}/>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
            )}
        </Stack>
    )
}