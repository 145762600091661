import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Divider, Stack, Table, TableBody, TableContainer, Typography} from "@mui/material";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {Project_Bss_Data_Type_Enum} from "../../../lib";

export function HouseholdAssets() {
    const {householdProfile} = useBaselineOverviewContext()
    const {HOUSEHOLD_PROFILE_PRODUCTIVE_ASSETS} = householdProfile
    const length = Object.keys(HOUSEHOLD_PROFILE_PRODUCTIVE_ASSETS || {}).length
    if (!length) {
        return null
    }
    return (
        <Stack spacing={2}>
            <Divider/>
            <Typography variant={'subtitle2'}>Productive Assets</Typography>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        {Object.keys(HOUSEHOLD_PROFILE_PRODUCTIVE_ASSETS).map((key, index) => {
                            const data = HOUSEHOLD_PROFILE_PRODUCTIVE_ASSETS[key]
                            return (
                                <BaselineTableRow type={Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets}
                                                  term={data.term}
                                                  isEven={index % 2 === 0}
                                                  data={data.data}
                                                  key={key}/>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}