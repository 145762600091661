import {clear, createStore, del, entries, get, set, UseStore} from "idb-keyval";
import {useIsOnline} from "../../provider";
import {useEffect} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";

let bssDb: UseStore
if (typeof indexedDB !== "undefined") {
    bssDb = createStore("bssQueryCacheDb", "bssQueryCache")
}

export function getBssQueryCache() {
    return {
        getAll: async () => {
            if (typeof bssDb === "undefined") {
                return null;
                // Exit early on server
            }
            const allVals = await entries(bssDb);
            return allVals || null;
        },
        getItem: async (name: string) => {
            if (typeof bssDb === "undefined") {
                return null;
                // Exit early on server
            }
            const value = await get(name, bssDb);
            return value || null;
        },
        setItem: async (name: string, value: any) => {
            // Exit early on server
            if (typeof bssDb === "undefined") {
                return;
            }
            return set(name, value, bssDb);
        },
        removeItem: async (name: string) => {
            // Exit early on server
            if (typeof bssDb === "undefined") {
                return;
            }
            await del(name, bssDb);
        },
        deleteAll: async () => {
            // Exit early on server
            if (typeof bssDb === "undefined") {
                return;
            }
            await clear(bssDb);
        }
    }
}

export const useGetIdbQueryCache = ({
                                        cacheName
                                    }: { cacheName: string }) => {
    return useQuery({
        queryKey: [cacheName],
        queryFn: () => getBssQueryCache().getItem(cacheName),
        gcTime: 60,
        networkMode: 'always'
    })
}

export const useSetIdbQueryCache = <T>({
                                           cacheName
                                       }: { cacheName: string }) => {
    return useMutation({
        mutationKey: [cacheName + '__set'],
        mutationFn: ({data}: {
            data: T
        }) => getBssQueryCache().setItem(cacheName, data),
        networkMode: 'always'
    })
}

export const useDeleteIdbQueryCache = () => {
    return useMutation({
        mutationKey: ['bssIdb__delAll'],
        mutationFn: () => getBssQueryCache().deleteAll(),
        networkMode: 'always'
    })
}

export const getCacheBaselineItem = (baselineId: string) => {
    return `bss-baseline-${baselineId}`
}

export const useIdbQueryCacheFallback = <T>({
                                                cacheName, data
                                            }: { cacheName: string, data: T }): [T, boolean, boolean] => {
    const {isOnline} = useIsOnline()
    const {data: queryData, isLoading} = useGetIdbQueryCache({cacheName})
    const {mutateAsync, isPending} = useSetIdbQueryCache({cacheName})

    useEffect(() => {
        if (isOnline) {
            mutateAsync({data})
        }

    }, [cacheName, data, isOnline, mutateAsync]);

    return [isOnline ? data : queryData as T, isOnline, isLoading || isPending]
}