import {
    AvailableBaselineTypes,
    BaselineDataOverviewType,
    BaselineDataRows,
    BaselineOverviewContextProps,
    CalculatedFieldFunctionProps
} from "../baselineOverviewTypes";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum, Project_Interview_Data_Type_Enum} from "../../../lib";
import {useBssState} from "../../state/bssState";
import {LivestockAnimalMatureId, LivestockAnimalYoungId} from "../../../tss/livestock/livestockHelper";


const MilkProduction: BaselineCsvFieldHelperProps = [{
    type: "Type A",
    fields: ['__milked_female', 'days_milked', 'get_milk_production', '__total_milk', {
        key: 'total_milk_sold',
        calculator: (values) => {
            return Number(values.days_milked) * Number(values.sold_per_day)
        }
    }, 'price_per_litre', '__total_cash_income', {
        key: 'get_skim_bool',
        calculator: values => values.get_skim_bool ? '0' as any : 1
    }, 'quantity_given_away']
}]


export type CsvTypeName = 'Type A' | 'Type B' | 'Type C' | 'Type CSV Column'

type CsvCalculatorProps = CalculatedFieldFunctionProps & {
    sold_per_day?: number
    days_milked?: number
    baselineId: string
    wealthGroupId: string
    new_birth?: number
    breeding_female?: number
    animals_other?: number
    new_birth_secondary?: number
    losses_death?: number
    meat_per_carcass?: number
    meat_quantity_sold?: number
    quantity_given_away?: number
    slaughtered?: number
    meat_quantity_given_away?: number
};
export type CsvFieldColumnName =
    Partial<AvailableBaselineTypes>
    | `__${Partial<AvailableBaselineTypes>}`
    | '__kcal'
    | '__total_milk'
    | ({
    key: string,
    calculator: (props: CsvCalculatorProps) => number
});

export type BaselineCsvFieldHelperProps = ({
    type: CsvTypeName
    fields: (CsvFieldColumnName)[]
}[]) | null;
export const BaselineCsvFieldHelper: {
    [k in BaselineDataOverviewType]: BaselineCsvFieldHelperProps
} = {
    [Project_Interview_Data_Type_Enum.ExpenditureFood]: [{
        type: 'Type A',
        fields: ['quantity_unit', 'no_measure_month', 'duration_months_count', '__total_quantity', '__kcal', '__total_kcal', 'price_per_kg', '__total_expenses']
    }, {
        type: 'Type B',
        fields: ['quantity_unit', {
            key: 'no_times_year',
            calculator: (props) => Number(props.no_measure_month) * Number(props.duration_months_count)
        }, '__kcal', '__total_kcal', 'price_per_kg', '__total_expenses']
    }],
    [Project_Interview_Data_Type_Enum.ExpenditureNonFood]: [{
        type: 'Type A',
        fields: ['total_expenses']
    }],

    [Project_Interview_Data_Type_Enum.Employment]: [{
        type: 'Type A',
        fields: ['people', 'duration_frequency_months', 'duration_months_count', 'payment', '__total_cash_income']
    }],
    [Project_Interview_Data_Type_Enum.SelfEmployment]: [{
        type: 'Type A',
        fields: ['people', 'quantity', 'duration_months_count', 'get_profit', '__total_cash_income']
    }],
    [Project_Interview_Data_Type_Enum.LabourExchange]: [{
        type: 'Type A',
        fields: ['get_food_kcal', 'people', 'duration_frequency_months', 'duration_months_count', 'food_amount', '__total_kcal']
    }],
    [Project_Interview_Data_Type_Enum.CropsExpenditure]: [{
        type: 'Type A',
        fields: ['total_expenses']
    }],
    [Project_Bss_Data_Type_Enum.RemittancesCreditIncome]: [{
        type: 'Type A',
        fields: ['remittance_amount', 'months_count', '__total_cash_income']
    }, {
        type: 'Type B',
        fields: [{key: '', calculator: () => 1}, {
            key: '',
            calculator: () => 1
        }, 'remittance_amount', 'months_count', '__total_cash_income']
    }, {
        type: 'Type C',
        fields: [{key: '', calculator: () => 1}, 'remittance_amount', 'months_count', '__total_cash_income']
    }],
    [Project_Bss_Data_Type_Enum.RemittancesCreditExpenses]: [{
        type: 'Type A',
        fields: ['total_expenses']
    }],
    [Project_Interview_Data_Type_Enum.FoodAid]: [{
        type: 'Type A',
        fields: ['get_amount', 'duration_months_count', '__total_kcal']
    }, {
        type: 'Type B',
        fields: ['get_amount', 'duration_months_count', '__kcal', '__total_kcal']
    }],
    [Project_Bss_Data_Type_Enum.CombinedData]: [{
        type: 'Type A',
        fields: ['total_kcal', 'total_cash_income', 'total_expenses']
    }],
    [Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding]: [{
        type: 'Type A',
        fields: ['number_children', '__total_kcal']
    }],
    [Project_Bss_Data_Type_Enum.Migration]: [{
        type: 'Type A',
        fields: ['people', 'months_count', '__people', '__months_count', '__total_kcal', {
            key: 'savings_time_person',
            calculator: (values) => {
                return values.total_cash_income / values.months_count / values.people
            }
        }, '__total_cash_income']
    }],
    [Project_Bss_Data_Type_Enum.HouseholdProfileBase]: [{
        type: 'Type A',
        fields: ['wealth_breakdown' as any, 'people_in_household', 'land_owned', 'land_cultivated_food', 'land_cultivated_cash', 'children_primary_school', 'children_secondary_school']
    }],
    [Project_Bss_Data_Type_Enum.HouseholdCerealReserves]: [{
        type: 'Type A',
        fields: ['leftover_cereal_amount', {
            key: 'no_times_year',
            calculator: () => 1
        }, '__kcal', '__leftover_cereal_total_calories']
    }],
    [Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets]: [{
        type: 'Type CSV Column',
        fields: ['amount']
    }],
    [Project_Bss_Data_Type_Enum.CropsProduction]: [{
        type: 'Type A',
        fields: ['total_produced', '__kcal', 'total_sold', 'get_sold_price_unit', '__total_cash_income', 'get_other_use', '__total_kcal']
    }, {
        type: 'Type B',
        fields: ['total_produced', {
            key: 'measure',
            calculator: () => 'kg' as any
        }, {
            key: 'weight',
            calculator: () => 1
        }, '__total_produced', '__kcal', 'total_sold', 'get_sold_price_unit', '__total_cash_income', 'get_other_use', '__total_kcal']
    }, {
        type: 'Type C',
        fields: ['total_sold', 'get_sold_price_unit', '__total_cash_income']
    }],
    [Project_Bss_Data_Type_Enum.CropsRoot]: [{
        type: 'Type A',
        fields: ['months_count', 'get_percent_month', '__total_kcal']
    }],
    [Project_Bss_Data_Type_Enum.CropsGreen]: [{
        type: 'Type A',
        fields: ['months_count', 'get_percent_month', '__total_kcal']
    }],
    [Project_Bss_Data_Type_Enum.MilkProductionPeak]: MilkProduction,
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeak]: MilkProduction,
    [Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]: MilkProduction,
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]: MilkProduction,
    [Project_Bss_Data_Type_Enum.LivestockHerd]: [{
        type: "Type A",
        fields: [{
            key: 'animals',
            calculator: (values) => Number(values?.breeding_female) + Number(values?.animals_other)
        }, 'breeding_female', {
            key: 'new_birth',
            calculator: (values) => Number(values.new_birth) + Number(values.new_birth_secondary)
        },
            {
                key: 'nr_animals_sold',
                calculator: (values) => getLivestockHerdCalculation({
                    values,
                    baselineKey: Project_Bss_Data_Type_Enum.LivestockSale,
                    columnName: 'get_animals'
                })
            },
            {
                key: 'nr_animals_slaughtered',
                calculator: (values) => getLivestockHerdCalculation({
                    values,
                    baselineKey: Project_Bss_Data_Type_Enum.LivestockSlaughter,
                    columnName: 'slaughtered'
                })
            },
            {
                key: 'losses_death',
                calculator: values => Number(values.losses_death) + Number(values.total_given_away)
            },
            {
                key: 'nr_animals_bought',
                calculator: (values) => getLivestockHerdCalculation({
                    values,
                    baselineKey: Project_Bss_Data_Type_Enum.LivestockExpenditure,
                    columnName: 'get_animals'
                })
            },
        ]
    }],
    [Project_Bss_Data_Type_Enum.LivestockSlaughter]: [{
        type: 'Type A',
        fields: ['__get_nr_animals', {
            key: 'kg_consumed',
            calculator: (values) => Number(values.meat_per_carcass) - Number(values.meat_quantity_given_away) - Number(values.meat_quantity_sold)
        }, '__total_kcal', {
            key: 'meat_quantity_sold',
            calculator: values => Number(values.meat_quantity_sold) * Number(values.slaughtered)
        }, 'meat_price_per_kg', '__total_cash_income']
    }],
    [Project_Interview_Data_Type_Enum.LivestockExpenditure]: [{
        type: 'Type A', // this is special case we map the fields inside of the csv render function
        fields: ['total_young_animal_expenses' as any, 'total_mature_animal_expenses']
    }],
    [Project_Interview_Data_Type_Enum.LivestockSale]: [{
        /**
         * edge case: need to concatenate all sales of mature / young animals
         */
        type: 'Type A',
        fields: [{
            key: 'get_animals',
            calculator: (values) => {
                const currentWealthGroupId = values.wealthGroupId as string
                const state = getLivestockState(values)
                return Object.keys(state).reduce((acc, animalKeyId) => {
                    const animalCount = state[animalKeyId]?.wealthGroupData?.[currentWealthGroupId]?.get_animals;
                    if (Number(animalCount) > 0) {
                        acc += Number(animalCount);
                    }
                    return acc;
                }, 0);
            }
        }, {
            key: 'price',
            calculator: (values) => {
                const currentWealthGroupId = values.wealthGroupId as string
                const state = getLivestockState(values)
                let count = 0
                let countTotalIncome = 0
                Object.keys(state).forEach((animalKeyId) => {
                    const animalCount = state[animalKeyId]?.wealthGroupData?.[currentWealthGroupId]?.get_animals
                    const income = state[animalKeyId]?.wealthGroupData?.[currentWealthGroupId]?.total_cash_income
                    if (Number(animalCount) > 0) {
                        count += Number(animalCount)
                    }
                    if (Number(income) > 0) {
                        countTotalIncome += Number(income)
                    }
                })
                return countTotalIncome / count
            }
        }, '__total_cash_income']
    }]
    ,
    [Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput]: [{
        type: "Type A",
        fields: ['total_expenses']
    }],
    [Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts]: [{
        type: "Type A",
        fields: ['quantity', 'price', '__total_cash_income']
    }],
    [Project_Interview_Data_Type_Enum.LivestockExchanged]: null,
    [Project_Bss_Data_Type_Enum.LivestockGivenAway]: null,
    [Project_Interview_Data_Type_Enum.FishingWildFoodExpenses]: null,
    [Project_Interview_Data_Type_Enum.FishingWildFood]: null,
}


export const OverwriteBaselineCsvFieldHelper: {
    [k in BaselineDataOverviewType]?: {
        [k in string]?: (props: {
            ctx: BaselineOverviewContextProps
            data: any // this is the data context by getData from ctx
            wealthGroupId: string
            locationId: string
            livestock_type?: Livestock_Type_Enum
        }) => number | string | null
    }
} = {
    LIVESTOCK_HERD: {
        nr_animals_sold: (ctx) => getLivestockHerdRawCalculation({
            ...ctx,
            filterBy: 'get_animals',
            livestock_type: ctx.livestock_type as Livestock_Type_Enum,
            bssType: Project_Bss_Data_Type_Enum.LivestockSale
        }),
        nr_animals_slaughtered: (ctx) => getLivestockHerdRawCalculation({
            ...ctx,
            filterBy: 'slaughtered',
            livestock_type: ctx.livestock_type as Livestock_Type_Enum,
            bssType: Project_Bss_Data_Type_Enum.LivestockSlaughter
        }),
        nr_animals_bought: (ctx) => getLivestockHerdRawCalculation({
            ...ctx,
            filterBy: 'get_animals',
            livestock_type: ctx.livestock_type as Livestock_Type_Enum,
            bssType: Project_Bss_Data_Type_Enum.LivestockExpenditure
        }),
    },
    LIVESTOCK_SALE: {
        price: (ctx) => {
            const rows = getLivestockIncomeRaw(ctx)
            let totalAnimals = 0
            let totalIncome = 0
            rows.forEach(row => {
                // @ts-ignore
                const animalCount = row?.get_animals;
                // @ts-ignore
                const income = row?.total_cash_income
                if (Number(animalCount) > 0) {
                    totalAnimals += Number(animalCount);
                }
                if (Number(income) > 0) {
                    totalIncome += Number(income);
                }
            })
            return totalIncome / totalAnimals
        },
        get_animals: (ctx) => {
            const rows = getLivestockIncomeRaw(ctx)
            return rows.reduce((acc, row) => {
                // @ts-ignore
                const animalCount = row?.get_animals;
                if (Number(animalCount) > 0) {
                    acc += Number(animalCount);
                }
                return acc;
            }, 0)
        }
    },
    LIVESTOCK_EXPENDITURE: {
        total_young_animal_expenses: ({ctx, wealthGroupId, locationId}) =>
            getLivestockExpenditureRaw({ctx, wealthGroupId, filterBy: LivestockAnimalYoungId, locationId}),
        total_mature_animal_expenses: ({ctx, wealthGroupId, locationId}) =>
            getLivestockExpenditureRaw({ctx, wealthGroupId, filterBy: LivestockAnimalMatureId, locationId})
    }
}

function getLivestockIncomeRaw({ctx, wealthGroupId, locationId}: {
    ctx: BaselineOverviewContextProps
    wealthGroupId: string
    locationId: string
}) {
    const total: BaselineDataRows = []
    const groupedCtx = ctx?.livestock?.grouped ?? {} as BaselineOverviewContextProps['livestock']['grouped'];
    Object.keys(groupedCtx).forEach(type => {
        const castType = type as Livestock_Type_Enum
        const sales = groupedCtx[castType]?.data?.[Project_Interview_Data_Type_Enum.LivestockSale]
        if (sales) {
            Object.keys(sales).forEach(termId => {
                const currentLocation = sales[termId]?.data?.[wealthGroupId]?.data
                    ?.find((d: any) => {
                        // keep in mind only one type each resolver
                        if ('project_interview_base' in d) {
                            return d.project_interview_base?.project_interview?.location_id === locationId
                        } else if ('project_interview' in d) {
                            return d.project_interview?.location_id === locationId
                        }
                    });
                if (currentLocation) {
                    total.push(currentLocation)
                }
            })
        }
    })

    return total
}

function getLivestockExpenditureRaw({ctx, wealthGroupId, filterBy, locationId}: {
    ctx: BaselineOverviewContextProps
    wealthGroupId: string
    filterBy: string
    locationId: string
}) {
    let total = 0
    const groupedCtx = ctx?.livestock?.grouped ?? {} as BaselineOverviewContextProps['livestock']['grouped'];
    Object.keys(groupedCtx).forEach(type => {
        const castType = type as Livestock_Type_Enum
        const expenses = groupedCtx[castType]?.data?.[Project_Interview_Data_Type_Enum.LivestockExpenditure]
        if (expenses) {
            Object.keys(expenses).forEach(termId => {
                if (termId === filterBy) {
                    const currentLocation = expenses[termId]?.data?.[wealthGroupId]?.data
                        ?.find((d: any) => {
                            // keep in mind only one type each resolver
                            if ('project_interview_base' in d) {
                                return d.project_interview_base?.project_interview?.location_id === locationId
                            } else if ('project_interview' in d) {
                                return d.project_interview?.location_id === locationId
                            }
                        });
                    // @ts-ignore
                    total += Number(currentLocation?.total_expenses ?? 0)
                }
            })
        }
    })
    return total
}

function getLivestockHerdRawCalculation({ctx, wealthGroupId, filterBy, locationId, livestock_type, bssType}: {
    ctx: BaselineOverviewContextProps
    wealthGroupId: string
    filterBy: string
    locationId: string
    livestock_type: Livestock_Type_Enum
    bssType: Project_Bss_Data_Type_Enum
}) {
    let total = 0
    const groupedCtx = bssType === Project_Bss_Data_Type_Enum.LivestockSlaughter
        ? ctx?.livestock?.grouped?.[livestock_type]?.base[bssType]
        : ctx?.livestock?.grouped?.[livestock_type]?.data[bssType]
    if (groupedCtx) {
        Object.keys(groupedCtx).forEach(termId => {
            const current = bssType === Project_Bss_Data_Type_Enum.LivestockSlaughter
                ? groupedCtx[wealthGroupId]?.data
                // @ts-ignore
                : groupedCtx[termId]?.data?.[wealthGroupId]?.data
            const found = current?.find((d: any) => {
                // keep in mind only one type each resolver
                if ('project_interview_base' in d) {
                    return d.project_interview_base?.project_interview?.location_id === locationId
                } else if ('project_interview' in d) {
                    return d.project_interview?.location_id === locationId
                }
            });
            if (found) {
                total += Number(found[filterBy] ?? 0)
            }
        })
    }
    return total
}

function getLivestockHerdCalculation({values, baselineKey, columnName}: {
    values: any,
    baselineKey: Project_Bss_Data_Type_Enum,
    columnName: string
}) {
    const currentLivestockType = values.livestock_type as Livestock_Type_Enum
    const baselineId = values.baselineId as string
    const currentWealthGroupId = values.wealthGroupId as string
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const state = useBssState().baselineData?.[baselineId]?.livestock?.herd?.[currentLivestockType]?.[baselineKey] ?? {}
    let count = 0
    Object.keys(state).forEach((animalKeyId) => {
        // @ts-ignore
        const animalCount = state[animalKeyId]?.wealthGroupData?.[currentWealthGroupId]?.[columnName as any]
        if (Number(animalCount) > 0) {
            count += Number(animalCount)
        }
    })
    return count
}

function getLivestockState(values: CsvCalculatorProps) {
    const currentLivestockType = values.livestock_type as Livestock_Type_Enum
    const baselineId = values.baselineId as string
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useBssState().baselineData?.[baselineId]?.livestock?.herd?.[currentLivestockType]?.[Project_Bss_Data_Type_Enum.LivestockSale] ?? {}
}