import {PropsWithChildren} from "react";
import {useIsOnline} from "../../provider";
import {useAuthenticationStatus} from "@nhost/react";
import {LoadingScreen} from "../components/LoadingScreen";
import {LoginForm} from "../../common/auth/LoginForm";
import {PageRootSimpleSpa} from "../../common/layout/PageRootSimpleSpa";

export function AuthGuardOffline({children}: PropsWithChildren) {
    const {isOnline} = useIsOnline()
    const {isLoading, isAuthenticated} = useAuthenticationStatus()
    if (!isOnline) {
        return <>{children}</>
    }
    if (isLoading) {
        return <LoadingScreen/>
    }
    if (!isAuthenticated) {
        return (
            <PageRootSimpleSpa title={'Login'}>
                <LoginForm onSuccess={async () => {
                    // do nothing..
                }}/>
            </PageRootSimpleSpa>
        )
    }
    return <>{children}</>
}