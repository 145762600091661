import {BssProjectListItem} from "./BssProjectListItem";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Alert, Container} from "@mui/material";
import {useBssProjectListQuery} from "../../lib/gql/__codegen__/react-query";
import {LoadingScreen} from "../../theme/components/LoadingScreen";
import {useIdbQueryCacheFallback} from "../state/bssQueryCacheState";


export function BssProjectList() {

    const {data, error, isFetching} = useBssProjectListQuery()
    const projectList = data?.project;
    const [list, isOnline, isLoading] = useIdbQueryCacheFallback({
        data: projectList,
        cacheName: 'bss-project-list-cache'
    })
    if (error) {
        return (
            <Alert severity={'error'}>{JSON.stringify(error)}</Alert>
        )
    }
    if ((isOnline && isFetching) || (!isOnline && isLoading)) {
        return (
            <LoadingScreen/>
        )
    }

    return (
        <Container maxWidth={false}>
            <Grid2 container spacing={2}>
                {list?.map(project => (
                    <Grid2 key={project.id} xs={12} sm={6} md={3}>
                        <BssProjectListItem project={project}/>
                    </Grid2>
                ))}
            </Grid2>
        </Container>
    )
}