import {Alert, DialogContent, TableCell, TableRow} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {BaselineCsvDialogContentSection} from "./BaselineCsvDialogContentSection";
import {BaselineDataOverviewType} from "../baselineOverviewTypes";
import {headlineTranslationMapping} from "../translationHelper";
import {useTranslation} from "react-i18next";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum} from "../../../lib";
import {useBssState} from "../../state/bssState";
import {useShallow} from "zustand/react/shallow";
import {Fragment, useState} from "react";
import {BaselineCsvAccordionSection} from "./BaselineCsvAccordionSection";
import {sortedCsvDataTypes, sortedHouseholdTypes} from "../baselineHelpers";
import {LivestockAnimalMatureId, LivestockAnimalYoungId} from "../../../tss/livestock/livestockHelper";
import {BaselineCsvProvider} from "./BaselineCsvWorkbookProvider";
import {BaselineCsvDIalogActions} from "./BaselineCsvDIalogActions";
import Grid2 from "@mui/material/Unstable_Grid2";
import {BaselineCsvCopyTable} from "./BaselineCsvCopyTable";


export function BaselineCsvDialogContent() {
    const {t} = useTranslation()
    const {wealthGroups, baselineId,} = useBaselineOverviewContext()
    // const csvData = mapBaselineCsvData({baselineId, wealthGroups})
    const bssState = useBssState(useShallow(state => state.baselineData))
    const [expanded, setExpanded] = useState<string | boolean>(false)
    const baselineState = bssState[baselineId]
    if (!baselineState) {
        return (
            <Alert>
                No baseline state found for baselineId {baselineId}
            </Alert>
        )
    }

    const cropSeasons = Object.keys(baselineState.crops);
    const livestockKeys = Object.keys(baselineState.livestock);

    return (
        <BaselineCsvProvider>
            <DialogContent sx={{bgcolor: '#eee'}}>
                <Grid2 container gap={4} height={"100%"}>
                    <Grid2 sm={"auto"} height={"100%"} overflow={"auto"}>

                        <BaselineCsvAccordionSection title={t('householdProfile')} expanded={expanded}
                                                     onChange={setExpanded}>
                            {sortedHouseholdTypes.map((bssType => {
                                const data = baselineState.data[bssType];
                                if (!data) {
                                    return null // if there is no data don't show the accordion
                                }
                                return Object.keys(data).map((termId) => {
                                    if (!data[termId]) {
                                        return null;
                                    }

                                    return (
                                        <BaselineCsvDialogContentSection
                                            key={`${bssType}__${termId}`}
                                            type={bssType}
                                            data={data[termId]}
                                            term_id={termId}
                                            isEven={false}

                                        />
                                    )
                                })
                            }))}
                        </BaselineCsvAccordionSection>
                        {livestockKeys.length > 0 && (
                            <BaselineCsvAccordionSection title={t('livestockLabel')} expanded={expanded}
                                                         onChange={setExpanded}>
                                {Object.keys(baselineState.livestock?.herd).map(lType => {
                                    const livestockType = lType as Livestock_Type_Enum

                                    return (
                                        <Fragment key={lType}>
                                            <TableRow>
                                                <TableCell colSpan={wealthGroups.length + 1}
                                                           sx={{fontWeight: 'bold', fontSize: '1.15rem'}}>
                                                    {t(`livestock.type.${lType.toLowerCase()}`)}
                                                </TableCell>
                                            </TableRow>
                                            {Object.keys(baselineState.livestock?.herd[livestockType]).map((bssType, index) => {
                                                const castBssType = bssType as BaselineDataOverviewType
                                                let animalSaleAlreadyRunOnce = false
                                                if (castBssType === Project_Bss_Data_Type_Enum.LivestockExpenditure) {
                                                    return null // we have to handle this differently because all types concatenated into one section
                                                }
                                                return (
                                                    <Fragment key={`${lType}_${bssType}`}>
                                                        {Object.keys(baselineState.livestock?.herd[livestockType][castBssType]).map((termId) => {
                                                            /**
                                                             * edge case for sale, it only runs once because all animal types are being concatenated into
                                                             * one sale section and not separated by young/mature animal type
                                                             * the csv section takes care of the concatenation
                                                             */
                                                            if (castBssType === Project_Bss_Data_Type_Enum.LivestockSale) {
                                                                if (animalSaleAlreadyRunOnce) {
                                                                    return null
                                                                }
                                                                animalSaleAlreadyRunOnce = true
                                                            }
                                                            return (
                                                                <Fragment key={`${lType}_${bssType}_${termId}`}>
                                                                    <BaselineCsvDialogContentSection
                                                                        type={castBssType}
                                                                        key={`${lType}__${termId}__${bssType}`}
                                                                        data={baselineState.livestock?.herd[livestockType][castBssType][termId]}
                                                                        isEven={index % 2 === 0}
                                                                        term_id={termId}
                                                                        livestock_type={livestockType}
                                                                    />
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}
                                <BaselineCsvDialogContentSection
                                    type={Project_Bss_Data_Type_Enum.LivestockExpenditure}
                                    data={{
                                        term: {
                                            en: 'Animal Purchases'
                                        } as any,
                                        isResolved: true,
                                        wealthGroupData: wealthGroups.reduce((acc, wealthGroup) => {
                                            const herdData = baselineState?.livestock?.herd
                                            let youngAnimalsSale = 0
                                            let matureAnimalsSale = 0
                                            Object.keys(herdData).forEach((herdKey) => {
                                                const animalType = herdKey as Livestock_Type_Enum
                                                const matureExpenses = herdData[animalType]?.LIVESTOCK_EXPENDITURE?.[LivestockAnimalMatureId].wealthGroupData?.[wealthGroup.id]?.total_expenses
                                                const youngExpenses = herdData[animalType]?.LIVESTOCK_EXPENDITURE?.[LivestockAnimalYoungId].wealthGroupData?.[wealthGroup.id]?.total_expenses

                                                if (Number(matureExpenses) > 0) {
                                                    matureAnimalsSale += Number(matureExpenses)
                                                }
                                                if (Number(youngExpenses) > 0) {
                                                    youngAnimalsSale += Number(youngExpenses)
                                                }
                                            })
                                            acc[wealthGroup.id] = {
                                                total_young_animal_expenses: youngAnimalsSale,
                                                total_mature_animal_expenses: matureAnimalsSale,
                                                total_expenses: youngAnimalsSale + matureAnimalsSale
                                            }
                                            return acc
                                        }, {} as any)
                                    }}
                                    isEven={false}
                                />

                                {Object.keys(baselineState.livestock?.data).map(bssType => {
                                    const castBssType = bssType as BaselineDataOverviewType
                                    return (
                                        <Fragment key={bssType}>
                                            <TableRow>
                                                <TableCell colSpan={wealthGroups.length + 1}
                                                           sx={{fontWeight: 'bold'}}>
                                                    {t(headlineTranslationMapping[castBssType as Project_Bss_Data_Type_Enum])}
                                                </TableCell>
                                            </TableRow>
                                            {Object.keys(baselineState.livestock?.data[castBssType]).map((termId) => {
                                                return (
                                                    <BaselineCsvDialogContentSection
                                                        type={castBssType}
                                                        key={`${termId}__${bssType}`}
                                                        data={baselineState.livestock?.data[castBssType][termId]}
                                                        isEven={false}
                                                        term_id={termId}
                                                    />
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}
                            </BaselineCsvAccordionSection>
                        )}
                        {cropSeasons.length > 0 && (
                            <BaselineCsvAccordionSection title={t('crops.name')}
                                                         expanded={expanded}
                                                         onChange={setExpanded}>
                                {cropSeasons.map(seasonId => {
                                    return (
                                        <Fragment key={seasonId}>
                                            <TableRow>
                                                <TableCell colSpan={wealthGroups.length + 1} sx={{fontWeight: 'bold'}}>
                                                    {seasonId} todo: translate to current season name
                                                </TableCell>
                                            </TableRow>
                                            {Object.keys(baselineState.crops[seasonId]).map((type, index) => {
                                                const castBssType = type as BaselineDataOverviewType
                                                return (
                                                    <Fragment key={`${seasonId}__${type}`}>
                                                        <TableRow>
                                                            <TableCell colSpan={wealthGroups.length + 1}
                                                                       sx={{fontWeight: 'bold'}}>
                                                                {t(headlineTranslationMapping[castBssType as Project_Bss_Data_Type_Enum])}
                                                            </TableCell>
                                                        </TableRow>
                                                        {Object.keys(baselineState.crops[seasonId][castBssType]).map((termId) => (
                                                            <BaselineCsvDialogContentSection
                                                                type={castBssType}
                                                                key={`${seasonId}__${termId}__${type}`}
                                                                data={baselineState.crops[seasonId][castBssType][termId]}
                                                                isEven={index % 2 === 0}
                                                                seasonId={seasonId}
                                                                term_id={termId}
                                                            />
                                                        ))}
                                                    </Fragment>
                                                )
                                            })}
                                        </Fragment>)
                                })}
                            </BaselineCsvAccordionSection>
                        )}
                        {sortedCsvDataTypes.map((bssType, index) => {
                            if (!baselineState.data[bssType]) {
                                return null // if there is no data don't show the accordion
                            }
                            return (
                                <BaselineCsvAccordionSection
                                    expanded={expanded}
                                    onChange={setExpanded}
                                    title={t(headlineTranslationMapping[bssType])}
                                    key={`${bssType}__${index}`}>

                                    {Object.keys(baselineState.data[bssType]).map((termId, i) => {
                                        return (
                                            <BaselineCsvDialogContentSection
                                                type={bssType}
                                                key={`${bssType}__${termId}__${i}`}
                                                data={baselineState.data[bssType][termId]}
                                                isEven={i % 2 === 0}
                                                term_id={termId}
                                            />
                                        )
                                    })}
                                </BaselineCsvAccordionSection>
                            )
                        })}
                        {baselineState.data?.COMBINED_DATA && (
                            <BaselineCsvAccordionSection title={t('baselineMergedData')} expanded={expanded}
                                                         onChange={setExpanded}>
                                {Object.keys(baselineState.data.COMBINED_DATA).map((termId, i) => {
                                    const {subCategory} = baselineState.data.COMBINED_DATA[termId]
                                    return (
                                        <BaselineCsvDialogContentSection
                                            type={subCategory as BaselineDataOverviewType}
                                            key={`COMBINED_DATA__${termId}__${i}`}
                                            data={baselineState.data.COMBINED_DATA[termId]}
                                            isEven={i % 2 === 0}
                                            term_id={termId}
                                        />
                                    )
                                })}

                            </BaselineCsvAccordionSection>
                        )}
                    </Grid2>
                    <Grid2 sm height={"100%"}>
                        <BaselineCsvCopyTable/>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <BaselineCsvDIalogActions/>
        </BaselineCsvProvider>
    )
}