import {useBssState} from "../../state/bssState";
import {
    Livestock_Type_Enum,
    Project_Bss_Data_Backup_Insert_Input,
    Project_Bss_Data_Insert_Input,
    Project_Bss_Note_Insert_Input
} from "../../../lib";
import {BaselineDataOverviewType} from "../baselineOverviewTypes";
import {BaselineData} from "../../state/bssTypes/bssTypes";

function isUUID(str: string) {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
}

export function prepareBssUploadData({baselineId, userId}: { baselineId: string, userId?: string }) {
    const baselineState = useBssState.getState().baselineData[baselineId]
    const comments = useBssState.getState().comments[baselineId]
    if (!baselineState) {
        console.warn(`No baseline state found for baselineId ${baselineId}`)
        return {}
    }
    const {data, crops, livestock} = baselineState

    const bssDataItems: Project_Bss_Data_Insert_Input[] = []
    const itemAddToSet = ({
                              data,
                              seasonId,
                              type,
                              termId,
                              livestock_type,
                              baselineId,
                          }: {
        data: BaselineData,
        type: BaselineDataOverviewType,
        termId: string,
        baselineId: string,
        seasonId?: string
        livestock_type?: Livestock_Type_Enum
    }) => {
        const {isResolved, wealthGroupData, stitchedElements, subCategory} = data
        if (!wealthGroupData) {
            // don't handle non-resolved fields
            return
        }
        Object.keys(wealthGroupData).forEach((wealthGroupId) => {
            const {
                disabled,
                kcal,
                total_kcal,
                total_cash_income,
                total_expenses,
                ...data
            } = wealthGroupData[wealthGroupId]
            const bssItem: Project_Bss_Data_Insert_Input = {
                project_id: baselineId,
                wealth_group_id: wealthGroupId,
                type: subCategory || type,
                term_id: isUUID(termId) ? termId : null,
                kcal: kcal,
                total_kcal,
                total_cash_income,
                total_expenses,
                non_significant: !!disabled,
                data: disabled ? null : {
                    ...data,
                    ...(stitchedElements && {stitchedElements}),
                    ...(subCategory && {
                        subCategory: type
                    })
                },
                season_id: seasonId,
                is_resolved: isResolved,
                livestock_type,
                user_id: userId
            }
            bssDataItems.push(bssItem)
        })
    }
    data && Object.keys(data).forEach((type) => {
        // handle all data items
        const dataItem = data[type as BaselineDataOverviewType]
        Object.keys(dataItem).forEach((termId) => {
            const termData = dataItem[termId]
            itemAddToSet({
                data: termData,
                type: type as BaselineDataOverviewType,
                termId,
                baselineId,
            })
        })
    })
    crops && Object.keys(crops).forEach((seasonId) => {
        const seasonData = crops[seasonId]
        Object.keys(seasonData).forEach((type) => {
            const dataItem = data[type as BaselineDataOverviewType]
            dataItem && Object.keys(dataItem).forEach((termId) => {
                const termData = dataItem[termId]
                itemAddToSet({
                    data: termData,
                    type: type as BaselineDataOverviewType,
                    termId,
                    baselineId,
                    seasonId
                })
            })
        })
    })
    livestock.data && Object.keys(livestock.data).forEach((type) => {
        const dataItem = livestock.data[type as BaselineDataOverviewType]
        Object.keys(dataItem).forEach((termId) => {
            const termData = dataItem[termId]
            itemAddToSet({
                data: termData,
                type: type as BaselineDataOverviewType,
                termId,
                baselineId,
            })
        })
    })
    livestock.herd && Object.keys(livestock.herd).forEach((livestockType) => {
        const herdData = livestock.herd[livestockType as Livestock_Type_Enum]
        Object.keys(herdData).forEach((type) => {
            const dataItem = herdData[type as BaselineDataOverviewType]
            Object.keys(dataItem).forEach((termId) => {
                const termData = dataItem[termId]
                itemAddToSet({
                    data: termData,
                    type: type as BaselineDataOverviewType,
                    termId,
                    baselineId,
                    livestock_type: livestockType as Livestock_Type_Enum
                })
            })
        })
    })

    const commentsItems: Project_Bss_Note_Insert_Input[] = []
    comments?.forEach(comment => {
        commentsItems.push({
            id: comment.id,
            user_id: comment.user_id ?? userId,
            project_id: baselineId,
            message: comment.message,
            type: comment.type,
            season_id: comment.season_id,
            livestock_type: comment.livestock_type,
            term_id: isUUID(comment.term_id) ? comment.term_id : null,
            is_deleted: comment.is_deleted,
            created_at: comment.created_at,
            updated_at: comment.updated_at,
            is_done: comment.is_done
        })
    })
    const backup: Project_Bss_Data_Backup_Insert_Input = {
        user_id: userId,
        project_id: baselineId,
        data: bssDataItems
    }
    return {bssDataItems, commentsItems, backup}
}