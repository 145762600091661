import {TssLayout} from "../../theme/layouts/tss";
import {useBssState, useResetBssState} from "../state/bssState";
import {useQuery} from "@tanstack/react-query";
import {getBssQueryCache, useDeleteIdbQueryCache} from "../state/bssQueryCacheState";
import {LoadingScreen} from "../../theme/components/LoadingScreen";
import {Alert, Container, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Delete} from "@mui/icons-material";
import {stringToMb} from "../../lib/helpers/stringToMb";
import {ConfirmButton} from "../../common/buttons";

export function BssAdmin() {
    const bssState = useBssState()
    const {data, error, isFetching} = useQuery({
        queryKey: ['bssQueryCache'],
        queryFn: () => getBssQueryCache().getAll()
    })
    const {mutateAsync, isPending} = useDeleteIdbQueryCache()
    const resetWorkingState = useResetBssState()
    const {t} = useTranslation()
    console.log({data, error})


    if (isFetching) {
        return (
            <LoadingScreen/>
        )
    }
    return (
        <TssLayout title={'Home'}
                   appBarProps={{
                       sx: {
                           bgcolor: 'success.main'
                       }
                   }}>
            <Container>
                <Stack spacing={3}>
                    <Alert severity={'warning'} variant={'outlined'}>
                        <Stack spacing={2}>
                            <Typography>
                                {t('adminBssWorkingCache', {size: stringToMb(JSON.stringify(bssState))})}
                            </Typography>
                            <div>
                                <ConfirmButton
                                    message={t('adminBssQueryCacheClearMessage')}
                                    onConfirm={async () => {
                                        await mutateAsync()
                                    }}
                                    buttonProps={{
                                        loading: isPending,
                                        color: 'error',
                                        startIcon: <Delete/>,
                                        variant: 'outlined'
                                    }}
                                >
                                    {t('clearWorkingCache')}
                                </ConfirmButton>
                            </div>
                        </Stack>
                    </Alert>
                    <Alert severity={'info'} variant={'outlined'}>
                        <Stack spacing={2}>
                            <Typography>
                                {t('adminBssQueryCacheMessage', {size: stringToMb(JSON.stringify(data))})}
                            </Typography>
                            <div>
                                <ConfirmButton
                                    message={t('adminBssQueryCacheClearMessage')}
                                    onConfirm={async () => {
                                        await mutateAsync()
                                    }}
                                    buttonProps={{
                                        loading: isPending,
                                        color: 'error',
                                        startIcon: <Delete/>,
                                        variant: 'outlined'
                                    }}
                                >
                                    {t('clearOfflineCache')}
                                </ConfirmButton>
                            </div>
                        </Stack>
                    </Alert>
                </Stack>

            </Container>
        </TssLayout>
    )
}