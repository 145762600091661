import {appNhostClient} from '../nhost/appNhostClient'

type AuthHeaderProps = {
    authorization: string
}

export const fetchData = <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options: RequestInit['headers'] = {}
): (() => Promise<TData>) => {
    return async () => {
        const authHeaders = {} as AuthHeaderProps
        if (appNhostClient.auth.isAuthenticated()) {
            authHeaders.authorization = `Bearer ${appNhostClient.auth.getAccessToken()}`
        }

        if (process.env.STORYBOOK && query.includes('mutation ')) {
            console.log('mutations are prevented inside of storybook')
            return
        }
        // console.log('gqlFetcher', {options, authHeaders, query, variables})

        const res = await fetch(appNhostClient.graphql.httpUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...options,
                ...authHeaders
            },
            body: JSON.stringify({
                query,
                variables
            })
        })

        const json = await res.json()

        if (json.errors) {
            console.log('json.errors', json.errors)
            const {message} = json.errors[0] || {}
            console.error(message || 'Error…')
            console.error({query, variables})
            throw {graphQLErrors: json.errors}
        }

        return json.data
    }
}
