import {Livestock_Type_Enum} from "../lib";

export const TERM_ID_FIXTURES = {
    MILK: 'e9c7f25d-05d0-42bc-b7b2-0ae192b09e5a',
    MEAT_GENERIC: '22d95ca7-b2d4-45a6-a490-c8fae9511a86'
}

export const TERM_LIVESTOCK_MEAT: {
    [k in Livestock_Type_Enum]: string
} = {
    [Livestock_Type_Enum.Chicken]: '2406c4e1-fd7d-4222-84c7-91478d309012',
    [Livestock_Type_Enum.Cattle]: 'dd2eef3b-2d9c-4fcd-9055-3ed18c60911a',
    [Livestock_Type_Enum.Oxen]: 'dd2eef3b-2d9c-4fcd-9055-3ed18c60911a',
    [Livestock_Type_Enum.Sheep]: '7bdd4a5d-2208-4f8a-80d9-39c5530b7074',
    [Livestock_Type_Enum.Goat]: '64e33b67-39b0-4378-9923-b2ca5f6fbc9f',
    [Livestock_Type_Enum.Pig]: '481646b3-50e2-40f2-be8c-05bb37986aaf',
    [Livestock_Type_Enum.OtherPoultry]: '2406c4e1-fd7d-4222-84c7-91478d309012',
    [Livestock_Type_Enum.Camel]: '2c0a817d-28df-4da4-b6bc-4c88a03375b2',
    [Livestock_Type_Enum.Donkey]: TERM_ID_FIXTURES.MEAT_GENERIC,
    [Livestock_Type_Enum.Horse]: TERM_ID_FIXTURES.MEAT_GENERIC,
    [Livestock_Type_Enum.Yak]: TERM_ID_FIXTURES.MEAT_GENERIC,
    [Livestock_Type_Enum.Buffalo]: TERM_ID_FIXTURES.MEAT_GENERIC
}

export const TROPICAL_LIVESTOCK_UNIT: {
    [k in Livestock_Type_Enum]: number
} = {
    [Livestock_Type_Enum.Chicken]: 0,
    [Livestock_Type_Enum.Cattle]: 0.5,
    [Livestock_Type_Enum.Oxen]: 0.5,
    [Livestock_Type_Enum.Sheep]: 0.1,
    [Livestock_Type_Enum.Goat]: 0.1,
    [Livestock_Type_Enum.Pig]: 0.2,
    [Livestock_Type_Enum.OtherPoultry]: 0,
    [Livestock_Type_Enum.Camel]: 0.7,
    [Livestock_Type_Enum.Donkey]: 0,
    [Livestock_Type_Enum.Horse]: 0,
    [Livestock_Type_Enum.Yak]: 0,
    [Livestock_Type_Enum.Buffalo]: 0
}


export const TERM_CATEGORIES_FILTER_FIXTURES = {
    LIVESTOCK: '9ef24799-62ab-4813-a11f-140aa6e00e2b',
    LIVESTOCK_SALE: ['9ef24799-62ab-4813-a11f-140aa6e00e2b', '07561646-6828-41af-a8c7-206c6e13a5e0'],
    LIVESTOCK_PURCHASE: ['2fbd4b00-01d9-4ff8-9b85-fea09618f00c'],
    LIVESTOCK_EXCHANGED_FOOD: ['f50792d4-25cd-4bbd-b7a4-19f096957299'],
    PURCHASES_FOOD: ['3222baf3-8f4f-421f-a200-eced33c72312', '2cea27af-c69c-4dd1-9fe6-32d9e4779bbe'],
    PURCHASES_NON_FOOD: ['1f47ba88-f38c-4691-ad58-7bf52d4f50fc', '916f8c4f-7131-40ee-b5f7-4b48fbcf5077', 'd6c0e388-0ea3-42ef-bb8c-8796fb786767', 'da33d985-1289-4f99-9340-295980f2bfc9', '13a58bdb-991a-469d-9bd1-a3f2b4973409', '6b6c7c18-83ef-484f-9ff4-1758f8ae3d5d', 'e6d5f84d-3037-45af-82ae-7ef4428d45f9']
};
export type ListFilterState = '01' | '02' | '03' | '04' | null;

export const EXPENDITURE_CATEGORIES_FILTER_FIXTURES = {
    staplesFood: '3222baf3-8f4f-421f-a200-eced33c72312',
    health: 'da33d985-1289-4f99-9340-295980f2bfc9',
    education: '13a58bdb-991a-469d-9bd1-a3f2b4973409',
    other: '6b6c7c18-83ef-484f-9ff4-1758f8ae3d5d',
    essentialHouseholdItems: 'd6c0e388-0ea3-42ef-bb8c-8796fb786767',
    otherProduction: '916f8c4f-7131-40ee-b5f7-4b48fbcf5077',
    transport: '1f47ba88-f38c-4691-ad58-7bf52d4f50fc',
    clothing: 'e6d5f84d-3037-45af-82ae-7ef4428d45f9'
}