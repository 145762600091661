export const arrayFieldSum = <T extends {}, K extends keyof T>(array: T[], fieldName?: K) => {
    if (!array?.length) {
        return 0
    }
    // @ts-ignore
    return array.reduce((partialSum, a) => partialSum + (fieldName ? Number(a[fieldName]) || 0 : a), 0)
}

export function sumArrayObjectFields<T extends object, K extends keyof T>(array: T[], fields: K[]) {
    return array.reduce((previousValue, currentValue) => {
        return fields.reduce((prev, current) => ({
            ...prev,
            [current]: (previousValue[current] || 0) + Number(currentValue[current] || 0)
        }), {} as { [Z in K]: number })
    }, {} as { [Z in K]: number })
}

export function sumObjectFields<T extends object, K extends keyof T>(object: T, fields: K[]) {
    return fields.reduce((previousValue, currentValue) => {
        return previousValue + Number(object[currentValue] || 0)
    }, 0)
}

export function multiplyObjectFields<T extends object, K extends keyof T>(object: T, fields: K[], defaultValue = 0) {
    return fields.reduce((previousValue, currentValue) => {
        return previousValue * Number(object?.[currentValue] ?? defaultValue)
    }, 1)
}

export function multiplyObjectFieldsArray<T extends object, K extends keyof T>(array: T[], fields: K[]) {
    return array.reduce((previousValue, currentValue) => {
        const multiply = fields.reduce((prev, current) =>
            prev * Number(currentValue[current]), 1)
        return previousValue + multiply
    }, 0)
}