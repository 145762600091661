import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {CropsTableOverview} from "./CropsTableOverview";

export function CropsAccordion() {
    const {t} = useTranslation()
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
            >
                <Typography variant={'h5'}>
                    {t('crops.name')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <CropsTableOverview/>
            </AccordionDetails>
        </Accordion>
    )
}