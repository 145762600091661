import {getNumberFormat} from "../../lib/helpers/numberFormat";
import {
    AvailableBaselineTypes,
    BaselineDataOverviewType,
    BaselineDataWithComputed,
    BaselineRowData,
    GetMinMaxProps,
    GroupAverageTypes,
    GroupColumnAverages,
    GroupedDataReturnType,
    TypeIsSummed
} from "./baselineOverviewTypes";
import orderBy from "lodash/orderBy";
import {GroupColumnsOnType} from "./baselineDataFields";
import {durationGetTimesPerMonths} from "../../lib/helpers/durationCalculator";
import {BssBaselineDetailQuery, GreenRootCropsFragment, Project_Bss_Data_Type_Enum} from "../../lib";
import {sumObjectFields} from "../../lib/helpers/arrayFieldSum";

function fillArrayWithZeros(array: number[], len: number) {
    const zeros = Array(len).fill(0)
    return [...array, ...zeros].slice(0, len)
}


// export const BssColsToIncludeZeros = ['total_cash_income', 'total_kcal', 'total_expenses', 'total_quantity', 'total_produced', 'total_sold', 'get_other_use', 'total_consumed', 'total_kcal_sum', 'total_consumed_milk', 'total_milk', 'leftover_cereal_total_calories']
/**
 * For all price related data we don't fill with zeros
 * todo: add missing where we need to fill zeros
 */
export const BssColsToIncludeNulls = ['wealth_breakdown', 'people_in_household', 'children_primary_school', 'children_secondary_school', 'days_milked', 'get_milk_production', 'meat_per_carcass', 'quantity_unit', 'price_per_kg', 'payment', 'get_profit', 'remittance_amount', 'food_amount', 'get_food_kcal']
export const DividerAfterColName = ['get_milk_production', 'quantity_given_away']


export function getDataField(val: number, dataPoint?: string) {
    if (dataPoint === 'total_kcal' || dataPoint === 'total_kcal_sum' || dataPoint === 'get_food_kcal') {
        return val ? Number(val).toFixed(1) + '%' : '-'
    }

    return getNumberFormat(val) || '-'
}


function getMinMaxAvg({
                          col,
                          data,
                          locationCount
                      }: GetMinMaxProps) {
    const points = data
        // @ts-ignore
        .map(d => d[col])
        .filter(i => i);
    const sorted = orderBy(points, [], ['desc'])

    if (!BssColsToIncludeNulls.includes(col)) {
        const filled = fillArrayWithZeros(sorted, locationCount)

        return {
            avg0: filled.reduce((a, b) => a + b, 0) / locationCount,
            avg1: filled.slice(1, -1).reduce((a, b) => a + b, 0) / (locationCount - 2),
            avg2: filled.slice(2, -2).reduce((a, b) => a + b, 0) / (locationCount - 4),
            min: filled.slice(-2),
            max: filled.slice(0, 2),
        } as GroupAverageTypes
    }
    return {
        avg0: sorted.reduce((a, b) => a + b, 0) / sorted.length,
        avg1: sorted.slice(1, -1).reduce((a, b) => a + b, 0) / (sorted.length - 2),
        avg2: sorted.slice(2, -2).reduce((a, b) => a + b, 0) / (sorted.length - 4),
        min: sorted.slice(-2),
        max: sorted.slice(0, 2),
    } as GroupAverageTypes

}

function getMinMaxAvgTotals({
                                col,
                                data,
                                locationCount
                            }: GetMinMaxProps) {
    const points = data
        // @ts-ignore
        .map(d => d[col])
        .filter(i => i);
    const sorted = orderBy(points, [], ['desc'])

    const filled = fillArrayWithZeros(sorted, locationCount)

    return {
        avg0: filled.reduce((a, b) => a + b, 0) / locationCount,
        avg1: filled.slice(1, -1).reduce((a, b) => a + b, 0) / (locationCount - 2),
        avg2: filled.slice(2, -2).reduce((a, b) => a + b, 0) / (locationCount - 4),
        min: filled.slice(-2),
        max: filled.slice(0, 2),
    }
}


type GroupDataProps = {
    data: BaselineDataWithComputed[],
    locationCount: number,
    type: BaselineDataOverviewType
};

export function processDataRow({
                                   data = [],
                                   locationCount,
                                   type
                               }: GroupDataProps) {
    const currentData: BaselineDataWithComputed[] = data
        .map(row => {
            const timesPerMonth = durationGetTimesPerMonths({duration: row.duration || {}});
            const rowData: BaselineDataWithComputed = {
                ...row,
                months_count: row.months?.length ?? 0,
                duration_months_count: row.duration?.frequency_months_multi?.length ?? (row.duration?.frequency_type === 'YEAR' ? 12 : 0) ?? 0,
                duration_frequency_months: row.duration?.frequency_months ?? 0,
                no_measure_month: 0,
                get_profit: row.profit_by_person || row.profit_per_unit || 0,
                get_frequency_per_months: row.duration ? timesPerMonth : 0,
                get_food_kcal: row.food_term?.kcal ?? 0,
                get_total_quantity_kg: (row.quantity || 0) * (row.quantity_unit || 1) * timesPerMonth,
                get_sold_total: row.sold_quantity ? row.sold_quantity * row.sold_unit : 0,
                get_sold_price_unit: (row.price || 0) / (row.sold_unit || 1),
                get_sold_amount: row.sold_quantity ? row.sold_quantity * timesPerMonth : 0,
                get_amount: row.amount ? row.amount * timesPerMonth : 0,
                get_amount_kg: (row.quantity || 0) * (row.quantity_unit || 1),
                get_nr_animals: row.amount,
                get_animals: row.quantity,
                price_per_kg: (1 / (row.quantity_unit || 1)) * (row.price || 0),
                remittance_amount: row.amount,
                // get_times_year: row. // todo get_times_year
            }
            rowData.no_measure_month = (rowData.total_quantity || 0) / (rowData.months_count || rowData.duration_months_count || 0) / (rowData.quantity_unit || 1)
            return rowData
        })


    const fieldsToProcess = GroupColumnsOnType[type]?.large

    if (!fieldsToProcess) {
        return {
            data: currentData
        } as any
    }
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        return {
            ...acc,
            [field]: getMinMaxAvg({
                col: field,
                data: currentData as any,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: currentData
    }
}


type ProcessHouseholdProfileProps = {
    data: BssBaselineDetailQuery['project_interview_base'],
    locationCount: number,
    type: BaselineDataOverviewType
};

export function processHouseholdProfile({
                                            data,
                                            locationCount,
                                            type
                                        }: ProcessHouseholdProfileProps): GroupedDataReturnType {
    const currentData = data
        .map(row => {
            return {
                ...row,
                months_count: row.leftover_cereal_months?.length ?? 0,
            }
        })

    const fieldsToProcess = GroupColumnsOnType[type]?.large
    if (!fieldsToProcess) {
        return {
            data: currentData
        } as any
    }
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        return {
            ...acc,
            [field]: getMinMaxAvg({
                col: field,
                data: currentData,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: currentData
    }
}

type ProcessGreenRootCropsProductionProps = {
    data: GreenRootCropsFragment[],
    locationCount: number,
}

export function processGreenRootCropsProduction({data, locationCount}: ProcessGreenRootCropsProductionProps) {
    const mapped = data.map(row => ({
        ...row,
        months_count: row.months?.length ?? 0,
        get_percent_month: ((row.total_kcal || 0) / row.months?.length ?? 0) * 12,
    }))
    const fieldsToProcess = GroupColumnsOnType.CROPS_ROOT?.large
    if (!fieldsToProcess) {
        console.error('No fields to process')
        return {
            data: mapped
        } as any
    }
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        return {
            ...acc,
            [field]: getMinMaxAvg({
                col: field,
                data: mapped,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: mapped
    }
}

type ProcessTotals = {
    data: BssBaselineDetailQuery['project_interview_totals'],
    locationCount: number
}

const summarizeFieldsBasedStartString = (obj: any, startString: string) => {
    return Object.keys(obj)
        .filter(key => key.startsWith(startString))
        .reduce((totalCalories, key) => totalCalories + (typeof obj[key] === 'number' ? obj[key] : 0), 0);
}

export function processTotals({data, locationCount}: ProcessTotals) {
    const mapped = data.map(row => ({
        ...row,
        'calories_total': summarizeFieldsBasedStartString(row, 'calories_'),
        'income_total': summarizeFieldsBasedStartString(row, 'cash_income_'),
        'expenditure_total': summarizeFieldsBasedStartString(row, 'cash_expenses_'),
        'calories_labour_jobs_migration': row.calories_migration + row.calories_labour_jobs + row.calories_labour_exchange,
        'income_labour_jobs_migration': row.cash_income_labour_jobs + row.cash_income_migration + row.cash_income_labour_exchange,
        // @ts-ignore
        calories_crops: row.calories_crops + (row.cereal_leftover_kcal || 0) // add cereal leftovers
    }))
    const fieldsToProcess = Object.keys(mapped[0])
    if (!fieldsToProcess) {
        console.error('No fields to process')
        return {
            data: mapped
        } as any
    }
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        if (field === 'location_id') return acc
        return {
            ...acc,
            [field]: getMinMaxAvgTotals({
                col: field,
                data: mapped as any,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: mapped
    }
}

type ProcessCropsProductionProps = {
    data: BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_crops']
    locationCount: number
}

export function processCropsProduction({data, locationCount}: ProcessCropsProductionProps) {
    const mapped = data.map(row => ({
        ...row,
        get_other_use: (row.total_given_away || 0) + (((row.total_produced || 0) - (row.total_sold || 0) - (row.total_given_away || 0)) * (Number(row.losses || 0) / 100)),
        get_sold_price_unit: (row.sold_price_per_unit || 0) / (row.sold_unit_measure || 1)
    }))
    const fieldsToProcess = GroupColumnsOnType.CROPS_PRODUCTION?.large
    if (!fieldsToProcess) {
        console.error('No fields to process')
        return {
            data: mapped
        } as any
    }
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        return {
            ...acc,
            [field]: getMinMaxAvg({
                col: field,
                data: mapped,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: mapped
    }
}

type ProcessProductiveAssetsProps = {
    data: BssBaselineDetailQuery['project_interview_base'][number]['project_interview_productive_assets'],
    locationCount: number
}

export function processProductiveAssets({data, locationCount}: ProcessProductiveAssetsProps): GroupedDataReturnType {
    const generalFields = {
        amount: getMinMaxAvg({
            col: 'amount',
            data: data,
            locationCount
        })
    } as GroupColumnAverages
    return {
        ...generalFields,
        data: data
    }
}

type MilkProduction =
    BssBaselineDetailQuery['project_interview_livestock'][number]['peak_milk'];
type ProcessMilkProductionProps = {
    data: NonNullable<MilkProduction>[],
    locationCount: number
}

export function processMilkProduction({data, locationCount}: ProcessMilkProductionProps) {
    const mapped = data.map(row => ({
        ...row,
        get_milk_production: (row.measure_unit || 1) * (row.milk_per_animal || 0),
        get_skim_bool: row.consumes_skim ? 1 : 0,
        total_milk: (row.measure_unit || 1) * (row.milk_per_animal || 0) * (row.milked_female || 0),
        total_kcal_sum: (row.total_kcal || 0) + (row.total_exchanged_kcal || 0),
        total_consumed_milk: (row.measure_unit || 1) * (row.milk_per_animal || 0) * (row.milked_female || 0) - sumObjectFields(row, ['sold_per_day', 'quantity_given_away'] as any) // todo
    }))
    const fieldsToProcess = GroupColumnsOnType.MILK_PRODUCTION_PEAK?.large
    if (!fieldsToProcess) return {data: mapped} as any
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        return {
            ...acc,
            [field]: getMinMaxAvg({
                col: field,
                data: mapped,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: mapped
    }
}


type ProcessLivestockBaseProps = {
    data: BssBaselineDetailQuery['project_interview_livestock'],
    locationCount: number,
    type: Project_Bss_Data_Type_Enum
}

export function processLivestockBase({data, locationCount, type}: ProcessLivestockBaseProps) {
    const mapped = data.map(row => ({
        ...row,
        get_meat_income: (row.meat_quantity_sold || 0) * (row.meat_price_per_kg || 0),
        total_kcal: row.total_kcal_calories_consumed_eggs
    }))
    const fieldsToProcess = GroupColumnsOnType[type]?.large
    if (!fieldsToProcess) return {data: mapped} as any
    const generatedFields: GroupColumnAverages = fieldsToProcess.reduce((acc, field) => {
        return {
            ...acc,
            [field]: getMinMaxAvg({
                col: field,
                data: mapped,
                locationCount
            })
        }
    }, {} as GroupColumnAverages)
    return {
        ...generatedFields,
        data: mapped
    }
}

export function sumOrAvgData({data = [], col}: { data: BaselineRowData[], col: AvailableBaselineTypes }) {
    if (!data.length) {
        return 0
    }
    // @ts-ignore
    const sum = data.reduce((previousValue, currentValue) => previousValue + (currentValue[col] ?? 0), 0)

    // todo make sure all summed typed are correct
    if (TypeIsSummed.includes(col)) {
        return sum
    }

    return sum / data.length
}