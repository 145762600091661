import {bindDialog, bindPopover, bindTrigger, usePopupState, Variant} from "material-ui-popup-state/hooks";
import {forwardRef, isValidElement, MutableRefObject, PropsWithChildren, ReactNode} from "react";
import {
    AppBar,
    AppBarProps,
    Button,
    ButtonProps,
    Dialog,
    DialogProps,
    IconButton,
    IconButtonProps,
    Menu,
    MenuProps,
    Toolbar,
    ToolbarProps,
    Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {SlideUpTransition} from "./SlideUpTransition";

type PopperContainerProps = PropsWithChildren<{
    variant?: Variant
    popupId?: string
    trigger?: ButtonProps
    triggerIcon?: IconButtonProps,
    dialogProps?: Omit<DialogProps, 'open'>
    appBarProps?: AppBarProps
    toolbarProps?: ToolbarProps
    dialogTitle?: ReactNode
    menuProps?: Omit<MenuProps, 'open'>
    menuColumnCount?: number
    slideUpTransition?: boolean
}>

export const PopperContainer = forwardRef(
    ({
         variant,
         popupId,
         children,
         trigger,
         triggerIcon,
         dialogProps,
         dialogTitle,
         menuProps,
         menuColumnCount,
         slideUpTransition,
         appBarProps,
         toolbarProps
     }: PopperContainerProps,
     ref) => {
        let containerVariant = variant || 'dialog';

        const popupState = usePopupState({
            variant: containerVariant,
            popupId: popupId || 'popper-container'
        })
        if (ref) {
            (ref as MutableRefObject<any>).current = popupState
        }

        return (
            <>
                {trigger && (
                    <Button {...trigger} {...bindTrigger(popupState)} />
                )}
                {triggerIcon && (
                    <IconButton {...triggerIcon} {...bindTrigger(popupState)} />
                )}
                {containerVariant === 'dialog' && (
                    <Dialog {...dialogProps}
                            {...bindDialog(popupState)}
                            TransitionComponent={slideUpTransition ? SlideUpTransition : undefined}
                            onClose={(ev, reason) => {
                                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                                    return
                                }
                                popupState.close()
                            }}>
                        <AppBar position={'relative'} {...appBarProps}>
                            <Toolbar sx={{justifyContent: 'space-between'}} {...toolbarProps}>
                                {dialogTitle && isValidElement(dialogTitle) ? <>{dialogTitle}</> : (
                                    <Typography variant={'h6'}>{dialogTitle}</Typography>
                                )}
                                <IconButton onClick={() => popupState.close()} color={'inherit'}><Close/></IconButton>
                            </Toolbar>
                        </AppBar>
                        {children}
                    </Dialog>
                )}
                {containerVariant === 'popover' && (
                    <Menu anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center'
                          }}
                          {...menuProps}
                          slotProps={{
                              paper: {
                                  elevation: 8,
                                  ...menuProps?.PaperProps,
                                  sx: {
                                      ...menuProps?.PaperProps?.sx,
                                      ...(menuColumnCount && {
                                          '& .MuiList-root': {
                                              display: 'grid',
                                              gridTemplateColumns: `repeat(${menuColumnCount}, 1fr)`,
                                              gap: '4px'
                                          }
                                      })

                                  }
                              }
                          }}
                          {...bindPopover(popupState)}>
                        {children}
                    </Menu>
                )}
            </>
        )
    })
PopperContainer.displayName = 'PopperContainer'