import {BaselineOverviewResolveDialogProps} from "../baselineOverviewTypes";
import {useShallow} from "zustand/react/shallow";
import {create} from "zustand";

type BaselineResolverState = {
    currentContext: Pick<BaselineOverviewResolveDialogProps, 'seasonId' | 'type' | 'livestock_type' | 'term' | 'groupedByWealthGroupData' | 'stitchedElements'> | null
    setCurrentContext: (context: BaselineResolverState['currentContext'] | null) => void
}
export const useBaselineResolverState = create<BaselineResolverState>((set) => ({
    currentContext: null,
    setCurrentContext: (context) => set({currentContext: context})
}))
export const useSetBaselineResolverState = () => useBaselineResolverState(useShallow(state => state.setCurrentContext))