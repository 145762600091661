import {FC, PropsWithChildren} from 'react'
import {Box, BoxProps} from '@mui/material'

const BoxCentered: FC<PropsWithChildren<BoxProps>> = ({children, ...props}) => {
    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} {...props}>
            {children}
        </Box>
    )
}

export default BoxCentered
