import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {IconButton, Stack, TableCell, TableRow} from "@mui/material";
import {baselineSummarizeItems, GetTotalReturnType} from "./baselineSummarizeItems";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useTranslation} from "react-i18next";
import {BaselineSummarizeTableContentItemDetail} from "./BaselineSummarizeTableContentItemDetail";
import {UnfoldLess, UnfoldMore} from "@mui/icons-material";
import {useState} from "react";
import BoxCentered from "../../../common/form/BoxCentered";

export function BaselineSummarizeTableContentItem({identifier, parentKey, resolved}: {
    identifier: string
    parentKey: 'kcal' | 'income' | 'expenditure'
    resolved?: {
        [key: string]: {
            kcal: number
            income: number
            expenditure: number
        }
    }
}) {
    const {t} = useTranslation()
    const baselineContext = useBaselineOverviewContext()
    const [open, setOpen] = useState<boolean>(false)
    // @ts-ignore
    const {getTotal, translationKey} = baselineSummarizeItems[parentKey][identifier]
    let totals: GetTotalReturnType = {}

    if (getTotal) {
        totals = getTotal(baselineContext)
    }


    return (
        <>
            <TableRow sx={{
                bgcolor: identifier === 'summarize' ? 'warning.light' : undefined,
            }}>
                <TableCell>
                    <Stack direction={'row'} justifyContent={"space-between"}>
                        <span>{t(translationKey)}</span>
                        <IconButton size={'small'}
                                    onClick={() => {
                                        setOpen(s => !s)
                                    }}>
                            {open ? <UnfoldLess/> : <UnfoldMore/>}
                        </IconButton>
                    </Stack>
                </TableCell>
                {baselineContext.wealthGroups.map(item => {
                    const totalValue = totals?.[item.id]?.value ?
                        (parentKey === 'kcal' ? getNumberFormat(totals?.[item.id]?.value) + ' %' : getNumberFormat(totals?.[item.id]?.value))
                        : '-'

                    const resolvedValue = resolved?.[item.id]?.[parentKey] ?
                        (parentKey === 'kcal' ? getNumberFormat(resolved?.[item.id]?.[parentKey]) + ' %' : getNumberFormat(resolved?.[item.id]?.[parentKey]))
                        : '-'
                    return (
                        <TableCell key={item.id}>
                            <Stack alignItems={'center'} justifyContent={'stretch'} spacing={1}>
                                <BoxCentered border={'1px solid'} borderColor={'#ccc'} borderRadius={'4px'}
                                             width={'100%'}
                                             paddingX={1}
                                             paddingY={0.7}>
                                    {resolvedValue}
                                </BoxCentered>
                                {totalValue}
                            </Stack>
                        </TableCell>
                    );
                })}
            </TableRow>
            {open && (
                <BaselineSummarizeTableContentItemDetail data={totals} parentKey={parentKey}/>
            )}
        </>
    )
}