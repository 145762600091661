import {Alert, Box, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {useBssDataQuery} from "../../../lib/gql/__codegen__/react-query";
import {useDate} from "../../../hooks/useDate";
import {mapDownloadBssToState} from "./mapDownloadBssToState";
import {CloudDownload} from "@mui/icons-material";
import {useOverwriteBaselineState, useOverwriteCommentsState} from "../../state/bssState";
import {getUserName} from "../../../lib/helpers/userNameHelper";

export function BaselineSyncDownload({baselineId, onDownload}: { baselineId: string, onDownload?: () => void }) {
    const {t} = useTranslation()
    const {getDate} = useDate()
    const overwriteBaselineState = useOverwriteBaselineState()
    const overwriteCommentsState = useOverwriteCommentsState()
    const {data, isLoading} = useBssDataQuery({
        baselineId
    }, {
        // networkMode: 'online',
        gcTime: 0,
        staleTime: 0,
    })

    if (!data?.project_bss_data?.length) {
        return null
    }
    return (
        <Box margin={2}>
            <Alert severity="info" icon={<CloudDownload/>}>
                <Stack width={'100%'} spacing={1}>
                    <Typography>
                        {t('bssDownloadInfo')}
                    </Typography>
                    {data?.project_bss_data?.[0].created_at && (
                        <Typography
                            variant={'body2'}
                            fontWeight={'bold'}>{t('uploadedDateAndTime')}: {getDate(data?.project_bss_data?.[0].created_at, {
                            timeStyle: 'short',
                            dateStyle: 'medium',
                        })}</Typography>
                    )}
                    {data?.project_bss_data?.[0].user && (
                        <Typography
                            variant={'body2'}
                            fontStyle={'italic'}
                            fontWeight={'bold'}>by {getUserName(data?.project_bss_data?.[0].user)}</Typography>
                    )}


                    <LoadingButton
                        startIcon={<CloudDownload/>}
                        loading={isLoading}
                        fullWidth
                        onClick={async () => {
                            const conf = window.confirm(t('confirmDownloadBss'))
                            if (conf) {
                                if (data) {
                                    const {bssState, comments} = mapDownloadBssToState(data)
                                    overwriteBaselineState(baselineId, bssState)
                                    overwriteCommentsState(baselineId, comments)
                                    onDownload?.()
                                }
                            }
                        }}
                    >
                        download
                    </LoadingButton>
                </Stack>
            </Alert>
        </Box>
    )
}