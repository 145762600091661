import {useBssState, useSetBssBaselineData} from "./bssState";
import {useBaselineOverviewContext} from "../baseline/BaselineOverviewProvider";
import {
    BaselineDataOverviewType,
    GroupAverageTypes,
    GroupedDataReturnType,
    HouseHoldStatisticData
} from "../baseline/baselineOverviewTypes";
import {GroupColumnsOnType} from "../baseline/baselineDataFields";
import {BaselineDataGroup, SetBaselineDataProps} from "./bssTypes/bssTypes";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum} from "../../lib";
import {useShallow} from "zustand/react/shallow";

export const useBaselineKcalData = () => {
    const stateBaseline = useBssState(useShallow(state => state.baselineData))
    const {baselineData, baselineId, householdProfile} = useBaselineOverviewContext()

    const currentSet = stateBaseline[baselineId]?.data[Project_Bss_Data_Type_Enum.HouseholdProfileBase]
    const baseData = householdProfile?.[Project_Bss_Data_Type_Enum.HouseholdProfileBase]

    const getBaselineData = (wealthGroupId: string, averageId: keyof Pick<GroupAverageTypes, 'avg1' | 'avg2' | 'avg0'> = 'avg1'): HouseHoldStatisticData => {
        const hhMember = Number(currentSet?.householdProfile.wealthGroupData[wealthGroupId]?.people_in_household || 0)
            || Number(baseData?.[wealthGroupId]?.people_in_household?.[averageId] || 0)
            || 6
        return {
            kcalPppd: baselineData?.kcal_pppd || 2100,
            hhMember,
            durationDays: baselineData?.reference_period_duration || 365,
            schoolFoodKcal: baselineData?.school_food_kcal || 450,
            schoolDays: baselineData?.schooldays_year || 200
        }
    }
    return getBaselineData
}

export const useRecalculateOnHouseholdProfile = () => {
    const setBssBaselineData = useSetBssBaselineData()
    const stateBaseline = useBssState(useShallow(state => state.baselineData))
    const {baselineData, baselineId, householdProfile} = useBaselineOverviewContext()
    const currentSet = stateBaseline[baselineId]

    return (householdProfile: {
        [wealthGroupId: string]: GroupedDataReturnType
    }) => {
        const ppHouseholdOnWealthgroupId = Object.keys(householdProfile).reduce((acc, wealthGroupId) => {
            return {
                ...acc,
                [wealthGroupId]: householdProfile[wealthGroupId].people_in_household
            }
        }, {} as any)
        const objectsToUpdate: SetBaselineDataProps[] = []
        const updateKcalData = (data: BaselineDataGroup, {
            livestock_type, seasonId, type
        }: { seasonId?: string, livestock_type?: Livestock_Type_Enum, type: BaselineDataOverviewType }) => {
            Object.keys(data).forEach(termId => {
                if ((type === Project_Bss_Data_Type_Enum.HouseholdProfileBase && termId === 'householdProfile')
                    || !GroupColumnsOnType[type as BaselineDataOverviewType]?.computed?.some((computed) => computed.name === 'total_kcal')) {
                    // do nothing on household profile or no total_kcal exists in computed
                    return
                }
                const termData = data[termId]
                if (termData.wealthGroupData) {
                    const dataToUpdateObj = {
                        ...data,
                        type: type as SetBaselineDataProps['type'],
                        baselineId,
                        termId,
                        seasonId,
                        livestock_type,
                        data: {} as NonNullable<SetBaselineDataProps['data']>
                    }
                    Object.keys(termData.wealthGroupData).forEach(wealthGroupId => {
                        const wealthGroupData = termData.wealthGroupData[wealthGroupId]
                        const hhData = {
                            kcalPppd: baselineData?.kcal_pppd || 2100,
                            hhMember: ppHouseholdOnWealthgroupId[wealthGroupId] || 6,
                            durationDays: baselineData?.reference_period_duration || 365,
                            schoolFoodKcal: baselineData?.school_food_kcal || 450,
                            schoolDays: baselineData?.schooldays_year || 200
                        }
                        dataToUpdateObj.data[wealthGroupId] = {
                            ...wealthGroupData,
                        }
                        GroupColumnsOnType[type as BaselineDataOverviewType]?.computed?.forEach((computed) => {
                            if (computed.name === 'total_kcal') {
                                // console.log({hhData, wealthGroupData, computed})
                                const total_kcal = computed.calculator({
                                    ...wealthGroupData as any,
                                    hhData,
                                    term: {
                                        id: termId,
                                        kcal: wealthGroupData?.kcal
                                    }
                                })
                                dataToUpdateObj.data[wealthGroupId] = {
                                    ...wealthGroupData,
                                    total_kcal
                                }
                            }
                        })
                    })
                    objectsToUpdate.push(dataToUpdateObj)
                }
            })
        }
        currentSet?.crops && Object.keys(currentSet.crops).forEach((seasonId) => {
            const seasonData = currentSet.crops[seasonId]
            Object.keys(seasonData).forEach((type) => {
                updateKcalData(seasonData[type as BaselineDataOverviewType], {
                    seasonId,
                    type: type as BaselineDataOverviewType
                })
            })
        })
        currentSet?.livestock?.herd && Object.keys(currentSet.livestock.herd).forEach((livestock_type) => {
            const livestockData = currentSet.livestock.herd[livestock_type as Livestock_Type_Enum]
            Object.keys(livestockData).forEach((type) => {
                updateKcalData(livestockData[type as BaselineDataOverviewType], {
                    livestock_type: livestock_type as Livestock_Type_Enum,
                    type: type as BaselineDataOverviewType
                })
            })
        })
        currentSet?.data && Object.keys(currentSet.data).forEach((type) => {
            const data = currentSet.data[type as BaselineDataOverviewType]
            updateKcalData(data, {type: type as BaselineDataOverviewType})
        })

        objectsToUpdate.forEach(obj => {
                setBssBaselineData(obj)
            }
        )
    }
}