import {BaselineState, BssNoteItem} from "../../state/bssTypes/bssTypes";
import omit from "lodash/omit";
import {staticTermId} from "../staticTermId";
import {BssDataQuery, Livestock_Type_Enum, Project_Bss_Data_Type_Enum, Term} from "../../../lib";


export function mapDownloadBssToState(data: NonNullable<BssDataQuery>) {
    const bssState: BaselineState = {
        data: {} as BaselineState['data'],
        livestock: {
            data: {} as BaselineState['livestock']['data'],
            herd: {} as BaselineState['livestock']['herd']
        },
        crops: {},
    }
    const comments: BssNoteItem[] = []
    if (data) {
        data.project_bss_note.forEach((comment) => {
            comments.push({
                ...comment,
                term_id: staticTermId[comment.type as Project_Bss_Data_Type_Enum] || comment.term_id || null
            })
        })
        data.project_bss_data.forEach((bssItem) => {
            const {stitchedElements, subCategory, ...bssItemData} = bssItem.data || {}
            const type = (subCategory || bssItem.type) as Project_Bss_Data_Type_Enum
            const livestockType = bssItem.livestock_type as Livestock_Type_Enum
            const isResolved = !!bssItem.is_resolved;
            const termId = staticTermId[type] || bssItem.term_id;
            const term = bssItem.term
            const wealthGroupData = {
                ...omit(bssItem, ['wealth_group_id', 'project_id', 'term_id', 'type', 'livestock_type', 'season_id', 'id', 'created_at', 'data', 'is_resolved', 'user_id', 'user']),
                ...bssItemData,
                disabled: !!bssItem.non_significant
            };
            if (livestockType) {
                bssState.livestock.herd[livestockType] = bssState.livestock.herd[livestockType] || {}
                bssState.livestock.herd[livestockType][type] = {
                    ...bssState.livestock.herd[livestockType][type],
                    [termId]: {
                        term,
                        ...bssState.livestock.herd[livestockType][type]?.[termId],
                        isResolved,
                        wealthGroupData: {
                            ...bssState.data[type]?.[termId]?.wealthGroupData,
                            [bssItem.wealth_group_id]: wealthGroupData
                        }
                    }
                }
            } else if (bssItem.season_id) {
                bssState.crops[bssItem.season_id] = bssState.crops[bssItem.season_id] || {}
                bssState.crops[bssItem.season_id][type] = {
                    ...bssState.crops[bssItem.season_id][type],
                    [termId]: {
                        ...bssState.crops[bssItem.season_id][type]?.[termId],
                        term,
                        isResolved,
                        wealthGroupData: {
                            ...bssState.data[type]?.[termId]?.wealthGroupData,
                            [bssItem.wealth_group_id]: wealthGroupData
                        }
                    }
                }
            } else if (type === Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput || type === Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts) {
                bssState.livestock.data[type] = bssState.livestock.data[type] || {}
                bssState.livestock.data[type][termId] = {
                    ...bssState.livestock.data[type][termId],
                    term: term as Term,
                    isResolved,
                    subCategory: bssItem.type,
                    wealthGroupData: {
                        ...bssState.data[type]?.[termId]?.wealthGroupData,
                        [bssItem.wealth_group_id]: wealthGroupData
                    }
                }
            } else {
                bssState.data[type] = bssState.data[type] || {}
                bssState.data[type][termId] = {
                    ...bssState.data[type][termId],
                    term: term as Term,
                    isResolved,
                    stitchedElements,
                    subCategory: bssItem.type,
                    wealthGroupData: {
                        ...bssState.data[type][termId]?.wealthGroupData,
                        [bssItem.wealth_group_id]: wealthGroupData
                    }
                }
            }
        })
    }
    return {bssState, comments}
}
