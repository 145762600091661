import {PropsWithChildren, ReactNode} from 'react'
// import {TssAppBar} from "./TssAppBar";
import {AppBarProps, Box} from "@mui/material";
import {DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE} from "../../config";
import {useTheme} from "@mui/material/styles";
import TssAppBar from "./TssAppBar";
import {RootStyle} from "../dashboard/RootStyle";


export function TssLayout({appBarProps, children, title, headerEndSection, projectId}: PropsWithChildren<{
    title?: ReactNode
    headerEndSection?: ReactNode
    projectId?: string
    appBarProps?: AppBarProps
}>) {
    const theme = useTheme()
    return (
        <RootStyle>
            <TssAppBar appBarProps={appBarProps} title={title} endSection={headerEndSection} projectId={projectId}/>

            <Box component={'main'}
                 flexGrow={1}
                 paddingTop={`${DASHBOARD_HEADER_MOBILE + 24}px`}
                 minHeight={`calc(100vh - ${DASHBOARD_HEADER_DESKTOP + 24}px)`}
                 width={'100%'}
                 sx={{
                     [theme.breakpoints.up('lg')]: {
                         paddingTop: `${DASHBOARD_HEADER_DESKTOP + 24}px`
                     }
                 }}
            >{children}
            </Box>
        </RootStyle>
    )
}
