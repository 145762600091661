import {PropsWithChildren} from "react";
import {
    BaselineOverviewContextProps,
    BaselineOverviewProps,
    GetBssContextDataFilter,
    LivestockBaseEnums
} from "../baselineOverviewTypes";
import sortBy from "lodash/sortBy";
import {prepareCropsData} from "../crops/prepareCropsData";
import {prepareHouseholdData} from "../householdProfile/prepareHouseholdData";
import {prepareLivestockData} from "../livestock/prepareLivestockData";
import {prepareBssData} from "../interviewData/prepareBssData";
import {prepareBaselineTotals} from "../baselineSummarizeTable/prepareBaselineTotals";
import {Project_Bss_Data_Type_Enum} from "../../../lib";
import {BaselineResolveOnContext} from "./BaselineResolveDialog";
import {BaselineOverviewContext} from "../BaselineOverviewProvider";

function extractFirstLetters(str: string) {
    const words = str.split(/\s+/); // Split the string by one or more whitespaces
    const result = words.map(word => word.charAt(0).toUpperCase()); // Extract first letter and convert to uppercase
    return result.join('');
}

export function BaselineOverviewProvider({children, data, baselineId}: PropsWithChildren<BaselineOverviewProps>) {
    const {
        project_by_pk,
        interviews,
        project_interview_crops_season,
        wealth_groups,
        schoolfeeding_data,
        migration_data,
        bss_baseline_view,
        project_interview_base,
        project_interview_livestock,
        project_interview_totals
    } = data
    // const groupedData = groupBy(bss_baseline_view, 'type') as BaselineOverviewContextProps['dataByType']


    const sortedInterviews = sortBy(interviews, [
        'project_interview_base.date',
        'date',
        (item) => item.location.name.replace(/(\d+)/g, (_, p1) => p1.padStart(10, '0'))
    ])
    const wealthGroups = wealth_groups.map(item => ({
        ...item,
        short: extractFirstLetters(item.en) // todo later on with i18n
    }));
    const interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup'] = wealthGroups.reduce((acc, wealthGroup) => {
        return {
            ...acc,
            [wealthGroup.id]: {
                interviewsCount: wealthGroup.project_interviews_aggregate.aggregate?.count ?? 0,
                locationList: wealthGroup.project_interviews_aggregate.nodes.reduce((previousValue, current) => {
                    return {
                        ...previousValue,
                        [current.location_id]: true
                    }
                }, {})
            } as BaselineOverviewContextProps['interviewCountByWealthGroup'][string]
        }
    }, {} as BaselineOverviewContextProps['interviewCountByWealthGroup'])
    const cropsBySeason = prepareCropsData({data: project_interview_crops_season, interviewCountByWealthGroup})
    const householdProfile = prepareHouseholdData({data: project_interview_base, interviewCountByWealthGroup})
    const livestock = prepareLivestockData({data: project_interview_livestock, interviewCountByWealthGroup})

    const bssData = prepareBssData({
        bss_baseline_view,
        interviewCountByWealthGroup,
        migration_data,
        schoolfeeding_data
    })
    const totalsByWealthGroup = prepareBaselineTotals({
        totals: project_interview_totals,
        viewData: bss_baseline_view,
        livestock: project_interview_livestock,
        interviewCountByWealthGroup,
        base: project_interview_base
    })
    const getData = ({type, term_id, livestock_type, seasonId}: GetBssContextDataFilter) => {
        const mappedType = type as Project_Bss_Data_Type_Enum
        if (!mappedType) {
            return null
        }
        if (mappedType === Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts) {
            return livestock?.sales?.[term_id]
        } else if (mappedType === Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput) {
            return livestock?.expenditure?.[term_id]
        } else if (livestock_type) {
            if (LivestockBaseEnums.includes(mappedType)) {
                // @ts-ignore
                return livestock?.grouped?.[livestock_type]?.base?.[mappedType]
            }
            return livestock?.grouped?.[livestock_type]?.data?.[type]?.[term_id]
        } else if (seasonId) {
            if (mappedType === Project_Bss_Data_Type_Enum.CropsGreen || mappedType === Project_Bss_Data_Type_Enum.CropsRoot) {
                return cropsBySeason?.[seasonId]?.[mappedType]
            }
            // @ts-ignore
            return cropsBySeason?.[seasonId]?.[mappedType]?.[term_id]
        } else if (mappedType === Project_Bss_Data_Type_Enum.HouseholdProfileBase
            || mappedType === Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets
            || mappedType === Project_Bss_Data_Type_Enum.HouseholdCerealReserves) {
            return householdProfile?.[mappedType]
        }
        return bssData?.[mappedType]?.[term_id]
    }
    return (
        <BaselineOverviewContext.Provider value={{
            baselineId,
            baselineData: project_by_pk,
            interviews: sortedInterviews,
            wealthGroups,
            dataByType: bssData as any,
            cropsBySeason,
            householdProfile,
            livestock,
            totalsByWealthGroup,
            interviewCountByWealthGroup,
            getData
        }}>
            {children}
            <BaselineResolveOnContext/>
        </BaselineOverviewContext.Provider>
    )
}