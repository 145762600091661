import {Divider, Stack, Table, TableBody, TableContainer, Typography} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {Project_Bss_Data_Type_Enum, Project_Interview_Data_Type_Enum} from "../../../lib";
import {useTranslation} from "react-i18next";

export function LivestockExpenditureSales() {
    const {t} = useTranslation()
    const {livestock} = useBaselineOverviewContext()
    const expenditure = livestock.expenditure
    const sales = livestock.sales
    return (
        <Stack spacing={2}>
            <Divider/>


            <Typography variant={'subtitle2'}>{t('incomeFromLivestockProducts')}</Typography>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        {Object.keys(sales).map((key, index) => {
                            const current = sales[key as Project_Interview_Data_Type_Enum]
                            return (
                                <BaselineTableRow
                                    key={key + '_livestock_term_sales'}
                                    isEven={index % 2 === 0}
                                    type={Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts}
                                    data={current.data}
                                    term={current.term}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider/>
            <Typography variant={'subtitle2'}>{t('expenditureOnInputs')}</Typography>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        {Object.keys(expenditure).map((key, index) => {
                            const current = expenditure[key as Project_Interview_Data_Type_Enum]
                            return (
                                <BaselineTableRow
                                    key={key + '_livestock_term_expenses'}
                                    isEven={index % 2 === 0}
                                    type={Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput}
                                    data={current.data}
                                    term={current.term}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}