import {Livestock_Type_Enum} from '../../../../lib'

export const hideMilkOnLivestockType = (livestockType: Livestock_Type_Enum) =>
    [Livestock_Type_Enum.Chicken, Livestock_Type_Enum.OtherPoultry, Livestock_Type_Enum.Oxen, Livestock_Type_Enum.Donkey, Livestock_Type_Enum.Pig].includes(livestockType)

export const hasSecondaryMilkOnLivestockType = (livestockType: Livestock_Type_Enum) =>
    [Livestock_Type_Enum.Cattle, Livestock_Type_Enum.Camel, Livestock_Type_Enum.Sheep, Livestock_Type_Enum.Goat].includes(livestockType)


export const hasEggLivestockType = (livestockType: Livestock_Type_Enum) =>
    [Livestock_Type_Enum.Chicken, Livestock_Type_Enum.OtherPoultry].includes(livestockType)
