import {Divider, Stack, Table, TableBody, TableContainer, Typography} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {Project_Bss_Data_Type_Enum} from "../../../lib";

export function HouseholdCerealReserves() {
    const {householdProfile} = useBaselineOverviewContext()
    const {HOUSEHOLD_CEREAL_RESERVES} = householdProfile
    const length = Object.keys(HOUSEHOLD_CEREAL_RESERVES || {}).length
    if (!length) {
        return null
    }
    return (
        <Stack spacing={2}>
            <Divider/>
            <Typography variant={'subtitle2'}>
                Household Cereal Reserves
            </Typography>

            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        {Object.keys(HOUSEHOLD_CEREAL_RESERVES).map((key, index) => {
                            const data = HOUSEHOLD_CEREAL_RESERVES[key]

                            return (
                                <BaselineTableRow type={Project_Bss_Data_Type_Enum.HouseholdCerealReserves}
                                                  term={data.term}
                                                  isEven={index % 2 === 0}
                                                  data={data.data} key={data.term?.id}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}