import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {HouseholdBaseData} from "./HouseholdBaseData";
import {HouseholdAssets} from "./HouseholdAssets";
import {HouseholdCerealReserves} from "./HouseholdCerealReserves";

export function HouseholdProfileAccordion() {
    const {t} = useTranslation()
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
            >
                <Typography variant={'h5'}>
                    {t('householdProfile')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <HouseholdBaseData/>
                <HouseholdCerealReserves/>
                <HouseholdAssets/>
            </AccordionDetails>
        </Accordion>
    )
}