import {Badge, Box, Fab, Stack} from "@mui/material";
import {Add, Clear} from "@mui/icons-material";
import {useBaselineStitchingElements, useBaselineStitchingReset} from "./baselineStitchingState";
import {useSetBaselineResolverState} from "../resolveView/baselineResolverState";
import {Project_Bss_Data_Type_Enum} from "../../../lib";

export function BaselineStitchingFab() {
    const stitchedElements = useBaselineStitchingElements()
    const unset = useBaselineStitchingReset()
    const setResolverState = useSetBaselineResolverState()

    if (!stitchedElements.length) {
        return null
    }
    return (
        <Box position={'fixed'} bottom={24} right={24}>
            <Stack direction={'row'}>
                <div>
                    <Fab size={'small'} color={'error'}
                         sx={{width: 26, height: 26, minHeight: 26}}
                         onClick={() => {
                             unset()
                         }}>
                        <Clear fontSize={'small'}/>
                    </Fab>
                </div>
                <Badge badgeContent={stitchedElements.length} color={'info'} sx={{zIndex: '1060!important'}}>
                    <Fab size={'large'} color={'primary'} onClick={() => {
                        const firstElement = stitchedElements[0]
                        setResolverState({
                            ...firstElement,
                            seasonId: undefined,
                            livestock_type: undefined,
                            term: null,
                            type: Project_Bss_Data_Type_Enum.CombinedData,
                            stitchedElements: stitchedElements
                        })
                        unset()
                    }}>
                        <Add/>
                    </Fab>
                </Badge>
            </Stack>
        </Box>
    )
}