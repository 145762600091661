import {Alert, Box, Button, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useOverwriteBaselineState, useOverwriteCommentsState} from "../../state/bssState";
import {BaselineState} from "../../state/bssTypes/bssTypes";

export function BaselineStateReset({baselineId, afterReset}: { baselineId: string, afterReset?: () => void }) {
    const {t} = useTranslation()
    const overwriteBaselineState = useOverwriteBaselineState()
    const overwriteCommentsState = useOverwriteCommentsState()

    return (
        <Box margin={2}>
            <Alert severity="warning">
                <Stack spacing={1} width={'100%'}>
                    <Typography>
                        {t('bssResetData')}
                    </Typography>
                    <Button
                        fullWidth
                        onClick={() => {
                            const conf = window.confirm(t('confirmResetBaseline'))
                            if (conf) {
                                overwriteBaselineState(baselineId, {
                                    crops: {},
                                    data: {},
                                    livestock: {
                                        herd: {},
                                        data: {}
                                    },
                                } as BaselineState)
                                overwriteCommentsState(baselineId, [])
                                afterReset?.()
                            }
                        }}>
                        reset
                    </Button>
                </Stack>
            </Alert>
        </Box>
    )
}