import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {BaselineOverviewTableSectionRowDetailProps, GroupedDataReturnType} from "../baselineOverviewTypes";
import {useTranslation} from "react-i18next";
import {BssColsToIncludeNulls, getDataField, sumOrAvgData} from "../baselineGroupHelper";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {GroupColumnsOnType} from "../baselineDataFields";
import {DETAIL_SINGLE_COLUMN_WIDTH} from "./tableRowConfig";


export function BaselineTableRowDetail({
                                           isExpanded,
                                           type,
                                           showMoreRows,
                                           wealthGroupData
                                       }: BaselineOverviewTableSectionRowDetailProps) {
    const {t} = useTranslation()
    const {interviews} = useBaselineOverviewContext()
    const groupedByInterviewId: {
        [k: string]: GroupedDataReturnType['data']
    } = interviews.reduce((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.id]: wealthGroupData?.data
            // @ts-ignore
            .filter(d => {
                // @ts-ignore
                if ('project_interview_base' in d) {
                    return d.project_interview_base?.project_interview?.location_id === currentValue.location_id
                    // @ts-ignore
                } else if ('project_interview' in d) {
                    return d.project_interview?.location_id === currentValue.location_id
                }
            })
    }), {})

    return (
        <TableContainer>
            <Table size={'small'} padding={'checkbox'}>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        {isExpanded && interviews.map((loc, i) => (
                            <TableCell key={loc.id} sx={{whiteSpace: 'nowrap'}}>
                                <Box width={DETAIL_SINGLE_COLUMN_WIDTH}>

                                    # {i + 1}
                                </Box>
                            </TableCell>
                        ))}
                        <TableCell sx={{whiteSpace: 'nowrap'}}>
                            AVG
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{
                    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
                        borderBottom: 'none'
                    }
                }}>
                    {GroupColumnsOnType[type]?.[showMoreRows ? 'large' : 'short'].map(column => (
                        <TableRow key={column}>
                            <TableCell sx={{textAlign: 'right', paddingRight: 2, whiteSpace: 'nowrap'}}>
                                {t('rowType.' + column)}
                            </TableCell>
                            {isExpanded && interviews.map(loc => {
                                const currentVal = sumOrAvgData({
                                    // @ts-ignore
                                    data: groupedByInterviewId[loc.id],
                                    col: column
                                });
                                return (
                                    <TableCell key={loc.id}>
                                        {currentVal ? getDataField(
                                            currentVal) : !BssColsToIncludeNulls.includes(column) ? 0 : '-'}
                                    </TableCell>
                                )
                            })}
                            <TableCell sx={{fontWeight: 'bold'}}>
                                {wealthGroupData?.[column]?.avg1}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}