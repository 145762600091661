import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Tab, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {LivestockData} from "./LivestockData";
import {Livestock_Type_Enum} from "../../../lib";
import {LivestockExpenditureSales} from "./LivestockExpenditureSales";
import {LivestockHerdSlaughter} from "./LivestockHerdSlaughter";
import {useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";

export function LivestockAccordion() {
    const {t} = useTranslation()
    const {livestock} = useBaselineOverviewContext()
    const [tab, setTab] = useState<string>('0')

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
            >
                <Typography variant={'h5'}>
                    {t('livestockLabel')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TabContext value={tab}>
                    <TabList onChange={(_, value) => {
                        setTab(value)
                    }}>
                        {Object.keys(livestock.grouped).map((livestockType, index) => {
                            return (
                                <Tab key={livestockType} label={t(`livestock.type.${livestockType.toLowerCase()}`)}
                                     value={`${index}`}/>
                            )
                        })}
                        <Tab value={'other'} label={t('other')}/>
                    </TabList>
                    {Object.keys(livestock.grouped).map((livestockType, index) => {
                        return (
                            <TabPanel value={`${index}`} sx={{paddingX: 0}} key={`${livestockType}_panel`}>
                                <LivestockHerdSlaughter livestockType={livestockType as Livestock_Type_Enum}/>
                                <LivestockData livestockType={livestockType as Livestock_Type_Enum}/>
                            </TabPanel>
                        )
                    })}
                    <TabPanel value={'other'} sx={{paddingX: 0}}>
                        <LivestockExpenditureSales/>
                    </TabPanel>
                </TabContext>
            </AccordionDetails>
        </Accordion>
    )
}