import {Month_Enum} from "../../gql/__codegen__/react-query";

export type TermsAutocompleteType = {
    id: string
    label: string
    kcal?: number
    categoryIds?: string[]
};

export enum Frequency {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    OTHER = 'Other'
}

export type FrequencyData = {
    frequency_type: Frequency
    frequency_weeks?: number
    frequency_days?: number
    frequency_months?: number
    frequency_months_multi?: Month_Enum[]
}

export enum Sale_Purchase_Type_Enum {
    Sale = 'SALE',
    Purchase = 'PURCHASE'
}
