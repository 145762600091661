import {Box, Button, Divider, IconButton, Stack, Typography} from "@mui/material";
import {useSetBssComment} from "../../state/bssState";
import {BssNoteFragment, Project_Bss_Data_Type_Enum} from "../../../lib";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Delete, DoneAll, Edit, Loupe} from "@mui/icons-material";
import {useNotesContext} from "./NotesContextProvider";
import {useTranslation} from "react-i18next";
import {useDate} from "../../../hooks/useDate";
import {BssNoteItem} from "../../state/bssTypes/bssTypes";
import {useUserId} from "@nhost/react";
import {getUserNameShort} from "../../../lib/helpers/userNameHelper";
import {headlineTranslationMapping} from "../translationHelper";

import {useSetBaselineResolverState} from "../resolveView/baselineResolverState";

type BssNotesContentListProps = {
    options?: BssNoteFragment
}

function BssNotesContentListItem({item}: { item: BssNoteItem }) {
    const {getData} = useBaselineOverviewContext()
    const userId = useUserId()
    const setComment = useSetBssComment()
    const setDialog = useSetBaselineResolverState()
    const {t} = useTranslation()
    const {setMessage, currentEditMessage, isAllNotes, triggerClose} = useNotesContext()
    const {getDate} = useDate()
    const isOwn = item.user_id === userId
    const itemData = getData({
        type: item.type as Project_Bss_Data_Type_Enum,
        seasonId: item.season_id,
        term_id: item.term_id,
        livestock_type: item.livestock_type
    })
    const term = itemData?.term
    const wealthGroupData = itemData?.data ?? itemData

    return (
        <Stack key={item.id} spacing={1}>
            <Divider>
                <Stack direction={'row'}>
                    <Typography variant={'caption'}>
                        <strong>{isOwn ? 'Me' : item.user ? getUserNameShort(item.user) : null}</strong>&nbsp;-&nbsp;
                        {getDate(item.created_at, {
                            timeStyle: 'short',
                            dateStyle: 'short'
                        })}
                    </Typography>
                </Stack>
            </Divider>

            <Stack direction={'row'} spacing={2}>
                <Box flex={1}>
                    <div dangerouslySetInnerHTML={{__html: item.message as string}}/>
                    {isAllNotes && (
                        <Box marginTop={1}>

                            <Button
                                size={'small'}
                                color={'inherit'}
                                startIcon={<Loupe fontSize={'small'}/>}
                                onClick={() => {
                                    triggerClose()
                                    setDialog({
                                        term: term ? term : {
                                            id: item.term_id,
                                            en: t(headlineTranslationMapping[item.type as Project_Bss_Data_Type_Enum]),
                                            term_category_mns: []
                                        },
                                        seasonId: item.season_id,
                                        type: item.type,
                                        livestock_type: item.livestock_type,
                                        groupedByWealthGroupData: wealthGroupData,
                                    } as any)
                                }}>
                                {t(headlineTranslationMapping[item.type as Project_Bss_Data_Type_Enum])}
                                {term && (
                                    //@ts-ignore
                                    <>&nbsp;-&nbsp;{term?.en}</>
                                )}
                            </Button>
                        </Box>
                    )}
                </Box>
                <div>
                    <Stack spacing={1}
                           direction={'row'} justifyContent={'flex-end'}>
                        <IconButton size={'small'}
                                    color={item.is_done ? 'warning' : 'success'}
                                    title={item.is_done ? t('markUnresolved') : t('markResolved')}
                                    onClick={() => {
                                        const conf = window.confirm(t('confirmMarkAsDone'))
                                        if (conf) {
                                            setComment({
                                                ...item,
                                                is_done: !item.is_done
                                            })
                                        }
                                    }} disabled={!!currentEditMessage}>
                            <DoneAll fontSize={'small'}/>
                        </IconButton>
                        <IconButton size={'small'} onClick={() => setMessage(item)} disabled={!!currentEditMessage}>
                            <Edit fontSize={'small'}/>
                        </IconButton>
                        <IconButton
                            size={'small'}
                            color={'error'}
                            disabled={!!currentEditMessage}
                            onClick={() => {
                                const conf = window.confirm(t('confirmDeleteComment'))
                                if (conf) {
                                    setComment({
                                        ...item,
                                        is_done: true
                                    })
                                }
                            }}>
                            <Delete fontSize={'small'}/>
                        </IconButton>
                    </Stack>
                </div>
            </Stack>
        </Stack>
    )
}

export function BssNotesContentList() {
    const {list} = useNotesContext()
    return (
        <Stack spacing={2} className={'lm-comment-list'}>
            {list?.map((item, index) => (
                <BssNotesContentListItem key={item.id} item={item}/>
            ))}
        </Stack>
    )
}