import {useFormContext} from "react-hook-form";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";

export function useDisabledHook(): { [k: string]: boolean } {
    // const rows = useWatch()
    const {watch} = useFormContext()
    const {wealthGroups, interviews} = useBaselineOverviewContext()

    return wealthGroups.reduce((previousValue, currentValue) => {
        return {
            ...previousValue,
            [currentValue.id]: !!watch(`${currentValue.id}.disabled`)
        }
    }, {})
}