import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";
import {BssResolveElement} from "../baselineOverviewTypes";


type BaselineStitchingState = {
    elements: BssResolveElement[],
    toggleElement: (element: BssResolveElement) => void,
    reset: () => void,
}

const useBaselineStitchingState = create<BaselineStitchingState>()((setState, getState, store) => {
    return {
        elements: [],
        toggleElement: (element) => {
            if (getState().elements.includes(element)) {
                console.log("exists!")
                return setState((state) => {
                    return {
                        elements: state.elements.filter((el) => el !== element)
                    }
                })
            }
            console.log("does not exist add it")
            setState((state) => {
                return {
                    elements: [...state.elements, element]
                }
            })
        },
        reset: () => setState(() => ({
            elements: []
        }))
    }
})

export const useBaselineStitchingElements = () => useBaselineStitchingState(useShallow(state => state.elements))
export const useBaselineStitchingToggle = () => useBaselineStitchingState(useShallow(state => state.toggleElement))
export const useBaselineStitchingReset = () => useBaselineStitchingState(useShallow(state => state.reset))