import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum} from "../../../lib";
import {CsvFieldColumnName, CsvTypeName} from "../baselineDataFields";
import {CsvDataRowItem} from "./baselineCsvTypes";
import {useTranslation} from "react-i18next";
import {OverwriteBaselineCsvFieldHelper} from "../baselineDataFieldHelper/baselineCsvFieldHelper";
import {AvailableBaselineTypes} from "../baselineOverviewTypes";
import {getNumberInFormat} from "../../../lib/helpers/numberFormat";
import {useCallback} from "react";

type UseBaselineCsvRawDataHelperProps = {
    type: Project_Bss_Data_Type_Enum,
    selectedFields?: CsvFieldColumnName[],
    seasonId?: string
    livestock_type?: Livestock_Type_Enum
    term_id?: string
    selectedCsvType: CsvTypeName
};

export function useBaselineCsvRawDataHelper({
                                                type,
                                                selectedFields,
                                                seasonId,
                                                term_id,
                                                livestock_type
                                            }: UseBaselineCsvRawDataHelperProps) {
    const {t} = useTranslation()
    const ctx = useBaselineOverviewContext()
    const {interviews, wealthGroups, getData, baselineId} = ctx

    return useCallback(
        () => {
            if (!selectedFields) {
                return []
            }
            const currentRawDataContext = getData({
                type,
                livestock_type,
                term_id: term_id as string,
                seasonId
            })
            const helper = ({currentData, columnName, wealthGroupId, locationId}: {
                columnName: CsvFieldColumnName,
                currentData: any
                wealthGroupId: string
                locationId: string
            }): number | string | null => {
                console.log({currentRawDataContext, currentData})
                const isObjectKey = typeof columnName === 'object';
                const columnKeyName = isObjectKey ? columnName?.key : columnName
                if (columnKeyName.startsWith('__')) {
                    return null
                }
                if (!currentData) {
                    return null
                }
                const overwriteBaselineFunction = OverwriteBaselineCsvFieldHelper[type]?.[columnKeyName];
                if (overwriteBaselineFunction) {
                    const val = overwriteBaselineFunction({
                        ctx,
                        data: currentData,
                        wealthGroupId,
                        locationId,
                        livestock_type
                    })
                    return typeof val === 'number' ? getNumberInFormat(val) : val
                }

                const item = (isObjectKey && typeof columnName?.calculator === 'function')
                    ? columnName.calculator({
                        ...currentData,
                        wealthGroupId,
                        baselineId
                    } as any)
                    : currentData?.[columnKeyName as AvailableBaselineTypes]
                return typeof item === 'number' ? getNumberInFormat(item) : item
            }
            const rawData: CsvDataRowItem['csv'] = [[], [], ...selectedFields.map(() => [])]
            wealthGroups.forEach((wealthGroup, wealthGroupIndex) => {
                interviews.forEach((interview) => {
                    rawData[0].push(wealthGroup.short)
                    rawData[1].push(interview.location.name)
                    const wealthGroupData: any = currentRawDataContext?.data ?? currentRawDataContext

                    const currentDataSet = wealthGroupData?.[wealthGroup.id]?.data
                    const currentData = currentDataSet?.find((d: any) => {
                        if ('project_interview_base' in d) {
                            return d.project_interview_base?.project_interview?.location_id === interview.location_id
                        } else if ('project_interview' in d) {
                            return d.project_interview?.location_id === interview.location_id
                        }
                    })
                    selectedFields.forEach((columnName, i) => {
                        rawData[i + 2].push(helper({
                            columnName,
                            currentData,
                            wealthGroupId: wealthGroup.id,
                            locationId: interview.location_id
                        }))
                    })
                })
                // add one empty column after each wealth group
                rawData.forEach((_, i) => {
                    if (wealthGroupIndex < wealthGroups.length) {
                        rawData[i].push(null)
                    }
                })
            })
            rawData[0].unshift('')
            rawData[1].unshift('')
            selectedFields.forEach((columnName, i) => {
                const isObjectKey = typeof columnName === 'object';

                const columnKeyName = isObjectKey ? columnName?.key : columnName

                rawData[i + 2].unshift(t(`rowType.${columnKeyName.replace('__', '')}`))
            })
            return rawData
        }, [baselineId, ctx, getData, interviews, livestock_type, seasonId, selectedFields, term_id, t, type, wealthGroups])
}