import {createBrowserRouter, Route, RouterProvider} from 'react-router-dom'
import {createRoutesFromElements} from "react-router";
import Login from "../auth/Login";
import EmailVerified from "../auth/EmailVerified";
import ForgotPassword from "../auth/ForgotPassword";
import ChangePassword from "../auth/ChangePassword";
import {ProjectsScreen} from "../../bss/overview/ProjectsScreen";
import {BaselineScreen} from "../../bss/baseline/BaselineScreen";
import {ViteAuthGuardOffline} from "../ViteAuthGuardOffline";
import {ViteBssGlobalProvider} from "./ViteBssGlobalProvider";
import {BssAdmin} from "../../bss/admin/BssAdmin";

// need to make sure that not unnecessary calls are shared in RootApp
const tssReactRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ViteBssGlobalProvider/>}>
            <Route element={<ViteAuthGuardOffline/>}>
                <Route index element={<ProjectsScreen/>}></Route>
                <Route path={'/baseline'} element={<BaselineScreen/>}></Route>
                <Route path={'/admin'} element={<BssAdmin/>}></Route>

            </Route>
            <Route path={'/auth'}>
                <Route path={'login'} element={<Login/>}/>
                <Route path={'email-verified'} element={<EmailVerified/>}/>
                <Route path={'forgot-password'} element={<ForgotPassword/>}/>
                <Route path={'change-password'} element={<ChangePassword/>}/>
            </Route>
        </Route>
    )
);

export const BssReactRouter = () => (
    <RouterProvider router={tssReactRouter}/>
)