import {PropsWithChildren, useEffect} from "react";
import {useUserId} from "@nhost/react";
import {useTssSetLastUserId} from "../../lib";

export function AppOfflineUserIdProvider({children}: PropsWithChildren) {
    const userId = useUserId()
    const setLastUserId = useTssSetLastUserId()

    useEffect(
        () => {
            if (userId) {
                setLastUserId(userId)
            }
        },
        [userId, setLastUserId]
    )
    return (
        <>{children}</>
    )
}