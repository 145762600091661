import {useTranslation} from "react-i18next";
import {PopperContainer} from "../../../common/popper/PopperContainer";
import {BackupTable} from "@mui/icons-material";
import {BaselineCsvDialogContent} from "./BaselineCsvDialogContent";

export function BaselineCsvTable() {
    const {t} = useTranslation()
    return (
        <PopperContainer
            trigger={{
                color: 'inherit',
                startIcon: <BackupTable/>,
                children: t('bssCsvTable')
            }}
            dialogTitle={t('bssCsvTable')}
            appBarProps={{
                color: 'transparent'
            }}
            dialogProps={{
                fullScreen: true,
                PaperProps: {
                    sx: {
                        maxWidth: 'inherit',

                    }
                }
            }}>
            <BaselineCsvDialogContent/>
        </PopperContainer>
    )
}