import {NhostProvider} from "@nhost/react";
import {appNhostClient} from "../../lib/nhost/appNhostClient";
import {AppOfflineUserIdProvider} from "../../common/layout/AppOfflineUserIdProvider";
import {AppSetupContainer} from "../../common/layout/AppSetupContainer";
import {Outlet} from "react-router-dom";
import {NhostSimpleReactQueryProvider} from "../../lib/nhost/NhostSimpleReactQueryProvider";
import AllBssVariableSetup from "../../provider/AllBssVariableSetup";

export function ViteBssGlobalProvider() {
    // todo ContainerAuth needs to be handled differently
    // todo handle AllTssVariableSetup on per page basis?
    return (
        <NhostProvider nhost={appNhostClient}>
            <AppOfflineUserIdProvider>
                <NhostSimpleReactQueryProvider>
                    <AppSetupContainer>
                        <AllBssVariableSetup>
                            <Outlet/>
                        </AllBssVariableSetup>
                    </AppSetupContainer>
                </NhostSimpleReactQueryProvider>
            </AppOfflineUserIdProvider>
        </NhostProvider>
    )
}