import {createContext, useContext} from "react";
import {BaselineOverviewContextProps} from "./baselineOverviewTypes";


export const BaselineOverviewContext = createContext<BaselineOverviewContextProps>({
    baselineId: '',
    baselineData: {} as BaselineOverviewContextProps['baselineData'],
    wealthGroups: [],
    interviews: [],
    dataByType: {} as BaselineOverviewContextProps['dataByType'],
    cropsBySeason: {} as BaselineOverviewContextProps['cropsBySeason'],
    householdProfile: {} as BaselineOverviewContextProps['householdProfile'],
    livestock: {} as BaselineOverviewContextProps['livestock'],
    totalsByWealthGroup: {},
    interviewCountByWealthGroup: {},
    getData: () => null
})


export const useBaselineOverviewContext = () => useContext(BaselineOverviewContext)