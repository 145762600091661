import {BaselineState} from "../../state/bssTypes/bssTypes";
import {
    BaselineSummarizeExpenditureKeys,
    BaselineSummarizeIncomeKeys,
    BaselineSummarizeKcalKeys
} from "./baselineSummarizeItems";
import {getCropsResolvedTotals, getDataResolvedTotals, getLivestockResolvedTotals} from "./helpers/sumResolvedValues";


type BaselineSummarizeTotalsValues = {
    resolved: {
        [wealthGroupId: string]: {
            kcal?: number
            income?: number
            expenditure?: number
        }
    }
};

type BaselineSummarizeTotals = {
    kcal: {
        [key in BaselineSummarizeKcalKeys]: BaselineSummarizeTotalsValues
    }
    income: {
        [key in BaselineSummarizeIncomeKeys]: BaselineSummarizeTotalsValues
    }
    expenditure: {
        [key in BaselineSummarizeExpenditureKeys]: BaselineSummarizeTotalsValues
    }
};

const summarizeDataOnSection = (data: BaselineSummarizeTotals, field: 'kcal' | 'income' | 'expenditure') => {
    const res: {
        [wealthGroupId: string]: {
            kcal?: number
            income?: number
            expenditure?: number
        }
    } = {}
    Object.keys(data[field]).forEach((sectionIdentifier) => {
        // @ts-ignore
        const section = data[field][sectionIdentifier].resolved
        Object.keys(section).forEach((wealthGroupId) => {
            const sectionItem = section[wealthGroupId]
            res[wealthGroupId] = res[wealthGroupId] || {}
            res[wealthGroupId][field] = res[wealthGroupId][field] || 0
            res[wealthGroupId][field] += sectionItem[field] || 0
        })
    })
    console.log({res})
    return res
}

export const getBaselineResolvedTotals = (bssState: BaselineState): BaselineSummarizeTotals => {
    const crops = getCropsResolvedTotals(bssState?.crops)
    const {totalsLivestock, totalsLivestockProduct} = getLivestockResolvedTotals(bssState?.livestock)
    const allOthers = getDataResolvedTotals(bssState?.data)
    console.log({allOthers, bssState})
    const sumData: BaselineSummarizeTotals = {
        kcal: {
            summarize: {
                resolved: {}
            },
            crops: {
                resolved: crops
            },
            livestockProducts: {
                resolved: totalsLivestock
            },
            purchases: {
                resolved: allOthers.kcal.purchases
            },
            foodAid: {
                resolved: allOthers.kcal.foodAid
            },
            fishingWildFood: {
                resolved: allOthers.kcal.fishingWildFood
            },
            migrationMeals: {
                resolved: allOthers.kcal.migrationMeals
            }
        },
        income: {
            summarize: {
                resolved: {}
            },
            crops: {
                resolved: crops
            },
            livestockProducts: {
                resolved: totalsLivestockProduct
            },
            livestockSales: {
                resolved: totalsLivestock
            },
            remittanceCreditAssistance: {
                resolved: allOthers.income.remittanceCreditAssistance
            },
            fishingWildFoodSales: {
                resolved: allOthers.income.fishingWildFoodSales
            },
            labourJobsMigration: {
                resolved: allOthers.income.labourJobsMigration
            },
            selfEmployment: {
                resolved: allOthers.income.selfEmployment
            }
        },
        expenditure: {
            summarize: {
                resolved: {}
            },
            inputCroppingCosts: {
                resolved: crops
            },
            inputLivestockCosts: {
                resolved: totalsLivestock
            },
            nonStapleFoods: {
                resolved: allOthers.expenditure.nonStapleFoods
            },
            stapleFoods: {
                resolved: allOthers.expenditure.stapleFoods
            },
            otherProductionCosts: {
                resolved: allOthers.expenditure.otherProductionCosts
            },
            education: {
                resolved: allOthers.expenditure.education
            },
            health: {
                resolved: allOthers.expenditure.health
            },
            householdItems: {
                resolved: allOthers.expenditure.householdItems
            },
            other: {
                resolved: allOthers.expenditure.other
            },
            loanRepayment: {
                resolved: allOthers.expenditure.loanRepayment
            }
        }
    }

    sumData.kcal.summarize.resolved = summarizeDataOnSection(sumData, 'kcal')
    sumData.income.summarize.resolved = summarizeDataOnSection(sumData, 'income')
    sumData.expenditure.summarize.resolved = summarizeDataOnSection(sumData, 'expenditure')
    return sumData
}