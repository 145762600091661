import { TextFieldElement } from 'react-hook-form-mui'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { TextFieldProps } from '@mui/material'

type TextFieldHiddenInputProps = {
  name: string
  value: TextFieldProps['value']
}

export function TextFieldHiddenInput({ name, value }: TextFieldHiddenInputProps) {
  const { setValue } = useFormContext()
  useEffect(
    () => {
      let isActive = true
      if (isActive) {
        setValue(name, value)
      }
      return () => {
        isActive = false
      }
    }, [name, setValue, value]
  )
  return (
    <TextFieldElement name={name} type={'hidden'} sx={{ display: 'none' }} />
  )
}
