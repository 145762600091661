import {Project_Bss_Data_Type_Enum, Project_Interview_Data_Type_Enum} from "../../lib";

export const headlineTranslationMapping: {
    [key in Project_Bss_Data_Type_Enum]: string
} = {
    [Project_Bss_Data_Type_Enum.ExpenditureFood]: 'purchasesFood',
    [Project_Bss_Data_Type_Enum.ExpenditureNonFood]: 'purchasesNonFood',
    [Project_Bss_Data_Type_Enum.Employment]: 'labourJobs',
    [Project_Bss_Data_Type_Enum.SelfEmployment]: 'selfEmployment',
    [Project_Bss_Data_Type_Enum.FishingWildFood]: 'fishingWildFood',
    [Project_Bss_Data_Type_Enum.FishingWildFoodExpenses]: 'fishingWildFoodExpenses',
    [Project_Bss_Data_Type_Enum.LabourExchange]: 'labourExchange',
    [Project_Bss_Data_Type_Enum.Migration]: 'migration',
    // @ts-ignore
    [Project_Interview_Data_Type_Enum.RemittancesCredit]: 'remittanceCredit',
    [Project_Bss_Data_Type_Enum.FoodAid]: 'foodAid',
    [Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts]: 'incomeFromLivestockProducts',
    [Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput]: 'expenditureOnInputs',
    // new
    [Project_Bss_Data_Type_Enum.LivestockGivenAway]: 'livestockData.LIVESTOCK_GIVEN_AWAY',
    [Project_Bss_Data_Type_Enum.LivestockSale]: 'livestockSale',
    [Project_Bss_Data_Type_Enum.LivestockSlaughter]: 'livestock.slaughtered',
    [Project_Bss_Data_Type_Enum.LivestockExchanged]: 'livestockData.LIVESTOCK_EXCHANGED',
    [Project_Bss_Data_Type_Enum.LivestockHerd]: 'livestock.stepper.basic',
    [Project_Bss_Data_Type_Enum.LivestockExpenditure]: 'expenditure',
    [Project_Bss_Data_Type_Enum.MilkProductionPeak]: 'livestock.peakMilking',
    [Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]: 'livestock.peakMilkingSecondary',
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeak]: 'livestock.offPeakMilking',
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]: 'livestock.offPeakMilkingSecondary',
    [Project_Bss_Data_Type_Enum.CropsProduction]: 'crops.production',
    [Project_Bss_Data_Type_Enum.CropsGreen]: 'crops.greenCrops',
    [Project_Bss_Data_Type_Enum.CropsRoot]: 'crops.continuousHarvest',
    [Project_Bss_Data_Type_Enum.CropsExpenditure]: 'crops.expenditure',
    [Project_Bss_Data_Type_Enum.RemittancesCreditIncome]: 'remittanceCreditIncome',
    [Project_Bss_Data_Type_Enum.RemittancesCreditExpenses]: 'remittanceCreditExpenditure',
    [Project_Bss_Data_Type_Enum.HouseholdProfileBase]: 'householdProfile',
    [Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets]: 'productiveAssets',
    [Project_Bss_Data_Type_Enum.HouseholdCerealReserves]: 'leftoverCereal',
    [Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding]: 'schoolFeeding',
    [Project_Bss_Data_Type_Enum.CombinedData]: 'baselineMergedData'
}