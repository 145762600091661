import {Stack, Table, TableBody, TableContainer, Typography} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum, Project_Interview_Data_Type_Enum} from "../../../lib";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {useTranslation} from "react-i18next";

export function LivestockData({livestockType}: { livestockType: Livestock_Type_Enum }) {
    const {t} = useTranslation()
    const {livestock} = useBaselineOverviewContext()
    const data = livestock.grouped[livestockType].data
    const preparedData = {
        [Project_Interview_Data_Type_Enum.LivestockExpenditure]: data[Project_Interview_Data_Type_Enum.LivestockExpenditure],
        [Project_Interview_Data_Type_Enum.LivestockExchanged]: data[Project_Interview_Data_Type_Enum.LivestockExchanged],
        [Project_Interview_Data_Type_Enum.LivestockSale]: data[Project_Interview_Data_Type_Enum.LivestockSale],
        // [Project_Interview_Data_Type_Enum.LivestockGivenAway]: data[Project_Interview_Data_Type_Enum.LivestockGivenAway]
    }
    return (
        <Stack spacing={2}>
            {Object.keys(preparedData).map(key => {
                if (!preparedData[key as Project_Interview_Data_Type_Enum.LivestockExchanged]) {
                    return null
                }
                return (
                    <Stack spacing={2} key={key}>
                        <Typography variant={'subtitle2'}>
                            {t(`livestockData.${key}`)}
                        </Typography>
                        <TableContainer>
                            <Table size={'small'} padding={'checkbox'}>
                                <TableBody>
                                    {Object.keys(preparedData[key as Project_Bss_Data_Type_Enum.LivestockExchanged] as any).map((termId, index) => {
                                        const current = preparedData[key as Project_Bss_Data_Type_Enum.LivestockExchanged][termId]
                                        return (
                                            <BaselineTableRow
                                                key={termId}
                                                isEven={index % 2 === 0}
                                                type={key as Project_Bss_Data_Type_Enum}
                                                data={current.data}
                                                livestock_type={livestockType}
                                                term={current.term}
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                )
            })}
        </Stack>
    )
}