import {create} from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'
import {BaselineData, BaselineFilterState, BaselineState, BssState} from "./bssTypes/bssTypes";
import {produce} from "immer";
import {BssNoteFragment, Project_Bss_Data_Type_Enum} from "../../lib";
import {useBaselineOverviewContext} from "../baseline/BaselineOverviewProvider";
import {getIdbStorage} from "../../lib/state/idbStorage";
import {createStore, UseStore} from "idb-keyval";
import {useShallow} from "zustand/react/shallow";

const bssDb = typeof indexedDB !== "undefined" && createStore("bssDb", "bss")
const bssIdbStorage = getIdbStorage(bssDb as UseStore)


export const useBssState = create<BssState>()(devtools(persist((set, get) => ({
    baselineData: {},
    comments: {},
    updatedAt: {},
    unsetUpdatedAt: (baselineId) => set(produce<BssState>((state) => {
        if (state.updatedAt[baselineId]) {
            state.updatedAt[baselineId] = null
        }
    })),
    overwriteBaselineState: (baselineId, data) => set(produce<BssState>((state) => {
        state.baselineData[baselineId] = data
        state.updatedAt[baselineId] = new Date().toISOString()
    })),
    overwriteCommentState: (baselineId, data) => set(produce<BssState>((state) => {
        state.comments[baselineId] = data
        state.updatedAt[baselineId] = new Date().toISOString()
    })),
    setComment: (data) => set(produce<BssState>((state) => {
        if (!state.comments[data.project_id]) {
            state.comments[data.project_id] = [data]
        } else if (state.comments[data.project_id].find(c => c.id === data.id)) {
            state.comments[data.project_id] = state.comments[data.project_id]
                .map(c => c.id === data.id ? data : c)
        } else {
            state.comments[data.project_id].push(data)
        }
        state.updatedAt[data.project_id] = new Date().toISOString()
    })),
    setBaselineData: ({
                          data,
                          isResolved,
                          type,
                          baselineId,
                          termId,
                          seasonId,
                          livestock_type,
                          term,
                          stitchedElements,
                          subCategory
                      }) => set(produce<BssState>(state => {
        const dataToSet = {
            ...(isResolved !== undefined ? {isResolved} : {}),
            ...(data ? {
                wealthGroupData: data,
                term: term,
                stitchedElements,
                subCategory
            } : {})
        } as BaselineData
        if (!state.baselineData[baselineId]) {
            state.baselineData[baselineId] = {
                crops: {},
                livestock: {
                    data: {},
                    herd: {}
                },
                data: {},
            } as BaselineState
        }
        if (type === Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts || type === Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput) {
            // livestock but not livestock_type depending
            if (!state.baselineData[baselineId].livestock.data[type]) {
                state.baselineData[baselineId].livestock.data[type] = {}
            }
            state.baselineData[baselineId].livestock.data[type][termId] = {
                ...state.baselineData[baselineId].livestock.data[type][termId],
                ...dataToSet
            }
        } else if (livestock_type) {
            // livestock type related
            if (!state.baselineData[baselineId].livestock.herd[livestock_type]) {
                state.baselineData[baselineId].livestock.herd[livestock_type] = {} as any
            }
            if (!state.baselineData[baselineId].livestock.herd[livestock_type][type]) {
                state.baselineData[baselineId].livestock.herd[livestock_type][type] = {}
            }
            state.baselineData[baselineId].livestock.herd[livestock_type][type][termId] = {
                ...state.baselineData[baselineId].livestock.herd[livestock_type][type][termId],
                ...dataToSet
            }

        } else if (seasonId) {
            // crop season related
            if (!state.baselineData[baselineId].crops[seasonId]) {
                state.baselineData[baselineId].crops[seasonId] = {} as any
            }
            if (!state.baselineData[baselineId].crops[seasonId][type]) {
                state.baselineData[baselineId].crops[seasonId][type] = {}
            }
            state.baselineData[baselineId].crops[seasonId][type][termId] = {
                ...state.baselineData[baselineId].crops[seasonId][type][termId],
                ...dataToSet
            }
        } else {
            // everything else
            if (!state.baselineData[baselineId].data[type]) {
                state.baselineData[baselineId].data[type] = {}
            }
            state.baselineData[baselineId].data[type][termId] = {
                ...state.baselineData[baselineId].data[type][termId],
                ...dataToSet
            }
        }
        state.updatedAt[baselineId] = new Date().toISOString()
    })),
    unsetBaselineData: ({baselineId, termId}) => set(produce<BssState>(state => {
        if (state.baselineData[baselineId]?.data[Project_Bss_Data_Type_Enum.CombinedData]?.[termId]) {
            delete state.baselineData[baselineId].data[Project_Bss_Data_Type_Enum.CombinedData][termId]
            state.updatedAt[baselineId] = new Date().toISOString()
        }
    })),
    resetState: () => set({
        baselineData: {},
        comments: {},
        updatedAt: {},
    }),
}), {
    name: 'bss-storage',
    version: 1.0,
    storage: createJSONStorage(() => bssIdbStorage)
})))

export const useResetBssState = () => useBssState(useShallow(state => state.resetState))
export const useSetBssBaselineData = () => useBssState(useShallow(state => state.setBaselineData))
export const useOverwriteBaselineState = () => useBssState(useShallow(state => state.overwriteBaselineState))
export const useOverwriteCommentsState = () => useBssState(useShallow(state => state.overwriteCommentState))
export const useSetBssComment = () => useBssState(useShallow(state => state.setComment))
export const useCurrentUpdatedAt = (baselineId: string) => {
    const state = useBssState(useShallow(state => state.updatedAt))
    return state[baselineId]
}
export const useUnsetUpdatedAt = () => useBssState(useShallow(state => state.unsetUpdatedAt))
export const useUnsetBssBaselineData = () => useBssState(useShallow(state => state.unsetBaselineData))
export const useBssComments = (filter?: Partial<BssNoteFragment> & {
    showAll?: boolean
    showResolved?: boolean
}) => {
    const {baselineId} = useBaselineOverviewContext()
    const state = useBssState(useShallow(state => state.comments))
    const isGlobal = !filter?.type
    return state[baselineId]?.filter(i => {
        if (i.is_deleted) {
            return false
        }
        if (!filter?.showResolved && i.is_done) {
            return false
        }
        if (filter?.showAll) {
            return !!i.type
        }
        if (isGlobal) {
            return !i.type
        }
        const bool: boolean[] = []
        if (filter?.type) {
            bool.push(i.type === filter.type)
        }
        if (filter?.season_id) {
            bool.push(i.season_id === filter.season_id)
        }
        if (filter?.livestock_type) {
            bool.push(i.livestock_type === filter.livestock_type)
        }
        if (filter?.term_id) {
            bool.push(i.term_id === filter.term_id)
        }
        return bool.every(b => b)
    }) || []
}
export const useGetBssBaselineData = ({type, termId, baselineId, livestock_type, seasonId}: BaselineFilterState) => {
    const state = useBssState(useShallow(state => state.baselineData))
    if (type === Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts || type === Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput) {
        return state[baselineId]?.livestock?.data?.[type]?.[termId || '']
    } else if (livestock_type) {
        return state[baselineId]?.livestock?.herd[livestock_type]?.[type]?.[termId || '']
    } else if (seasonId) {
        return state[baselineId]?.crops?.[seasonId]?.[type]?.[termId || '']
    }
    return state[baselineId]?.data?.[type]?.[termId || '']
}

type BssDefaults = {
    hideResolved: boolean
    startWithAverages: boolean
    setStartWithAverages: (bool: boolean) => void
    setHideResolved: (bool: boolean) => void
}

export const useBssDefaults = create<BssDefaults>()(devtools(persist((set) => ({
    hideResolved: false,
    startWithAverages: false,
    setHideResolved: (bool) => set(state => ({hideResolved: bool})),
    setStartWithAverages: (bool) => set(state => ({startWithAverages: bool}))
}), {
    name: 'bss-storage-defaults',
    version: 1.0,
})))

export const useHideResolved = () => useBssDefaults(useShallow(state => state.hideResolved))

