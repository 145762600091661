import {Alert, Box, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useUploadBssDataMutation} from "../../../lib/gql/__codegen__/react-query";
import {LoadingButton} from "@mui/lab";
import {prepareBssUploadData} from "./prepareBssUploadData";
import {CloudUpload} from "@mui/icons-material";
import {useUserId} from "@nhost/react";

export function BaselineSyncUpload({baselineId, onUpload}: { baselineId: string, onUpload?: () => void }) {
    const {t} = useTranslation()
    const {mutateAsync, isPending, isSuccess} = useUploadBssDataMutation()
    const userId = useUserId()
    return (
        <Box margin={2}>
            <Alert severity="info" icon={<CloudUpload/>}>
                <Stack spacing={1} width={'100%'}>
                    <Typography>
                        {t('bssUploadInfo')}
                    </Typography>
                    <LoadingButton loading={isPending}
                                   fullWidth
                                   startIcon={<CloudUpload/>}
                                   color={isSuccess ? 'success' : 'primary'}
                                   onClick={async () => {
                                       const conf = window.confirm(t('confirmUploadBss'))
                                       if (!conf) {
                                           return
                                       }

                                       const {bssDataItems, commentsItems, backup} = prepareBssUploadData({
                                           baselineId,
                                           userId
                                       })
                                       if (bssDataItems) {
                                           await mutateAsync({
                                               projectId: baselineId,
                                               objects: bssDataItems,
                                               noteObjects: commentsItems,
                                               backupObject: backup
                                           })
                                           onUpload?.()
                                       }
                                   }}>
                        {t('upload')}
                    </LoadingButton>
                </Stack>
            </Alert>
        </Box>
    )
}