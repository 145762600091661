import {Button, Stack, TableCell, TableRow} from "@mui/material";
import {GetTotalReturnType} from "./baselineSummarizeItems";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {Sort} from "@mui/icons-material";
import {useState} from "react";

export function BaselineSummarizeTableContentItemDetail({data, parentKey}: {
    data?: GetTotalReturnType,
    parentKey: 'kcal' | 'income' | 'expenditure'
}) {
    const [sortDesc, setSortDesc] = useState<boolean>(false)

    const {wealthGroups, interviews, interviewCountByWealthGroup} = useBaselineOverviewContext()
    if (!data) {
        return null
    }
    const ordered: {
        [wealthGroupId: string]: {
            sortedData: {
                locationId: string
                value: number
            }[]
        }
    } = wealthGroups.reduce((previousValue, currentValue) => {
        const dataContext = data[currentValue.id]
        return {
            ...previousValue,
            [currentValue.id]: {
                sortedData: dataContext?.data?.sort((a, b) => {
                    const aValue = a.value || 0;
                    const bValue = b.value || 0;
                    return aValue - bValue
                })
            }
        }
    }, {})
    return (
        <>
            {interviews.map((interview, index) => {
                return (
                    <TableRow key={interview.id}>
                        <TableCell align={'right'}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                {index === 0 ? (
                                    <Button size={'small'}
                                            color={sortDesc ? 'primary' : 'inherit'}
                                            startIcon={<Sort/>}
                                            variant={sortDesc ? 'outlined' : undefined}
                                            onClick={() => {
                                                setSortDesc(prevState => !prevState)
                                            }}>
                                    </Button>
                                ) : (
                                    <span/>
                                )}
                                <span>
                                    {sortDesc ? `# ${index + 1}` : interview.location?.name}
                                </span>
                            </Stack>
                        </TableCell>
                        {wealthGroups.map(wealthGroup => {
                            const context = data[wealthGroup.id]
                            const locationValue = sortDesc
                                ? (ordered[wealthGroup.id].sortedData?.[index]?.value ?? 0)
                                : context?.data?.find(i => i.locationId === interview.location_id)?.value ?? 0
                            if (!interviewCountByWealthGroup[wealthGroup.id].locationList[interview.location_id]) {
                                // if there was no interview for the wealth group then we don't show the data at all to not confuse with "0" data
                                return (
                                    <TableCell key={wealthGroup.id}>
                                        {'-'}
                                    </TableCell>
                                )
                            }

                            const numberFormat = getNumberFormat(locationValue) + (parentKey === 'kcal' ? ' %' : '');

                            return (
                                <TableCell key={wealthGroup.id}>
                                    {locationValue ? numberFormat : 0}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                )
            })}
        </>
    )
}