import {BaselineGroupedCropData, BaselineOverviewContextProps} from "../baselineOverviewTypes";
import groupBy from "lodash/groupBy";
import {processCropsProduction, processDataRow, processGreenRootCropsProduction} from "../baselineGroupHelper";
import {BssBaselineDetailQuery, Project_Bss_Data_Type_Enum} from "../../../lib";

type PrepareCropsDataProps = {
    data: BssBaselineDetailQuery['project_interview_crops_season']
    interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']
}

export function prepareCropsData({data, interviewCountByWealthGroup}: PrepareCropsDataProps) {
    const groupedData: BaselineGroupedCropData = {}
    const expenses: {
        [cropSeasonId: string]: {
            [termId: string]: {
                term: BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_data'][number]['term'],
                data: (BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_data'][number] & {
                    project_interview_base: BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_base']
                    type: Project_Bss_Data_Type_Enum
                })[]
            }
        }
    } = {}
    const productionItems: {
        [cropSeasonId: string]: {
            [termId: string]: {
                term: BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_crops'][number]['term'],
                data: (BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_crops'][number] & {
                    project_interview_base: BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_base']
                })[]
            }
        }
    } = {}

    data.forEach(item => {
        if (!groupedData[item.crop_season_id]) {
            groupedData[item.crop_season_id] = {
                season: item.season,
                [Project_Bss_Data_Type_Enum.CropsRoot]: {},
                [Project_Bss_Data_Type_Enum.CropsGreen]: {},
                [Project_Bss_Data_Type_Enum.CropsProduction]: {},
                [Project_Bss_Data_Type_Enum.CropsExpenditure]: {},
            }
        }
        item.project_interview_data?.forEach(dataItem => {
            if (!expenses[item.crop_season_id]) {
                expenses[item.crop_season_id] = {}
            }
            if (!expenses[item.crop_season_id][dataItem.term_id]) {
                expenses[item.crop_season_id][dataItem.term_id] = {
                    term: dataItem.term,
                    data: []
                }
            }
            expenses[item.crop_season_id][dataItem.term_id].data.push({
                ...dataItem,
                type: Project_Bss_Data_Type_Enum.CropsExpenditure,
                project_interview_base: item.project_interview_base
            })
        })

        // console.log({cropsProduction: item.project_interview_crops})
        item.project_interview_crops?.forEach(cropItem => {
            if (!productionItems[item.crop_season_id]) {
                productionItems[item.crop_season_id] = {}
            }
            if (!productionItems[item.crop_season_id][cropItem.term_id]) {
                productionItems[item.crop_season_id][cropItem.term_id] = {
                    term: cropItem.term,
                    data: []
                }
            }
            productionItems[item.crop_season_id][cropItem.term_id].data.push({
                ...cropItem,
                project_interview_base: item.project_interview_base
            })
        })

    })
    const groupedBySeasonId = groupBy(data, 'crop_season_id')
    Object.keys(groupedBySeasonId).forEach(seasonId => {
        const dataByWealthGroup = groupBy(groupedBySeasonId[seasonId], 'project_interview_base.project_interview.wealth_group_id')
        Object.keys(dataByWealthGroup).forEach(wealthGroupId => {
            const rootCropsData = dataByWealthGroup[wealthGroupId].map(item => ({
                ...item.root_crops,
                project_interview_base: item.project_interview_base
            })).filter(i => i?.weeks);
            const greenCropsData = dataByWealthGroup[wealthGroupId].map(item => ({
                ...item.green_crops,
                project_interview_base: item.project_interview_base
            })).filter(i => i?.weeks);
            groupedData[seasonId][Project_Bss_Data_Type_Enum.CropsRoot][wealthGroupId] = processGreenRootCropsProduction({
                data: rootCropsData,
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
            })
            groupedData[seasonId][Project_Bss_Data_Type_Enum.CropsGreen][wealthGroupId] = processGreenRootCropsProduction({
                data: greenCropsData,
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
            })
        })
    })
    Object.keys(expenses).forEach(cropSeasonId => {
        Object.keys(expenses[cropSeasonId]).forEach(termId => {
            const groupedByWealthGroup = groupBy(expenses[cropSeasonId][termId].data, 'project_interview_base.project_interview.wealth_group_id')
            Object.keys(groupedByWealthGroup).forEach(wealthGroupId => {
                if (!groupedData[cropSeasonId][Project_Bss_Data_Type_Enum.CropsExpenditure][termId]) {
                    groupedData[cropSeasonId][Project_Bss_Data_Type_Enum.CropsExpenditure][termId] = {
                        term: expenses[cropSeasonId][termId].term,
                        data: {}
                    }
                }
                groupedData[cropSeasonId][Project_Bss_Data_Type_Enum.CropsExpenditure][termId].data[wealthGroupId] = processDataRow({
                    // @ts-ignore
                    data: groupedByWealthGroup[wealthGroupId],
                    locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount,
                    type: Project_Bss_Data_Type_Enum.CropsExpenditure
                })
            })
        })
    })
    Object.keys(productionItems).forEach(cropSeasonId => {
        Object.keys(productionItems[cropSeasonId]).forEach(termId => {
            const groupedByWealthGroup = groupBy(productionItems[cropSeasonId][termId].data, 'project_interview_base.project_interview.wealth_group_id')
            Object.keys(groupedByWealthGroup).forEach(wealthGroupId => {
                if (!groupedData[cropSeasonId][Project_Bss_Data_Type_Enum.CropsProduction][termId]) {
                    groupedData[cropSeasonId][Project_Bss_Data_Type_Enum.CropsProduction][termId] = {
                        term: productionItems[cropSeasonId][termId].term,
                        data: {}
                    }
                }
                groupedData[cropSeasonId][Project_Bss_Data_Type_Enum.CropsProduction][termId].data[wealthGroupId] = processCropsProduction({
                    data: groupedByWealthGroup[wealthGroupId],
                    locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
                })
            })
        })
    })
    return groupedData
}