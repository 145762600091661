import {PopperContainer} from "../../../common/popper/PopperContainer";
import {CloudSync, SyncProblem} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {BaselineSyncUpload} from "./BaselineSyncUpload";
import {BaselineSyncDownload} from "./BaselineSyncDownload";
import {BaselineStateReset} from "./BaselineStateReset";
import {BaselineLastUpdated} from "./BaselineLastUpdated";
import {useCurrentUpdatedAt, useUnsetUpdatedAt} from "../../state/bssState";

export function BaselineSyncDialog({baselineId}: { baselineId: string }) {
    const {t} = useTranslation()
    const ref = useRef<PopupState>(null)
    const unsetUpdatedAt = useUnsetUpdatedAt()
    const updatedAt = useCurrentUpdatedAt(baselineId)
    const onUpdated = () => {
        ref.current?.close()
        unsetUpdatedAt(baselineId)
    }
    return (
        <PopperContainer
            ref={ref}
            dialogTitle={'Sync'}
            dialogProps={{}}
            trigger={{
                color: 'inherit',
                startIcon: updatedAt ? <SyncProblem/> : <CloudSync/>,
                children: t('sync')
            }}>
            <BaselineLastUpdated baselineId={baselineId}/>
            <BaselineSyncUpload baselineId={baselineId} onUpload={() => onUpdated()}/>
            <BaselineSyncDownload baselineId={baselineId} onDownload={() => onUpdated()}/>
            <BaselineStateReset baselineId={baselineId} afterReset={() => onUpdated()}/>
        </PopperContainer>
    )
}