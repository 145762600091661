import {Box, Button, IconButton, Stack, TableCell, TableRow} from "@mui/material";
import {Loupe, UnfoldLess, UnfoldMore} from "@mui/icons-material";
import {BaselineTableRowDetail} from "./BaselineTableRowDetail";
import {GroupColumnsOnType} from "../baselineDataFields";
import {BaselineTableRowProps} from "../baselineOverviewTypes";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useGetBssBaselineData, useHideResolved} from "../../state/bssState";
import {getDataField} from "../baselineGroupHelper";
import {BssNotesDrawer} from "../notes/BssNotesDrawer";
import {FIRST_CELL_WIDTH} from "./tableRowConfig";
import {BaselineStitchingCheckbox} from "./BaselineStitchingCheckbox";
import {useSetBaselineResolverState} from "../resolveView/baselineResolverState";

export function BaselineTableRow({
                                     isEven,
                                     type,
                                     term,
                                     data,
                                     livestock_type,
                                     TopRow,
                                     seasonId,
                                     stitchedElements
                                 }: BaselineTableRowProps) {
    const {t} = useTranslation()
    const hideResolved = useHideResolved()
    const {baselineId} = useBaselineOverviewContext()
    const {wealthGroups} = useBaselineOverviewContext()
    const [openCell, setOpenCell] = useState<{ [k: string]: boolean }>({})
    const [showMoreRows, setShowMoreRows] = useState(false)
    const allExpanded = Object.values(openCell).filter(v => v)?.length === wealthGroups.length
    const anyExpanded = Object.values(openCell).filter(v => v)?.length > 0
    const backgroundColor = isEven ? 'rgba(0,0,0,0.025)' : undefined;
    const baselineData = useGetBssBaselineData({
        baselineId, type, termId: term?.id, livestock_type, seasonId
    })
    const setDialog = useSetBaselineResolverState()
    const isResolved = baselineData?.isResolved
    if (hideResolved && isResolved) {
        return null
    }
    return (
        <>
            <TableRow sx={{backgroundColor}}>
                <TableCell sx={{
                    fontWeight: 'bold'
                }}>
                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'} sx={{
                        width: FIRST_CELL_WIDTH
                    }}>
                        <Button
                            size={'small'}
                            startIcon={<Loupe fontSize={'small'}/>}
                            color={isResolved ? 'success' : baselineData ? 'primary' : 'inherit'}
                            onClick={() => {
                                setDialog({
                                    term,
                                    seasonId,
                                    type,
                                    livestock_type,
                                    groupedByWealthGroupData: data,
                                    stitchedElements
                                })
                            }}>
                            {term?.en} {term?.kcal ? `(${term?.kcal} kcal)` : ''}
                        </Button>

                        <Stack direction={'row'} spacing={1}>
                            {!stitchedElements?.length && (
                                <BaselineStitchingCheckbox element={{
                                    type, seasonId, livestock_type, term, groupedByWealthGroupData: data
                                }}/>
                            )}
                            <BssNotesDrawer
                                insideTableRow
                                options={{
                                    term_id: term?.id,
                                    type,
                                    livestock_type,
                                    season_id: seasonId
                                }}/>
                            <IconButton size={'small'}
                                        onClick={() => {
                                            setShowMoreRows(prevState => !prevState)
                                        }}>
                                {showMoreRows ? <UnfoldLess/> : <UnfoldMore/>}
                            </IconButton>
                            <IconButton size={'small'}
                                        onClick={() => {
                                            setOpenCell(wealthGroups.reduce((previousValue, currentValue) => {
                                                return {
                                                    ...previousValue,
                                                    [currentValue.id]: !allExpanded
                                                }
                                            }, {}))
                                        }}>
                                {allExpanded ? <UnfoldLess transform={'rotate(90)'}/> :
                                    <UnfoldMore transform={'rotate(90)'}/>}
                            </IconButton>
                        </Stack>
                    </Stack>
                </TableCell>
                {wealthGroups.map(wealthGroup => (
                    <TableCell key={wealthGroup.id}>
                        <Button size={'small'}
                                color={'inherit'}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'light',
                                    fontSize: '0.75rem',
                                    color: 'rgba(0,0,0,0.4)'
                                }}
                                startIcon={openCell[wealthGroup.id] ? <UnfoldLess transform={'rotate(90)'}/> :
                                    <UnfoldMore transform={'rotate(90)'}/>}
                                onClick={() => setOpenCell(prevState => ({
                                    ...prevState,
                                    [wealthGroup.id]: !prevState[wealthGroup.id]
                                }))}>
                            {wealthGroup.short}&nbsp;({data?.[wealthGroup.id]?.data?.length ?? 0})
                        </Button>
                    </TableCell>
                ))}
            </TableRow>
            {TopRow && (
                <>{TopRow}</>
            )}
            <TableRow sx={{backgroundColor}}>
                {wealthGroups.map(wealthGroup => (
                    <TableCell key={wealthGroup.id}>
                        {anyExpanded && (
                            <BaselineTableRowDetail
                                isExpanded={openCell[wealthGroup.id]}
                                type={type}
                                showMoreRows={showMoreRows}
                                wealthGroupData={data[wealthGroup.id]}
                            />
                        )}
                    </TableCell>
                ))}
            </TableRow>
            {!anyExpanded && GroupColumnsOnType[type]?.[showMoreRows ? 'large' : 'short'].map(column => (
                <TableRow key={column} sx={{backgroundColor}}>
                    <TableCell
                        sx={{textAlign: 'right', paddingRight: 2, whiteSpace: 'nowrap', width: FIRST_CELL_WIDTH}}>
                        <Box width={FIRST_CELL_WIDTH}>
                            {t('rowType.' + column)}
                        </Box>
                    </TableCell>
                    {wealthGroups.map(wealthGroup => {
                        const valueOfColumn = baselineData?.wealthGroupData?.[wealthGroup.id]?.[column] ?? data[wealthGroup.id]?.[column]?.avg1;
                        return (
                            <TableCell key={wealthGroup.id}>
                                {getDataField(valueOfColumn, column)}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
        </>
    )
}