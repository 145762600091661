import {Livestock_Type_Enum} from '../../lib'
import {ReactNode} from "react";


export type LivestockFixtures = {
    [k in Livestock_Type_Enum]: {
        milkKcalFull: number
        milkKcalSkim: number
        meatKcal: number
        eggKcal?: number
    }
};
export const livestockFixtures: LivestockFixtures = {
    [Livestock_Type_Enum.Camel]: {
        milkKcalFull: 710,
        meatKcal: 2015,
        milkKcalSkim: 340
    }, [Livestock_Type_Enum.Chicken]: {
        meatKcal: 1390,
        eggKcal: 1580,
        milkKcalSkim: 0, milkKcalFull: 0
    }, [Livestock_Type_Enum.Cattle]: {
        milkKcalFull: 640,
        meatKcal: 2350,
        milkKcalSkim: 340
    }, [Livestock_Type_Enum.Sheep]: {
        meatKcal: 2490,
        milkKcalSkim: 340,
        milkKcalFull: 1080
    }, [Livestock_Type_Enum.Goat]: {
        meatKcal: 1450,
        milkKcalSkim: 340,
        milkKcalFull: 710
    }, [Livestock_Type_Enum.OtherPoultry]: {
        meatKcal: 1390,
        milkKcalSkim: 0,
        milkKcalFull: 0,
        eggKcal: 1580
    }, [Livestock_Type_Enum.Pig]: {
        meatKcal: 3980,
        milkKcalSkim: 0,
        milkKcalFull: 0
    }, [Livestock_Type_Enum.Oxen]: {
        meatKcal: 2350,
        milkKcalSkim: 0,
        milkKcalFull: 0
    }, [Livestock_Type_Enum.Donkey]: {
        meatKcal: 0,
        milkKcalSkim: 0,
        milkKcalFull: 0
    }, [Livestock_Type_Enum.Horse]: {
        milkKcalFull: 440,
        milkKcalSkim: 340,
        meatKcal: 1330
    }, [Livestock_Type_Enum.Yak]: {
        milkKcalFull: 1020,
        milkKcalSkim: 340,
        meatKcal: 0 //todo
    }, [Livestock_Type_Enum.Buffalo]: {
        //todo
        milkKcalFull: 640,
        meatKcal: 2350,
        milkKcalSkim: 340
    }
}

export type LivestockOverviewRowType = {
    type: Livestock_Type_Enum
    totalCalories: number
    totalIncome: number
    totalExpenses: number
    start: ReactNode
    events: ReactNode
    end: ReactNode
}