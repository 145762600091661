import {createStore, UseStore} from "idb-keyval";
import {create} from 'zustand';
import {createJSONStorage, persist} from "zustand/middleware";
import {AllVariablesQuery} from "../gql/__codegen__/react-query";
import {getIdbStorage} from "./idbStorage";
import {TermsAutocompleteType} from "./tssTypes";
import {useShallow} from "zustand/react/shallow";

const tssDb = typeof indexedDB !== "undefined" && createStore("tssAllVarsDb", "allVars")

const tssIdbStorage = getIdbStorage(tssDb as UseStore)

export type AllVariablesState = {
    terms: AllVariablesQuery['term']
    measureUnits: AllVariablesQuery['measure_unit']
    cropSeasons: AllVariablesQuery['crop_season']
    initState: (data: {
        terms: AllVariablesQuery['term'],
        measureUnits: AllVariablesQuery['measure_unit'],
        cropSeasons: AllVariablesQuery['crop_season']
    }) => void
    getTerms: (categoryIds?: string[], locale?: string) => TermsAutocompleteType[]
    findTerm: (id: string) => TermsAutocompleteType | undefined
    // loadAllVariables: (locale: string) => Promise<void>
}


export const useAllVariablesState = create<AllVariablesState>()(persist((set, getState) => ({
    terms: [],
    measureUnits: [],
    cropSeasons: [],
    initState: ({terms, measureUnits, cropSeasons}) => set({terms, measureUnits, cropSeasons}),
    getTerms: (categoryIds, locale) => {
        const allTerms = getState().terms
        const filteredTerms = categoryIds?.length
            ? allTerms.filter(term => term.term_category_mns.some(cat => categoryIds.includes(cat.category_id)))
            : allTerms
        return filteredTerms.map(term => ({
            id: term.id as string,
            // @ts-ignore
            label: (term[locale] || term.en) as string,//enable multi lang
            kcal: term.kcal || 0,
            categoryIds: term.term_category_mns.map(i => i.category_id) as string[]
        }));
    },
    findTerm: (id) => {
        const allTerms = getState().terms
        const found = allTerms.find(i => i.id === id)
        if (found) {
            return {
                id: found.id,
                label: found.en, // todo, //enable multi lang
                kcal: found.kcal || 0,
                categoryIds: found.term_category_mns.map(i => i.category_id) as string[]
            }
        }
        return
    }
}), {
    name: 'all-tss-variables',
    version: 1,
    storage: createJSONStorage(() => tssIdbStorage)
}))

export const findTermOnId = (id: string) => useAllVariablesState.getState().findTerm(id)


// export const findMeasureUnitOnId = (id: string) => {
//     const val = useAllVariablesState.getState().measureUnits
//     return val.find(i => i.id === id)
// }
export const useGetTermsSimple = () => useAllVariablesState(useShallow(state => state.terms))
export const useGetTerms = () => useAllVariablesState(useShallow(state => state.getTerms))
export const useAllMeasureUnits = () => useAllVariablesState(useShallow(state => state.measureUnits))
export const useAllCropSeasons = () => useAllVariablesState(useShallow(state => state.cropSeasons))