import {TssLayout} from "../../theme/layouts/tss";
import {BssProjectList} from "./BssProjectList";
import {Container} from "@mui/material";

export function ProjectsScreen() {
    return (
        <TssLayout title={'Home'}
                   appBarProps={{
                       sx: {
                           bgcolor: 'success.main'
                       }
                   }}>
            <Container maxWidth={false}>
                <BssProjectList/>
            </Container>
        </TssLayout>
    )
}

