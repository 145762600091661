import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useTranslation} from "react-i18next";
import {Stack, Table, TableBody, TableContainer, Typography} from "@mui/material";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {Project_Bss_Data_Type_Enum} from "../../../lib";
import {staticTermId} from "../staticTermId";
import {headlineTranslationMapping} from "../translationHelper";

export function CropsTableOverviewRootGreen({seasonId}: { seasonId: string }) {
    const {cropsBySeason} = useBaselineOverviewContext();
    const {t} = useTranslation();
    const rootCrops = cropsBySeason[seasonId]?.[Project_Bss_Data_Type_Enum.CropsRoot]
    const greenCrops = cropsBySeason[seasonId]?.[Project_Bss_Data_Type_Enum.CropsGreen]
    return (
        <Stack spacing={2}>
            <Typography variant={'subtitle2'}>

                {t('rootCrops')}
            </Typography>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        <BaselineTableRow type={Project_Bss_Data_Type_Enum.CropsRoot}
                                          term={{
                                              id: staticTermId[Project_Bss_Data_Type_Enum.CropsRoot],
                                              en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.CropsRoot]),
                                          }}
                                          seasonId={seasonId}
                                          isEven={false}
                                          data={rootCrops}/>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant={'subtitle2'}>
                {t('greenCrops')}
            </Typography>
            <TableContainer>
                <Table size={'small'} padding={'checkbox'}>
                    <TableBody>
                        <BaselineTableRow type={Project_Bss_Data_Type_Enum.CropsGreen}
                                          term={{
                                              id: staticTermId[Project_Bss_Data_Type_Enum.CropsGreen],
                                              en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.CropsGreen]),
                                          }}
                                          seasonId={seasonId}
                                          isEven={false}
                                          data={greenCrops}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>

    )
}