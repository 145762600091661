import {Project_Bss_Data_Type_Enum} from "../../lib";

// @ts-ignore
export const staticTermId: {
    [type in Project_Bss_Data_Type_Enum]: string
} = {
    [Project_Bss_Data_Type_Enum.CropsRoot]: 'rootCrops',
    [Project_Bss_Data_Type_Enum.CropsGreen]: 'greenCrops',
    [Project_Bss_Data_Type_Enum.HouseholdProfileBase]: 'householdProfile',
    [Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding]: 'schoolFeeding',
    [Project_Bss_Data_Type_Enum.Migration]: 'migration',
    [Project_Bss_Data_Type_Enum.LivestockHerd]: 'livestockHerd',
    [Project_Bss_Data_Type_Enum.MilkProductionPeak]: 'milkProductionPeak',
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeak]: 'milkProductionOffPeak',
    [Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]: 'milkProductionPeakSecondary',
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]: 'milkProductionOffPeakSecondary',
    [Project_Bss_Data_Type_Enum.LivestockSlaughter]: 'livestockSlaughter',
    [Project_Bss_Data_Type_Enum.RemittancesCreditIncome]: 'remittancesCreditIncome',
    [Project_Bss_Data_Type_Enum.RemittancesCreditExpenses]: 'remittancesCreditExpenses',
}