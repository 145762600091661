import {Checkbox} from "@mui/material";
import {useBaselineStitchingElements, useBaselineStitchingToggle} from "./baselineStitchingState";
import {BssResolveElement} from "../baselineOverviewTypes";

type BaselineStitchingCheckboxProps = {
    element: BssResolveElement
}

export function BaselineStitchingCheckbox({element}: BaselineStitchingCheckboxProps) {
    const elements = useBaselineStitchingElements()
    const toggle = useBaselineStitchingToggle()
    const isChecked = !!elements?.find(e => e === element)
    return (
        <Checkbox size={'small'} checked={isChecked} onChange={() => {
            toggle(element)
        }}/>
    )
}