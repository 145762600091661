import {Project_Bss_Data_Type_Enum} from "../../lib";

export const sortedHouseholdTypes = [
    Project_Bss_Data_Type_Enum.HouseholdProfileBase,
    Project_Bss_Data_Type_Enum.HouseholdCerealReserves,
    Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets,
]

export const sortedDataTypes = [
    Project_Bss_Data_Type_Enum.ExpenditureFood,
    Project_Bss_Data_Type_Enum.ExpenditureNonFood,
    Project_Bss_Data_Type_Enum.Employment,
    Project_Bss_Data_Type_Enum.SelfEmployment,
    Project_Bss_Data_Type_Enum.LabourExchange,
    Project_Bss_Data_Type_Enum.FishingWildFood,
    Project_Bss_Data_Type_Enum.FishingWildFoodExpenses,
    Project_Bss_Data_Type_Enum.RemittancesCreditIncome,
    Project_Bss_Data_Type_Enum.RemittancesCreditExpenses,
    Project_Bss_Data_Type_Enum.FoodAid,
    Project_Bss_Data_Type_Enum.Migration,
]
export const sortedCsvDataTypes = [...sortedDataTypes, Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding]