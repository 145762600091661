import {
    BssBaselineDetailQuery,
    Livestock_Type_Enum,
    Project_Bss_Data_Type_Enum,
    Project_Interview_Data_Type_Enum,
    TermFragment
} from "../../../lib";
import groupBy from "lodash/groupBy";
import {BaselineDataWithComputed, BaselineOverviewContextProps} from "../baselineOverviewTypes";
import {processDataRow, processLivestockBase, processMilkProduction} from "../baselineGroupHelper";
import {LivestockIsAnimalTermId} from "../../../tss/livestock/livestockHelper";


type LivestockExpenditureData =
    BssBaselineDetailQuery['project_interview_livestock'][number]['project_interview_data'][number]
    & {
    project_interview_base: BssBaselineDetailQuery['project_interview_livestock'][number]['project_interview_base']
};

type PrepareLivestockDataProps = {
    data: BssBaselineDetailQuery['project_interview_livestock']
    interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']
}

export function prepareLivestockData({data, interviewCountByWealthGroup}: PrepareLivestockDataProps) {
    // @ts-ignore
    const livestockData: BaselineOverviewContextProps['livestock'] = {
        expenditure: {},
        sales: {},
        grouped: {} as BaselineOverviewContextProps['livestock']['grouped'],
    }
    const groupByLivestock = groupBy(data, 'type')
    const livestockExpenditure: LivestockExpenditureData[] = []
    const livestockSales: LivestockExpenditureData[] = []
    Object.keys(groupByLivestock).forEach((livestockType) => {
        const groupByWealthGroup = groupBy(groupByLivestock[livestockType], 'project_interview_base.project_interview.wealth_group_id')
        const currentLivestockType = livestockType as Livestock_Type_Enum;
        livestockData.grouped[currentLivestockType] = {
            data: {},
            base: {
                [Project_Bss_Data_Type_Enum.LivestockHerd]: {},
                [Project_Bss_Data_Type_Enum.MilkProductionPeak]: {},
                [Project_Bss_Data_Type_Enum.MilkProductionOffPeak]: {},
                [Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]: {},
                [Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]: {},
                [Project_Bss_Data_Type_Enum.LivestockSlaughter]: {}
            }
        }
        Object.keys(groupByWealthGroup).forEach(wealthGroupId => {
            const groupByWealthGroupElement = groupByWealthGroup[wealthGroupId];

            // start milk production
            livestockData.grouped[currentLivestockType].base[Project_Bss_Data_Type_Enum.MilkProductionPeak][wealthGroupId] = processMilkProduction({
                data: groupByWealthGroupElement.filter(i => i.peak_milk).map(i => ({
                    ...i.peak_milk,
                    project_interview_base: i.project_interview_base,
                    // project_interview_base: projectInterviewBase
                    // ...(pick(i, ['total_off_peak_milk_calories', 'total_peak_milk_calories']))
                })),
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
            })
            livestockData.grouped[currentLivestockType].base[Project_Bss_Data_Type_Enum.MilkProductionOffPeak][wealthGroupId] = processMilkProduction({
                data: groupByWealthGroupElement.filter(i => i.off_peak_milk).map(i => ({
                    ...i.off_peak_milk,
                    project_interview_base: i.project_interview_base,
                    // ...(pick(i, ['total_off_peak_milk_calories', 'total_peak_milk_calories']))
                })),
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
            })
            livestockData.grouped[currentLivestockType].base[Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary][wealthGroupId] = processMilkProduction({
                data: groupByWealthGroupElement.filter(i => i.peak_milk_secondary).map(i => ({
                    ...i.peak_milk_secondary,
                    project_interview_base: i.project_interview_base,
                    // ...(pick(i, ['total_off_peak_milk_calories', 'total_peak_milk_calories']))
                })),
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
            })
            livestockData.grouped[currentLivestockType].base[Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary][wealthGroupId] = processMilkProduction({
                data: groupByWealthGroupElement.filter(i => i.off_peak_milk_secondary).map(i => ({
                    ...i.off_peak_milk_secondary,
                    project_interview_base: i.project_interview_base,
                    // ...(pick(i, ['total_off_peak_milk_calories', 'total_peak_milk_calories']))
                })),
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
            })
            livestockData.grouped[currentLivestockType].base[Project_Bss_Data_Type_Enum.LivestockHerd][wealthGroupId] = processLivestockBase({
                data: groupByWealthGroupElement,
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount,
                type: Project_Bss_Data_Type_Enum.LivestockHerd
            })
            livestockData.grouped[currentLivestockType].base[Project_Bss_Data_Type_Enum.LivestockSlaughter][wealthGroupId] = processLivestockBase({
                data: groupByWealthGroupElement,
                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount,
                type: Project_Bss_Data_Type_Enum.LivestockSlaughter
            })

            // start expenditure data
            const livestockInputData = groupByWealthGroupElement.reduce((acc, curr) => {
                if (!curr.project_interview_data?.length) {
                    return acc
                }
                return [
                    ...acc,
                    ...curr.project_interview_data.filter(i => {
                        if (!i.term) {
                            return false
                        }
                        if (i.type === Project_Interview_Data_Type_Enum.LivestockExpenditure) {
                            const isAnimal = LivestockIsAnimalTermId.includes(i.term?.id);
                            if (!isAnimal) {
                                livestockExpenditure.push({
                                    ...i,
                                    project_interview_base: curr.project_interview_base
                                })
                            }
                            return isAnimal
                        }
                        if (i.type === Project_Interview_Data_Type_Enum.LivestockSale) {
                            const isAnimal = LivestockIsAnimalTermId.includes(i.term?.id);
                            if (!isAnimal) {
                                livestockSales.push({
                                    ...i,
                                    project_interview_base: curr.project_interview_base
                                })
                            }
                            return isAnimal
                        }
                        return true
                    }).map(item => ({
                        ...item,
                        project_interview_base: curr.project_interview_base
                    })),
                ]
            }, [] as LivestockExpenditureData[])
            const expenditureByType = groupBy(livestockInputData, 'type')
            Object.keys(expenditureByType).forEach(type => {
                const expenditureByTermId = groupBy(expenditureByType[type], 'term_id')
                Object.keys(expenditureByTermId).forEach(termId => {
                    const currentData = expenditureByTermId[termId];
                    if (!currentData?.length) {
                        return
                    }
                    livestockData.grouped[currentLivestockType].data[type] = livestockData.grouped[currentLivestockType].data[type] || {}
                    livestockData.grouped[currentLivestockType].data[type][termId] = {
                        term: currentData[0].term as TermFragment,
                        data: {
                            [wealthGroupId]: processDataRow({
                                data: currentData as BaselineDataWithComputed[],
                                locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount,
                                type: Project_Bss_Data_Type_Enum.LivestockExpenditure
                            })
                        }
                    }

                })
            })
        })
    })

    const livestockExpenditureByTermId = groupBy(livestockExpenditure, 'term_id')
    const processExpenditureSales = (termId: string, grouped: any, key: 'expenditure' | 'sales') => {
        const groupedByWealthGroup = groupBy(grouped[termId], 'project_interview_base.project_interview.wealth_group_id')
        Object.keys(groupedByWealthGroup).forEach(wealthGroupId => {
            const currentData = groupedByWealthGroup[wealthGroupId];
            livestockData[key][termId] = {
                term: currentData[0].term as TermFragment,
                data: {
                    [wealthGroupId]: processDataRow({
                        data: currentData as BaselineDataWithComputed[],
                        locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount,
                        type: key === 'expenditure' ? Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput : Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts
                    })
                }
            }
        })

    };
    Object.keys(livestockExpenditureByTermId).forEach(termId => processExpenditureSales(termId, livestockExpenditureByTermId, 'expenditure'))
    const livestockSalesByTerm = groupBy(livestockSales, 'term_id')
    Object.keys(livestockSalesByTerm).forEach(termId => processExpenditureSales(termId, livestockSalesByTerm, 'sales'))

    return livestockData
}