import {IconButton, Stack} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import {useBaselineCsvRawDataHelper} from "./useBaselineCsvRawDataHelper";
import {useBaselineWorkbook} from "./BaselineCsvWorkbookProvider";
import {BaselineCsvCopyProps} from "./copyHelper";


export function BaselineCsvCopy({
                                    csvResolvedData,
                                    selectedCsvType,
                                    type,
                                    selectedFields,
                                    livestock_type,
                                    seasonId,
                                    term_id,
                                    sectionTitle,
                                    titleInline
                                }: BaselineCsvCopyProps) {
    const {addCsvToSheet} = useBaselineWorkbook()

    const getRawData = useBaselineCsvRawDataHelper({
        type,
        selectedFields,
        selectedCsvType,
        term_id,
        livestock_type,
        seasonId
    })

    const copyFunc = () => {
        // const hasCopiedData = selectedResolver?.length;
        const csvData = getRawData()
        if (titleInline) {
            csvData[2][0] = sectionTitle + " (" + csvData[2][0] + ")"
        }
        // merge csvResolvedData with rawDataArray
        csvResolvedData.forEach((row, i) => {
            // add after the first two rows the data of resolved minus the first column (label)
            csvData[i + 2] = [...csvData[i + 2], ...row.slice(1)]
        })
        if (!titleInline) {
            csvData.splice(2, 0, [sectionTitle, ...Array(csvData[0].length - 1).fill('')]);
        }
        // todo: how to handle household profile productive assets?
        const shouldTransformIntoColumn = selectedCsvType === 'Type CSV Column'
        const mappedArray = shouldTransformIntoColumn
            ? csvData
                .reduce((columns, row) => {
                    row.forEach((item, index) => {
                        if (columns[index] === undefined) {
                            columns[index] = [];
                        }
                        columns[index].push(item === null || item === '' ? '' : item + "");
                    });
                    return columns;
                }, [] as string[][])
            : csvData
                .map(row => {
                    const isEmptyRow = row.every(val => val === null || val === '');
                    return isEmptyRow ? [] : row.map(val => val || '');
                })

        // todo need to split this into data and header to persist the header all times
        addCsvToSheet({
            // csvData: mappedString,
            arrayData: normalizeArrayLengths(mappedArray),
            seasonId,
            livestock_type,
            term_id,
            type,
            selectedFields,
            sectionTitle
        })
    }
    return (
        <Stack direction={'row'} spacing={1}>
            {/*<IconButton onClick={() => copyFunc({csvData: csvResolvedData})}>*/}
            {/*    <ContentCopy/>*/}
            {/*</IconButton>*/}
            <IconButton onClick={() => copyFunc()}>
                <ContentCopy/>
            </IconButton>
        </Stack>
    )
}

function normalizeArrayLengths(arrayOfArrays: (string | number)[][]) {
    // Step 1: Determine the maximum length of the arrays
    const maxLength = arrayOfArrays.reduce((max, current) => Math.max(max, current.length), 0);

    // Step 2: Normalize the length of each array
    return arrayOfArrays.map(array => {
        // Create a new array with `null` values up to the max length
        return [...array, ...new Array(maxLength - array.length).fill(null)];
    });
}