import ReloadPrompt from "./ReloadPrompt.tsx";
import {BssReactRouter} from "@feg/ui/src/vite/bss/bssReactRouter.tsx";

function App() {
    // replaced dyanmicaly
    // @ts-ignore
    const date = '__DATE__'

    return (
        <>
            <BssReactRouter/>
            <ReloadPrompt/>
        </>
    )
}

export default App