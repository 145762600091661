import {AvailableTotalReturnKeys, BaselineOverviewContextProps} from "../baselineOverviewTypes";
import {BaselineState} from "../../state/bssTypes/bssTypes";

export type GetTotalReturnType = {
    [wealthGroupId: string]: {
        value: number
        data: {
            value: number
            locationId: string
        }[]
    }
};


export type GetTotalResolvedType = {
    [wealthGroupId: string]: {
        [key in BaselineSummarizeKeys]: number
    }
}

export type GetTotalResolvedLivestockType = {
    totalsLivestock: GetTotalResolvedType
    totalsLivestockProduct: GetTotalResolvedType
}

const getFieldOfTotals = (totalsByWealthGroup: BaselineOverviewContextProps['totalsByWealthGroup'], key: AvailableTotalReturnKeys): GetTotalReturnType => {
    return Object.keys(totalsByWealthGroup).reduce((acc, wealthGroupId) => {
        return ({
            ...acc,
            [wealthGroupId]: {
                value: totalsByWealthGroup[wealthGroupId][key]?.avg1,
                data: totalsByWealthGroup[wealthGroupId].data.map(row => ({
                    value: row[key],
                    locationId: row.location_id
                }))
            }
        })
    }, {})
}


export type BaselineSummarizeKeys = 'kcal' | 'income' | 'expenditure';


export type BaselineSummarizeKcalKeys =
    'summarize'
    | 'crops'
    | 'livestockProducts'
    | 'purchases'
    | 'foodAid'
    | 'fishingWildFood'
    | 'migrationMeals'
export type BaselineSummarizeIncomeKeys =
    'summarize'
    | 'crops'
    | 'livestockProducts'
    | 'livestockSales'
    | 'labourJobsMigration'
    | 'selfEmployment'
    | 'remittanceCreditAssistance'
    | 'fishingWildFoodSales'
export type BaselineSummarizeExpenditureKeys =
    'summarize'
    | 'stapleFoods'
    | 'nonStapleFoods'
    | 'householdItems'
    | 'inputCroppingCosts'
    | 'inputLivestockCosts'
    | 'education'
    | 'health'
    | 'otherProductionCosts'
    | 'other'
    | 'loanRepayment'

type BaselineSummarizeItem = {
    translationKey: string
    getTotal: (data: BaselineOverviewContextProps) => GetTotalReturnType
    getResolved?: (data: BaselineState) => GetTotalResolvedType
};
type BaselineSummarizeTotals = {
    kcal: {
        [key in BaselineSummarizeKcalKeys]: BaselineSummarizeItem
    }
    income: {
        [key in BaselineSummarizeIncomeKeys]: BaselineSummarizeItem
    }
    expenditure: {
        [key in BaselineSummarizeExpenditureKeys]: BaselineSummarizeItem
    }
};


export const baselineSummarizeItems: BaselineSummarizeTotals = {
    kcal: {
        summarize: {
            translationKey: 'foodSummaryTotalKcal',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'calories_total')
            },
            // getResolved: bssState => {
            //     const {crops, data, livestock} = bssState
            //
            //
            // }
        },
        crops: {
            translationKey: 'crops.name',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                const cropsTotals = getFieldOfTotals(totalsByWealthGroup, 'calories_crops');
                return cropsTotals
            },
            // getResolved: bssState => {
            //     const {crops} = bssState
            //     return getCropsResolvedTotals(crops)
            // }
        },
        livestockProducts: {
            translationKey: 'livestockProducts',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'calories_livestock')
            }
        },
        purchases: {
            translationKey: 'purchases',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'calories_purchases')
            }
        },
        foodAid: {
            translationKey: 'foodAid',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'calories_food_aid')
            }
        },
        fishingWildFood: {
            translationKey: 'fishingWildFood',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'calories_fishing_wild_food')
            }
        },
        migrationMeals: {
            translationKey: 'migrationAndLabour',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'calories_labour_jobs_migration')
            }
        }
    },

    income: {
        summarize: {
            translationKey: 'incomeSummaryTotalKcal',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'income_total')
            }
        },
        crops: {
            translationKey: 'crops.name',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_income_crops')
            },
            // getResolved: bssState => {
            //     const {crops} = bssState
            //     return getCropsResolvedTotals(crops)
            // }
        },
        livestockProducts: {
            translationKey: 'livestockProducts',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                // todo: need to use livestock products
                return getFieldOfTotals(totalsByWealthGroup, 'income_livestock_product')
            }
        },
        livestockSales: {
            translationKey: 'livestockSale',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                // todo: need to use livestock sales
                return getFieldOfTotals(totalsByWealthGroup, 'income_livestock_sale')
            }
        },
        labourJobsMigration: {
            translationKey: 'labourJobsMigration',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'income_labour_jobs_migration')
            }
        },
        selfEmployment: {
            translationKey: 'selfEmployment',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_income_self_employment')
            }
        },
        remittanceCreditAssistance: {
            translationKey: 'remittanceCredit',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_income_remittance_credit')
            }
        },
        fishingWildFoodSales: {
            translationKey: 'fishingWildFood',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_income_fishing_wild_food')
            }
        }
    },
    expenditure: {
        summarize: {
            translationKey: 'expenditureSummaryTotalKcal',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'expenditure_total')
            }
        },
        stapleFoods: {
            translationKey: 'purchasedStaples',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'food_staples')
            }

        },
        nonStapleFoods: {
            translationKey: 'purchasedNonStaples',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'food_non_staples')
            }
        },
        householdItems: {
            translationKey: 'essentialHouseholdItems',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'essential_household_items')
            }
        },
        inputCroppingCosts: {
            translationKey: 'croppingCosts',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_expenses_crops')
            },
            // getResolved: bssState => {
            //     const {crops} = bssState
            //
            //     return getCropsResolvedTotals(crops)
            // }
        },
        inputLivestockCosts: {
            translationKey: 'livestockCosts',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_expenses_livestock')
            }
        },
        education: {
            translationKey: 'education',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'expenditure_education')
            }
        },
        health: {
            translationKey: 'health',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'expenditure_health')
            }
        },
        otherProductionCosts: {
            translationKey: 'otherProductionCosts',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                // todo: need to see what costs are meant here!!
                return getFieldOfTotals(totalsByWealthGroup, 'expenditure_other')
            }
        },
        loanRepayment: {
            translationKey: 'loanRepayment',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'cash_expenses_remittance_credit')
            }
        },
        other: {
            translationKey: 'other',
            getTotal: (data) => {
                const {totalsByWealthGroup} = data
                return getFieldOfTotals(totalsByWealthGroup, 'expenditure_other')
            }
        }
    }
}