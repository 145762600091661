import {TableCell, TableRow} from "@mui/material";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum, Project_Interview_Data_Type_Enum} from "../../../lib";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useTranslation} from "react-i18next";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";

const castToNumber = (value: string | number) => {
    if (!value) {
        return 0
    }
    if (value === '-') {
        return 0
    }
    return Number(value)
}

type ReferenceYearOnWealthGroup = {
    [wealthGroupId: string]: number
}

export function LivestockTotalHerdCalculated({livestockType}: { livestockType: Livestock_Type_Enum }) {
    // need to calculate on db values or on resolved values
    const {t} = useTranslation()
    const {livestock, wealthGroups} = useBaselineOverviewContext()

    const {base, data} = livestock.grouped[livestockType]
    const herd = base?.[Project_Bss_Data_Type_Enum.LivestockHerd]
    const slaughter = base?.[Project_Bss_Data_Type_Enum.LivestockSlaughter]
    const startOfReference: ReferenceYearOnWealthGroup = wealthGroups.reduce((acc, wealthGroup) => {
        return {
            ...acc,
            [wealthGroup.id]: getStartOfReference(wealthGroup.id)
        }
    }, {})
    const endOfReference: ReferenceYearOnWealthGroup = wealthGroups.reduce((acc, wealthGroup) => {
        return {
            ...acc,
            [wealthGroup.id]: getEndOfReference(wealthGroup.id)
        }
    }, {})

    // console.log({startOfReference})

    function getStartOfReference(wealthGroupId: string) {
        const currentHerd = herd[wealthGroupId]
        if (!currentHerd) {
            return 0
        }
        return (castToNumber(currentHerd?.breeding_female.avg1) || 0) +
            // (castToNumber(currentHerd?.new_birth.avg1) || 0) +
            (castToNumber(currentHerd?.animals_other.avg1) || 0)
        // (castToNumber(currentHerd?.total_given_away.avg1) || 0)
    }

    function getEndOfReference(wealthGroupId: string) {
        const start = startOfReference[wealthGroupId]
        const purchased = Object.keys(data[Project_Interview_Data_Type_Enum.LivestockExpenditure] || {})
            .reduce((acc, key) => {
                return acc + castToNumber(data[Project_Interview_Data_Type_Enum.LivestockExpenditure][key]?.data?.[wealthGroupId]?.get_animals.avg1)
            }, 0)
        const sold = Object.keys(data[Project_Interview_Data_Type_Enum.LivestockSale] || {})
            .reduce((acc, key) => {
                return acc + castToNumber(data[Project_Interview_Data_Type_Enum.LivestockSale][key]?.data?.[wealthGroupId]?.get_animals.avg1)
            }, 0)
        return start -
            (castToNumber(slaughter[wealthGroupId]?.slaughtered.avg1) || 0) +
            (castToNumber(herd[wealthGroupId]?.new_birth.avg1) || 0) + (castToNumber(herd[wealthGroupId]?.new_birth_secondary.avg1) || 0) -
            (castToNumber(herd[wealthGroupId]?.total_given_away.avg1) || 0) - sold + purchased
    }

    return (
        <>
            <TableRow sx={{bgcolor: 'success.lighter'}}>
                <TableCell align={'right'}>
                    {t('startReferenceYear')}
                </TableCell>

                {wealthGroups.map(wealthGroup => (
                    <TableCell key={wealthGroup.id}>
                        {getNumberFormat(startOfReference[wealthGroup.id])}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow sx={{bgcolor: 'success.lighter'}}>
                <TableCell align={'right'}>
                    {t('endReferenceYear')}
                </TableCell>

                {wealthGroups.map(wealthGroup => (
                    <TableCell key={wealthGroup.id}>
                        {getNumberFormat(endOfReference[wealthGroup.id])}
                    </TableCell>
                ))}
            </TableRow>
        </>
    )
}