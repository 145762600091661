import {
    BssBaselineDetailQuery,
    CropProductionFragment,
    GreenRootCropsFragment,
    InterviewBaseFragment,
    InterviewDataFragment,
    InterviewLivestockBaseFragment,
    InterviewLivestockMilkFragment,
    InterviewTotalsFragment,
    Livestock_Type_Enum,
    Project_Bss_Data_Type_Enum,
    TermSimpleFragment
} from "../../lib";
import {ReactNode} from "react";

export type BaselineOverviewProps = {
    baselineId: string
    data: BssBaselineDetailQuery
}


export type BaselineDataOverviewType = Project_Bss_Data_Type_Enum

// type BaselineDataOverviewType = (typeof Project_Interview_Data_Type_Enum) & (typeof ExtendedDataTypes)
type GroupedList = {
    [type in BaselineDataOverviewType]: {
        [termId: string]: {
            term: TermSimpleFragment
            type: BaselineDataOverviewType
            data: {
                [wealthGroupId: string]: GroupedDataReturnType
            }
        }
    }
};


type WealthGroupItem = BssBaselineDetailQuery['wealth_groups'][number] & {
    short: string
};
export type WealthGroups = WealthGroupItem[];

type InterviewDataRow = BssBaselineDetailQuery['bss_baseline_view'][number]['data'][number]
type CropsProductionRow = BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_crops'][number];
export type  BaselineGroupedCropData = {
    [seasonId: string]: {
        season: BssBaselineDetailQuery['project_interview_crops_season'][number]['season'],
        [Project_Bss_Data_Type_Enum.CropsExpenditure]: {
            [termId: string]: {
                term: BssBaselineDetailQuery['project_interview_crops_season'][number]['project_interview_data'][number]['term'],
                data: {
                    [wealthGroupId: string]: GroupedDataReturnType
                }
            }
        }
        [Project_Bss_Data_Type_Enum.CropsProduction]: {
            [termId: string]: {
                term: CropsProductionRow['term'],
                data: {
                    [wealthGroupId: string]: GroupedDataReturnType
                }
            }
        }
        [Project_Bss_Data_Type_Enum.CropsGreen]: {
            [wealthGroupId: string]: GroupedDataReturnType
        }
        [Project_Bss_Data_Type_Enum.CropsRoot]: {
            [wealthGroupId: string]: GroupedDataReturnType
        }
    }
}

export type BaselineOverviewContextProps = {
    baselineId: string
    baselineData: BssBaselineDetailQuery['project_by_pk']
    interviews: BssBaselineDetailQuery['interviews']
    wealthGroups: WealthGroups
    dataByType: GroupedList
    cropsBySeason: BaselineGroupedCropData
    totalsByWealthGroup: {
        [wealthGroupId: string]: GroupedTotalReturnType
    }
    householdProfile: {
        [Project_Bss_Data_Type_Enum.HouseholdProfileBase]: {
            [wealthGroupId: string]: GroupedDataReturnType
        }
        [Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets]: {
            [termId: string]: {
                term: TermSimpleFragment
                data: {
                    [wealthGroupId: string]: GroupedDataReturnType
                }
            }
        }
        [Project_Bss_Data_Type_Enum.HouseholdCerealReserves]: {
            [termId: string]: {
                term: TermSimpleFragment
                data: {
                    [wealthGroupId: string]: GroupedDataReturnType
                }
            }
        }
    }
    livestock: {
        sales: {
            [termId: string]: {
                term: TermSimpleFragment
                data: {
                    [wealthGroupId: string]: GroupedDataReturnType
                }
            }
        }
        expenditure: {
            [termId: string]: {
                term: TermSimpleFragment
                data: {
                    [wealthGroupId: string]: GroupedDataReturnType
                }
            }
        }
        grouped: {
            [livestockType in Livestock_Type_Enum]: {
                base: {
                    [Project_Bss_Data_Type_Enum.MilkProductionPeak]: {
                        [wealthGroupId: string]: GroupedDataReturnType
                    },
                    [Project_Bss_Data_Type_Enum.MilkProductionOffPeak]: {
                        [wealthGroupId: string]: GroupedDataReturnType
                    }
                    [Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]: {
                        [wealthGroupId: string]: GroupedDataReturnType
                    }
                    [Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]: {
                        [wealthGroupId: string]: GroupedDataReturnType
                    }
                    [Project_Bss_Data_Type_Enum.LivestockHerd]: {
                        [wealthGroupId: string]: GroupedDataReturnType
                    },
                    [Project_Bss_Data_Type_Enum.LivestockSlaughter]: {
                        [wealthGroupId: string]: GroupedDataReturnType
                    }
                }
                data: {
                    [type: string]: {
                        [termId: string]: {
                            term: TermSimpleFragment
                            data: {
                                [wealthGroupId: string]: GroupedDataReturnType
                            }
                        }
                    }
                },

            }
        }
    }
    interviewCountByWealthGroup: {
        [wealthGroupId: string]: {
            interviewsCount: number
            locationList: {
                [locationId: string]: boolean
            }
        }
    }
    getData: (filter: GetBssContextDataFilter) => {
        term?: TermSimpleFragment,
        data: GroupedDataReturnType
    } | BaselineGroupedCropData | null
}
export const LivestockBaseEnums = [
    Project_Bss_Data_Type_Enum.LivestockHerd,
    Project_Bss_Data_Type_Enum.MilkProductionPeak,
    Project_Bss_Data_Type_Enum.MilkProductionOffPeak,
    Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary,
    Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary,
    Project_Bss_Data_Type_Enum.LivestockSlaughter
]
export type GetBssContextDataFilter = {
    type: Project_Bss_Data_Type_Enum
    seasonId?: string
    livestock_type?: Livestock_Type_Enum | null
    term_id: string
};
export type BaselineOverviewTableSectionProps = {
    type: BaselineDataOverviewType
}

type BaselineViewRow = BssBaselineDetailQuery['bss_baseline_view'][number];
type Term = BaselineViewRow['term'] | TermSimpleFragment;

export type BaselineTableRowProps = {
    type: BaselineDataOverviewType
    term: Term
    isEven: boolean
    data: WealthGroupDataReturnType
    livestock_type?: Livestock_Type_Enum
    TopRow?: ReactNode
    seasonId?: string
    stitchedElements?: BssResolveElement[]
}

type InterviewDataCombinedRow = InterviewDataRow & CropsProductionRow

export type BssResolveElement = {
    term: Term
    type: BaselineDataOverviewType
    groupedByWealthGroupData: {
        [K: string]: GroupedDataReturnType
    }
    livestock_type?: Livestock_Type_Enum
    seasonId?: string
}

export type BaselineOverviewResolveDialogProps = BssResolveElement & {
    open: boolean
    onClose: () => void
    stitchedElements?: BssResolveElement[]
}

export type ResolveDataSectionProps = Pick<BaselineOverviewResolveDialogProps, 'type' | 'groupedByWealthGroupData' | 'term' | 'livestock_type' | 'stitchedElements'>
export type ResolveDataSectionDetailProps = Pick<BaselineOverviewResolveDialogProps, 'groupedByWealthGroupData'> & {
    dataName: AvailableBaselineTypes
    showOneCard?: boolean
}

export type ComputedReadonlyFieldProps =
    Pick<BaselineOverviewResolveDialogProps, 'groupedByWealthGroupData' | 'term' | 'livestock_type'>
    & {
    type: BaselineDataOverviewType
    showOneCard: boolean
    disabledWealthGroups: { [k: string]: boolean }
    outliers: number
};

export type BaselineOverviewTableSectionRowDetailProps = {
    isExpanded: boolean
    type: BaselineDataOverviewType
    wealthGroupData: GroupedDataReturnType
    showMoreRows: boolean
}

export type GroupAverageTypes = {
    avg0: number
    avg1: number
    avg2: number
    max: number[]
    min: number[]
};
export type GroupColumnAverages = {
    [K in AvailableBaselineTypes]: GroupAverageTypes
};

export type GroupColumnTotalAverages = {
    [K in AvailableTotalReturnKeys]: {
        avg0: number
        avg1: number
        avg2: number
        max: number[]
        min: number[]
    }
}

type ComputedRowData = {
    months_count: number
    duration_months_count: number
    duration_frequency_months: number
    no_measure_month: number
    get_profit: number
    get_frequency_per_months: number
    get_food_kcal: number
    get_sold_total: number
    get_total_quantity_kg: number
    get_sold_price_unit: number
    get_amount: number
    get_sold_amount: number
    get_other_use?: number
    get_percent_month: number
    get_amount_kg: number
    get_nr_animals: number
    get_meat_income: number
    get_milk_production?: number
    get_skim_bool?: number
    get_animals?: number
    kcal?: number
    livestock_type?: Livestock_Type_Enum
    price_per_kg?: number
    remittance_amount?: number
};


export type AvailableBaselineTypes = keyof InterviewDataFragment
    | keyof ComputedRowData
    | keyof InterviewBaseFragment
    | 'count'
    | keyof CropProductionFragment
    | keyof GreenRootCropsFragment
    | keyof InterviewLivestockBaseFragment
    | keyof InterviewLivestockMilkFragment


// type RawDataRow = InterviewDataCombinedRow


export type BaselineDataWithComputed = InterviewDataCombinedRow & ComputedRowData;

// keep in sync with AvailableBaselineTypes | ComputedFieldNames
export type BaselineRowData = BaselineDataWithComputed & CropsProductionRow;

// todo: needs to be revisited with Gav
export const TypeIsSummed: Partial<AvailableBaselineTypes>[] = ['total_quantity', 'total_expenses', 'total_cash_income', 'quantity', 'payment', 'total_kcal'];

export type HouseHoldStatisticData = {
    kcalPppd: number
    hhMember: number
    durationDays: number
    schoolFoodKcal: number
    schoolDays: number
};
export type BssCalculaterProps = {
    hhData: HouseHoldStatisticData
    term?: BaselineOverviewResolveDialogProps['term']
    food_term?: InterviewDataRow['food_term']
    livestock_type?: Livestock_Type_Enum
};
export type CalculatedFieldFunctionProps = BaselineRowData & BssCalculaterProps

type CalculatedFieldFunction = (props: CalculatedFieldFunctionProps) => number
export type ComputedDataField = {
    name: AvailableBaselineTypes
    calculator: CalculatedFieldFunction
};

type BaselineDataFields =
    BaselineRowData
    | BssBaselineDetailQuery['project_interview_base'][number]
    | BssBaselineDetailQuery['project_interview_base'][number]['project_interview_productive_assets'][number]
    | CropsProductionRow
    | GreenRootCropsFragment
    | BssBaselineDetailQuery['project_interview_livestock'][number]
    | BssBaselineDetailQuery['project_interview_livestock'][number]['peak_milk']

export type BaselineDataRows = BaselineDataFields[];


export type GroupedDataReturnType = GroupColumnAverages & {
    data: BaselineDataRows
}


export type AvailableTotalReturnKeys =
    keyof InterviewTotalsFragment
    | keyof TotalComputedFields

export type GroupTotalAverages = GroupColumnTotalAverages;


export type AdditionalFieldTotalComputed = {
    food_staples: number
    food_non_staples: number
    essential_household_items: number
    income_livestock_product: number
    income_livestock_sale: number
    calories_labour_jobs_migration: number
    expenditure_education: number
    expenditure_health: number
    expenditure_other: number
    cereal_leftover_kcal: number
}
type TotalComputedFields = {
    calories_total: number
    income_total: number
    expenditure_total: number
    location_id: string
    income_labour_jobs_migration: number
} & AdditionalFieldTotalComputed;
export type GroupedTotalReturnType = GroupTotalAverages & {
    data: NonNullable<BssBaselineDetailQuery['project_interview_totals'][number] & TotalComputedFields>[]
}

export type WealthGroupDataReturnType = {
    [wealthGroupId: string]: GroupedDataReturnType
}

export type GetMinMaxProps = {
    col: string,
    data: GroupedDataReturnType['data']
    locationCount: number
};