import {Table, TableBody, TableContainer} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {BaselineTableRow} from "../baselineTableRow/BaselineTableRow";
import {Project_Bss_Data_Type_Enum} from "../../../lib";
import {staticTermId} from "../staticTermId";
import {useTranslation} from "react-i18next";
import {headlineTranslationMapping} from "../translationHelper";

export function HouseholdBaseData() {
    const {householdProfile} = useBaselineOverviewContext()
    const {HOUSEHOLD_PROFILE_BASE} = householdProfile
    const {t} = useTranslation()
    return (
        <TableContainer>
            <Table size={'small'} padding={'checkbox'}>
                <TableBody>
                    <BaselineTableRow
                        isEven={false}
                        type={Project_Bss_Data_Type_Enum.HouseholdProfileBase}
                        data={HOUSEHOLD_PROFILE_BASE}
                        term={{
                            id: staticTermId[Project_Bss_Data_Type_Enum.HouseholdProfileBase],
                            en: t(headlineTranslationMapping[Project_Bss_Data_Type_Enum.HouseholdProfileBase]),
                            term_category_mns: []
                        }}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    )
}