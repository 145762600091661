import {BaselineDataGroup, BaselineState} from "../../../state/bssTypes/bssTypes";
import {
    BaselineSummarizeExpenditureKeys,
    BaselineSummarizeIncomeKeys,
    BaselineSummarizeKcalKeys,
    GetTotalResolvedLivestockType,
    GetTotalResolvedType
} from "../baselineSummarizeItems";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum} from "../../../../lib";
import {EXPENDITURE_CATEGORIES_FILTER_FIXTURES} from "../../../../tss/fixtures";

type TotalsByWealthGroup = {
    kcal: number
    income: number
    expenditure: number
};
type TotalsByWealthGroupOptional = {
    kcal?: number
    income?: number
    expenditure?: number
};

export const getCropsResolvedTotals = (cropsData: BaselineState['crops']): GetTotalResolvedType => {
    if (!cropsData) {
        return {}
    }
    const totals: {
        [wealthGroupId: string]: TotalsByWealthGroup
    } = {}
    Object.keys(cropsData).forEach(cropId => {
        const runTotals = (grouped: BaselineDataGroup) => {
            if (!grouped) {
                return
            }
            Object.keys(grouped).forEach(termId => {
                const {wealthGroupData} = grouped[termId]
                // todo shall we enable this?
                //     if (!isResolved) {
                //         return
                //     }
                Object.keys(wealthGroupData).forEach(wealthGroupId => {
                    if (!totals[wealthGroupId]) {
                        totals[wealthGroupId] = {
                            kcal: 0,
                            income: 0,
                            expenditure: 0
                        }
                    }
                    const {total_kcal, total_cash_income, total_expenses} = wealthGroupData[wealthGroupId]
                    totals[wealthGroupId].kcal += Number(total_kcal || 0)
                    totals[wealthGroupId].income += Number(total_cash_income || 0)
                    totals[wealthGroupId].expenditure += Number(total_expenses || 0)
                })
            })
        }
        Object.keys(cropsData[cropId]).forEach(cropItemKey => {
            runTotals(cropsData[cropId]?.[cropItemKey as Project_Bss_Data_Type_Enum])
        })
    })
    return totals
}

export const getLivestockResolvedTotals = (livestockData: BaselineState['livestock']): GetTotalResolvedLivestockType => {
    if (!livestockData) {
        return {
            totalsLivestock: {},
            totalsLivestockProduct: {}
        }
    }
    const totalsLivestock: {
        [wealthGroupId: string]: TotalsByWealthGroup
    } = {}
    const totalsLivestockProduct: {
        [wealthGroupId: string]: TotalsByWealthGroup
    } = {}
    Object.keys(livestockData.data).forEach(livestockItemKey => {
        const livestockItemData = livestockData.data[livestockItemKey as Project_Bss_Data_Type_Enum]
        Object.keys(livestockItemData).forEach(identifier => {
            const {wealthGroupData} = livestockItemData[identifier]
            // todo shall we enable this?
            //     if (!isResolved) {
            //         return
            //     }
            Object.keys(wealthGroupData).forEach(wealthGroupId => {
                if (!totalsLivestock[wealthGroupId]) {
                    totalsLivestock[wealthGroupId] = {
                        kcal: 0,
                        income: 0,
                        expenditure: 0
                    }
                }
                if (!totalsLivestockProduct[wealthGroupId]) {
                    totalsLivestockProduct[wealthGroupId] = {
                        kcal: 0,
                        income: 0,
                        expenditure: 0
                    }
                }
                const {total_kcal, total_cash_income, total_expenses} = wealthGroupData[wealthGroupId]
                totalsLivestock[wealthGroupId].kcal += (total_kcal || 0)
                totalsLivestockProduct[wealthGroupId].income += (total_cash_income || 0)
                // todo: why do we not split livestock expenses same like income? Gav..
                totalsLivestock[wealthGroupId].expenditure += (total_expenses || 0)
            })
        })
    })
    Object.keys(livestockData.herd).forEach(livestockEnum => {
        const livestockHerdData = livestockData.herd[livestockEnum as Livestock_Type_Enum]
        Object.keys(livestockHerdData).forEach(livestockItemKey => {
            const livestockItemData = livestockHerdData[livestockItemKey as Project_Bss_Data_Type_Enum]
            Object.keys(livestockItemData).forEach(identifier => {
                const {wealthGroupData} = livestockItemData[identifier]
                // todo shall we enable this?
                //     if (!isResolved) {
                //         return
                //     }
                Object.keys(wealthGroupData).forEach(wealthGroupId => {
                    if (!totalsLivestock[wealthGroupId]) {
                        totalsLivestock[wealthGroupId] = {
                            kcal: 0,
                            income: 0,
                            expenditure: 0
                        }
                    }
                    if (!totalsLivestockProduct[wealthGroupId]) {
                        totalsLivestockProduct[wealthGroupId] = {
                            kcal: 0,
                            income: 0,
                            expenditure: 0
                        }
                    }

                    const {
                        total_kcal,
                        total_cash_income,
                        total_expenses,
                        // @ts-ignore
                        total_kcal_sum,
                        get_meat_income
                    } = wealthGroupData[wealthGroupId]
                    // totalsLivestockProduct[wealthGroupId].kcal += (total_kcal || 0)
                    totalsLivestock[wealthGroupId].kcal += Number(total_kcal || 0)
                    totalsLivestock[wealthGroupId].kcal += Number(total_kcal_sum || 0)
                    totalsLivestock[wealthGroupId].expenditure += Number(total_expenses || 0)
                    if (livestockItemKey === Project_Bss_Data_Type_Enum.LivestockSale) {
                        totalsLivestock[wealthGroupId].income += Number(total_cash_income || 0)
                    } else {
                        totalsLivestockProduct[wealthGroupId].income += Number(total_cash_income || 0)
                    }
                    // totalsLivestock[wealthGroupId].income += (total_cash_income || 0)
                    totalsLivestockProduct[wealthGroupId].income += Number(get_meat_income || 0)
                })
            })
        })
    })

    return {
        totalsLivestockProduct,
        totalsLivestock
    }
}


export const getDataResolvedTotals = (data: BaselineState['data']) => {
    const totals: {
        kcal: {
            [k in Exclude<BaselineSummarizeKcalKeys, 'summarize' | 'livestockProducts' | 'crops'>]: {
                [wealthGroupId: string]: TotalsByWealthGroupOptional
            }
        }
        income: {
            [k in Exclude<BaselineSummarizeIncomeKeys, 'summarize' | 'crops' | 'livestockProducts' | 'livestockSales'>]: {
                [wealthGroupId: string]: TotalsByWealthGroupOptional
            }
        },
        expenditure: {
            [k in Exclude<BaselineSummarizeExpenditureKeys, 'summarize' | 'inputCroppingCosts' | 'inputLivestockCosts'>]: {
                [wealthGroupId: string]: TotalsByWealthGroupOptional
            }
        }
    } = {
        kcal: {
            purchases: {},
            foodAid: {},
            fishingWildFood: {},
            migrationMeals: {}
        },
        income: {
            labourJobsMigration: {},
            selfEmployment: {},
            remittanceCreditAssistance: {},
            fishingWildFoodSales: {}
        },
        expenditure: {
            stapleFoods: {},
            nonStapleFoods: {},
            householdItems: {},
            education: {},
            health: {},
            otherProductionCosts: {},
            other: {},
            loanRepayment: {}
        }
    }
    if (!data) {
        return totals
    }
    Object.keys(data).forEach(dataItemKey => {
        const dataIdentifier = dataItemKey as Project_Bss_Data_Type_Enum;
        const dataItemData = data[dataIdentifier]
        Object.keys(dataItemData).forEach(itemId => {
            const {wealthGroupData, term} = dataItemData[itemId]
            const findIdInCategories = (needle: string) => term?.term_category_mns?.some(i => i.category_id === needle) ?? false
            // todo shall we enable this?
            //     if (!isResolved) {
            //         return
            //     }
            Object.keys(wealthGroupData).forEach(wealthGroupId => {
                const {total_kcal, total_cash_income, total_expenses} = wealthGroupData[wealthGroupId]


                switch (dataIdentifier) {
                    case Project_Bss_Data_Type_Enum.ExpenditureFood:
                        totals.kcal.purchases[wealthGroupId] = {
                            kcal: (totals.kcal.purchases[wealthGroupId]?.kcal ?? 0) + Number(total_kcal || 0)
                        };
                        const isStaplesFood = findIdInCategories(EXPENDITURE_CATEGORIES_FILTER_FIXTURES.staplesFood)
                        if (isStaplesFood) {
                            totals.expenditure.stapleFoods[wealthGroupId] = {
                                expenditure: (totals.expenditure.stapleFoods[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                            };
                        } else {
                            totals.expenditure.nonStapleFoods[wealthGroupId] = {
                                expenditure: (totals.expenditure.nonStapleFoods[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                            };
                        }
                        break;
                    case Project_Bss_Data_Type_Enum.FoodAid:
                    case Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding:
                        totals.kcal.foodAid[wealthGroupId] = {
                            kcal: (totals.kcal.foodAid[wealthGroupId]?.kcal ?? 0) + Number(total_kcal || 0)
                        };
                        totals.income.remittanceCreditAssistance[wealthGroupId] = {
                            income: (totals.income.remittanceCreditAssistance[wealthGroupId]?.income ?? 0) + Number(total_cash_income || 0)
                        };
                        break;

                    case Project_Bss_Data_Type_Enum.FishingWildFood:
                        totals.kcal.fishingWildFood[wealthGroupId] = {
                            kcal: (totals.kcal.fishingWildFood[wealthGroupId]?.kcal ?? 0) + Number(total_kcal || 0)
                        };
                        totals.income.fishingWildFoodSales[wealthGroupId] = {
                            income: (totals.income.fishingWildFoodSales[wealthGroupId]?.income ?? 0) + Number(total_cash_income || 0)
                        };
                        break;

                    case Project_Bss_Data_Type_Enum.FishingWildFoodExpenses:
                        totals.expenditure.otherProductionCosts[wealthGroupId] = {
                            expenditure: (totals.expenditure.otherProductionCosts[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                        };
                        break;
                    case Project_Bss_Data_Type_Enum.SelfEmployment:
                        totals.income.selfEmployment[wealthGroupId] = {
                            income: (totals.income.selfEmployment[wealthGroupId]?.income ?? 0) + Number(total_cash_income || 0)
                        };
                        break;
                    case Project_Bss_Data_Type_Enum.Employment:
                    case Project_Bss_Data_Type_Enum.LabourExchange:
                    case Project_Bss_Data_Type_Enum.Migration:
                        totals.income.labourJobsMigration[wealthGroupId] = {
                            income: (totals.income.labourJobsMigration[wealthGroupId]?.income ?? 0) + Number(total_cash_income || 0)
                        };
                        totals.kcal.migrationMeals[wealthGroupId] = {
                            kcal: (totals.kcal.migrationMeals[wealthGroupId]?.kcal ?? 0) + Number(total_kcal || 0)
                        }
                        break;
                    case Project_Bss_Data_Type_Enum.RemittancesCreditIncome:
                        totals.income.remittanceCreditAssistance[wealthGroupId] = {
                            income: (totals.income.remittanceCreditAssistance[wealthGroupId]?.income ?? 0) + Number(total_cash_income || 0)
                        }
                        break;
                    case Project_Bss_Data_Type_Enum.RemittancesCreditExpenses:
                        totals.expenditure.loanRepayment[wealthGroupId] = {
                            expenditure: (totals.expenditure.loanRepayment[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                        }
                        break;
                    case Project_Bss_Data_Type_Enum.ExpenditureNonFood:
                        // need to split based on category id
                        if (findIdInCategories(EXPENDITURE_CATEGORIES_FILTER_FIXTURES.health)) {
                            totals.expenditure.health[wealthGroupId] = {
                                expenditure: (totals.expenditure.health[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                            };

                        } else if (findIdInCategories(EXPENDITURE_CATEGORIES_FILTER_FIXTURES.education)) {
                            totals.expenditure.education[wealthGroupId] = {
                                expenditure: (totals.expenditure.education[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                            }
                        } else if (findIdInCategories(EXPENDITURE_CATEGORIES_FILTER_FIXTURES.essentialHouseholdItems)) {
                            totals.expenditure.householdItems[wealthGroupId] = {
                                expenditure: (totals.expenditure.householdItems[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                            }
                        } else {
                            totals.expenditure.other[wealthGroupId] = {
                                expenditure: (totals.expenditure.other[wealthGroupId]?.expenditure ?? 0) + Number(total_expenses || 0)
                            };
                        }
                        break;
                }
            })
        })
    })

    return totals
}