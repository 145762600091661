import {BssBaselineDetailQuery, Project_Interview_Data_Type_Enum} from "../../../lib";
import groupBy from "lodash/groupBy";
import {processTotals} from "../baselineGroupHelper";
import {
    AdditionalFieldTotalComputed,
    BaselineOverviewContextProps,
    GroupedTotalReturnType
} from "../baselineOverviewTypes";
import {EXPENDITURE_CATEGORIES_FILTER_FIXTURES, TERM_CATEGORIES_FILTER_FIXTURES} from "../../../tss/fixtures";

type AdditionalField = keyof AdditionalFieldTotalComputed

type PrepareBaselineTotalsProps = {
    totals: BssBaselineDetailQuery['project_interview_totals'],
    viewData: BssBaselineDetailQuery['bss_baseline_view'],
    interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']
    livestock: BssBaselineDetailQuery['project_interview_livestock']
    base: BssBaselineDetailQuery['project_interview_base']
};

export function prepareBaselineTotals({
                                          totals,
                                          interviewCountByWealthGroup,
                                          viewData,
                                          livestock,
                                          base
                                      }: PrepareBaselineTotalsProps) {

    const additionalFields: {
        [locationId: string]: {
            [wealthGroupId: string]: {
                [field in AdditionalField]: number
            }
        }
    } = {}

    base.forEach(interview => {
        const locationId: string = interview.project_interview.location_id
        const wealthGroupId: string = interview.project_interview.wealth_group_id
        if (locationId && wealthGroupId) {
            additionalFields[locationId] = additionalFields[locationId] || {}
            additionalFields[locationId][wealthGroupId] = additionalFields[locationId][wealthGroupId] || {}
            // this is important value which is being added to the crops section of the totals
            if (additionalFields[locationId][wealthGroupId]['cereal_leftover_kcal']) {
                additionalFields[locationId][wealthGroupId]['cereal_leftover_kcal'] += Number(interview.leftover_cereal_total_calories || 0)
            } else {
                additionalFields[locationId][wealthGroupId]['cereal_leftover_kcal'] = Number(interview.leftover_cereal_total_calories || 0)
            }
        }
    })

    livestock.forEach(livestockItem => {
        const locationId: string = livestockItem.project_interview_base?.project_interview?.location_id
        const wealthGroupId: string = livestockItem.project_interview_base?.project_interview?.wealth_group_id
        if (locationId && wealthGroupId) {
            livestockItem.project_interview_data.forEach(dataItem => {
                if (dataItem.type === Project_Interview_Data_Type_Enum.LivestockSale) {
                    if (dataItem.term?.term_category_mns?.find(item => item.category_id === TERM_CATEGORIES_FILTER_FIXTURES.LIVESTOCK)) {
                        additionalFields[locationId] = additionalFields[locationId] || {}
                        additionalFields[locationId][wealthGroupId] = additionalFields[locationId][wealthGroupId] || {}
                        if (additionalFields[locationId][wealthGroupId]['income_livestock_sale']) {
                            additionalFields[locationId][wealthGroupId]['income_livestock_sale'] += Number(dataItem.total_cash_income || 0)
                        } else {
                            additionalFields[locationId][wealthGroupId]['income_livestock_sale'] = Number(dataItem.total_cash_income || 0)
                        }
                    } else {
                        additionalFields[locationId] = additionalFields[locationId] || {}
                        additionalFields[locationId][wealthGroupId] = additionalFields[locationId][wealthGroupId] || {}
                        if (additionalFields[locationId][wealthGroupId]['income_livestock_product']) {
                            additionalFields[locationId][wealthGroupId]['income_livestock_product'] += Number(dataItem.total_cash_income || 0)
                        } else {
                            additionalFields[locationId][wealthGroupId]['income_livestock_product'] = Number(dataItem.total_cash_income || 0)
                        }
                    }
                }
            })
        }
    })


    viewData
        .forEach(view => {
            const processDataItem = (fieldName: AdditionalField) => {
                view.data.forEach(dataItem => {
                    const locationId: string = dataItem.project_interview_base?.project_interview?.location_id
                    const wealthGroupId: string = dataItem.project_interview_base?.project_interview?.wealth_group_id
                    if (locationId && wealthGroupId) {
                        additionalFields[locationId] = additionalFields[locationId] || {}
                        additionalFields[locationId][wealthGroupId] = additionalFields[locationId][wealthGroupId] || {}
                        if (additionalFields[locationId][wealthGroupId][fieldName]) {
                            additionalFields[locationId][wealthGroupId][fieldName] += Number(dataItem.total_expenses || 0)
                        } else {
                            additionalFields[locationId][wealthGroupId][fieldName] = Number(dataItem.total_expenses || 0)
                        }
                    }
                })
            }

            if (view.type === Project_Interview_Data_Type_Enum.ExpenditureFood) {
                if (view.term?.term_category_mns
                    ?.some(item => item.category_id === EXPENDITURE_CATEGORIES_FILTER_FIXTURES.staplesFood)) {
                    processDataItem('food_staples')
                } else {
                    processDataItem('food_non_staples')
                }
            } else if (view.type === Project_Interview_Data_Type_Enum.ExpenditureNonFood) {
                if (view.term?.term_category_mns
                    ?.some(item => item.category_id === EXPENDITURE_CATEGORIES_FILTER_FIXTURES.essentialHouseholdItems)) {
                    processDataItem('essential_household_items')
                } else if (view.term?.term_category_mns
                    ?.some(item => item.category_id === EXPENDITURE_CATEGORIES_FILTER_FIXTURES.education)) {
                    processDataItem('expenditure_education')
                } else if (view.term?.term_category_mns
                    ?.some(item => item.category_id === EXPENDITURE_CATEGORIES_FILTER_FIXTURES.health)) {
                    processDataItem('expenditure_health')
                } else {
                    // todo: maybe the rest consolidate into other?
                    processDataItem('expenditure_other')
                }
            }
        })

    const groupByWealthGroup = groupBy(totals, 'project_interview_base.project_interview.wealth_group_id')
    const totalsByWealthGroup: {
        [wealthGroupId: string]: GroupedTotalReturnType
    } = {}
    Object.keys(groupByWealthGroup).forEach(wealthGroupId => {
        const mappedData = groupByWealthGroup[wealthGroupId].map(item => {
            const locationId = item.project_interview_base?.project_interview.location_id
            return {
                ...item,
                ...additionalFields[locationId]?.[wealthGroupId],
                location_id: locationId,
            }
        });
        totalsByWealthGroup[wealthGroupId] = processTotals({
            data: mappedData,
            locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
        })
    })

    return totalsByWealthGroup
}