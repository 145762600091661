import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {useBssDefaults} from "../../../state/bssState";

export function SwitchStartAverages() {
    const {startWithAverages, setStartWithAverages} = useBssDefaults()
    return (
        <FormGroup>
            <FormControlLabel value={startWithAverages}
                              control={<Switch checked={startWithAverages} onChange={(_, checked) => {
                                  setStartWithAverages(checked)
                              }}/>} label="Start with Averages"/>
        </FormGroup>
    )
}