import {createRoot} from 'react-dom/client'
import "./i18next.ts"
import '@fontsource-variable/inter';
import {StrictMode} from "react";
import App from "./App.tsx";

createRoot(document.getElementById('app')!).render(
    <StrictMode>
        <App/>
    </StrictMode>
    // <RouterProvider
    // <RouterProvider router={router}/>
)