import {
    calculateHouseholdNeeds,
    calculateSchoolFeedKcal,
    InterviewLivestockBaseFragment,
    Livestock_Type_Enum,
    Project_Bss_Data_Type_Enum,
    Project_Interview_Data_Type_Enum
} from "../../lib";
import {
    AvailableBaselineTypes,
    BaselineDataOverviewType,
    BssCalculaterProps,
    CalculatedFieldFunctionProps,
    ComputedDataField
} from "./baselineOverviewTypes";
import {livestockFixtures} from "../../tss/livestock/livestockTypes";
import {multiplyObjectFields} from "../../lib/helpers/arrayFieldSum";


const MilkProduction: GroupsOnType = {
    short: ['total_kcal_sum' as any, 'total_cash_income'],
    // @ts-ignore
    large: ['milked_female', 'days_milked', 'get_milk_production', 'quantity_consumed_day', "get_skim_bool", "sold_per_day", "price_per_litre", "quantity_given_away", "exchanged_cereal", "total_milk", "total_kcal_sum", "total_cash_income",],
    computed: [{
        // @ts-ignore
        name: 'total_milk',
        calculator: (values) => {
            return multiplyObjectFields(values, ['milked_female', 'get_milk_production'] as any)
        }
    }, {
        // @ts-ignore
        name: 'total_kcal_sum',
        calculator: (values) => {
            const reference = livestockFixtures[values.livestock_type as Livestock_Type_Enum];
            const kcalMilkTotal = multiplyObjectFields(values, ['quantity_consumed_day', 'days_milked'] as any) * (values?.get_skim_bool ? reference.milkKcalSkim : reference.milkKcalFull);

            // @ts-ignore
            const exchangedTotal = values?.exchanged_cereal ? Number(values.exchanged_cereal || 0) * 3600 : 0

            const hhNeed = calculateHouseholdNeeds(values.hhData)
            return ((kcalMilkTotal + exchangedTotal) / (hhNeed)) * 100
        }
    }, {
        name: 'total_cash_income',
        calculator: (values) => {
            return multiplyObjectFields(values, ['sold_per_day', 'price_per_litre', 'days_milked'] as any)
        }
    }]
};

export type CsvTypeName = 'Type A' | 'Type B' | 'Type C' | 'Type CSV Column'

type CsvCalculatorProps = CalculatedFieldFunctionProps & {
    sold_per_day?: number
    days_milked?: number
    baselineId: string
    wealthGroupId: string
    new_birth?: number
    breeding_female?: number
    animals_other?: number
    new_birth_secondary?: number
    losses_death?: number
    meat_per_carcass?: number
    meat_quantity_sold?: number
    quantity_given_away?: number
    slaughtered?: number
    meat_quantity_given_away?: number
};
export type CsvFieldColumnName =
    Partial<AvailableBaselineTypes>
    | `__${Partial<AvailableBaselineTypes>}`
    | '__kcal'
    | '__total_milk'
    | {
    key: string,
    calculator: (props: CsvCalculatorProps) => number
};
type GroupsOnType = {
    short: ('total_kcal' | 'total_cash_income' | 'total_expenses' | 'people_in_household' | 'amount' | 'leftover_cereal_total_calories' | 'breeding_female' | 'get_meat_income')[]
    large: Partial<AvailableBaselineTypes>[]
    computed: ComputedDataField[]
};
export const GroupColumnsOnType: {
    [k in BaselineDataOverviewType]?: GroupsOnType
} = {
    [Project_Interview_Data_Type_Enum.ExpenditureFood]: {
        large: ['quantity_unit', 'price_per_kg', 'duration_months_count', 'no_measure_month', 'total_quantity', 'total_kcal', 'total_expenses'],
        short: ['total_kcal', 'total_expenses'],
        computed: [{
            name: 'total_quantity',
            calculator: (values) => multiplyObjectFields(values, ['quantity_unit', 'no_measure_month', 'duration_months_count']),
        }, {
            name: 'total_kcal',
            calculator: (values) => {
                const kcal = values.term?.kcal ?? 0
                const total = multiplyObjectFields(values, ['quantity_unit', 'no_measure_month', 'duration_months_count'])
                const hhNeed = calculateHouseholdNeeds(values.hhData)
                return ((kcal * total) / (hhNeed)) * 100
            },
        }, {
            name: 'total_expenses',
            calculator: (values) => {
                return multiplyObjectFields(values, ['quantity_unit', 'price_per_kg', 'no_measure_month', 'duration_months_count'])
            },
            // fields: ['price', 'total_quantity']
        }]
    },
    [Project_Interview_Data_Type_Enum.ExpenditureNonFood]: {
        short: ['total_expenses'],
        large: ['price', 'quantity', 'duration_months_count', 'total_expenses'],
        computed: [{
            name: 'total_expenses',
            calculator: (values) => {
                return multiplyObjectFields(values, ['price', 'quantity', 'duration_months_count'])
            },
        }]
    },
    [Project_Interview_Data_Type_Enum.FishingWildFoodExpenses]: {
        short: ['total_expenses'],
        large: ['price', 'quantity', 'duration_months_count', 'total_expenses'],
        computed: [{
            name: 'total_expenses',
            calculator: (values) => {
                return multiplyObjectFields(values, ['price', 'quantity', 'duration_months_count'])
            },
        }]
    },

    [Project_Interview_Data_Type_Enum.Employment]: {
        short: ['total_kcal', 'total_cash_income'],
        large: ['people', 'payment', 'duration_months_count', 'duration_frequency_months', 'total_kcal', 'total_cash_income'],
        computed: [{
            name: 'total_cash_income',
            calculator: (values) => {
                return multiplyObjectFields(values, ['people', 'payment', 'duration_months_count', "duration_frequency_months"])
            },
        }]
    },

    [Project_Interview_Data_Type_Enum.SelfEmployment]: {
        short: ['total_cash_income'],
        large: ['people', 'duration_months_count', 'get_profit', 'quantity', 'total_cash_income'],
        computed: [{
            name: 'total_cash_income',
            calculator: (values) => {
                return multiplyObjectFields(values, ['get_profit', 'quantity', 'duration_months_count', 'people'])
            },
        }]
    },
    [Project_Interview_Data_Type_Enum.FishingWildFood]: {
        short: ['total_cash_income', 'total_kcal'],
        large: ['get_total_quantity_kg', 'duration_months_count', 'get_sold_total', 'get_sold_price_unit', 'total_cash_income', 'total_kcal'],
        computed: [{
            name: 'total_kcal',
            calculator: (values) => {
                const kcal = values.term?.kcal ?? 0
                const total = multiplyObjectFields(values, ['get_total_quantity_kg', 'duration_months_count']) - (values.get_sold_total || 0)
                const hhNeed = calculateHouseholdNeeds(values.hhData)
                return ((kcal * total) / (hhNeed)) * 100
            }
        }, {
            name: 'total_cash_income',
            calculator: (values) => {
                return multiplyObjectFields(values, ['get_sold_total', 'get_sold_price_unit'])
            }
        }]
    },

    [Project_Interview_Data_Type_Enum.LabourExchange]: {
        short: ['total_kcal'],
        large: ['people', 'food_amount', 'get_food_kcal', 'duration_months_count', 'duration_frequency_months', 'total_kcal'],
        computed: [{
            name: 'total_kcal',
            calculator: (values) => {
                const kcal = values.get_food_kcal ?? 0
                const total = multiplyObjectFields(values, ['people', 'food_amount', 'duration_frequency_months', 'duration_months_count'])
                const hhNeed = calculateHouseholdNeeds(values.hhData)

                return ((kcal * total) / (hhNeed)) * 100
            }
        }]
    },


    [Project_Interview_Data_Type_Enum.CropsExpenditure]: {
        short: ['total_expenses'],
        large: ['price', 'months_count', 'amount', 'total_expenses'],
        computed: [{
            name: 'total_expenses',
            calculator: (values) => {
                return multiplyObjectFields(values, ['amount', 'price', 'months_count'])
            }
        }]
    },


    [Project_Bss_Data_Type_Enum.RemittancesCreditIncome]: {
        short: ['total_cash_income'],
        large: ['remittance_amount', 'months_count', 'total_cash_income'],
        computed: [{
            name: 'total_cash_income',
            calculator: (values) => {
                return multiplyObjectFields(values, ['remittance_amount', 'months_count'])
            }
        }]
    },
    [Project_Bss_Data_Type_Enum.RemittancesCreditExpenses]: {
        short: ['total_expenses'],
        large: ['remittance_amount', 'months_count', 'total_expenses'],
        computed: [{
            name: 'total_expenses',
            calculator: (values) => {
                return multiplyObjectFields(values, ['remittance_amount', 'months_count'])
            }
        }]
    },

    [Project_Interview_Data_Type_Enum.FoodAid]: {
        short: ['total_cash_income', 'total_kcal'],
        large: ['get_amount', 'duration_months_count', 'get_sold_amount', 'total_cash_income', 'total_kcal'],
        computed: [{
            name: 'total_kcal',
            calculator: (values) => {
                const kcal = values.term?.kcal ?? 0
                const total = (values.get_amount - (values.get_sold_amount || 0)) * values.duration_months_count
                const hhNeed = calculateHouseholdNeeds(values.hhData)
                return ((kcal * total) / (hhNeed)) * 100
            }
        }]
    },
    [Project_Bss_Data_Type_Enum.CombinedData]: {
        short: ['total_kcal', 'total_cash_income', 'total_expenses'],
        large: ['total_kcal', 'total_cash_income', 'total_expenses'],
        computed: []
    },
    [Project_Bss_Data_Type_Enum.FoodAidSchoolFeeding]: {
        short: ['total_kcal'],
        large: ['number_children', 'total_kcal'],
        computed: [{
            name: 'total_kcal',
            calculator: (values) => {
                return calculateSchoolFeedKcal(values.number_children, {
                    hhSize: values.hhData.hhMember,
                    refDuration: values.hhData.durationDays,
                    kcalTotal: values.hhData.kcalPppd,
                    kcal: values.hhData.schoolFoodKcal,
                    schoolDays: values.hhData.schoolDays
                })
            }
        }]
    },
    [Project_Bss_Data_Type_Enum.Migration]: {
        short: ['total_cash_income', 'total_kcal'],
        large: ['people', 'months_count', 'total_cash_income', 'total_kcal'],
        computed: [
            {
                name: 'total_kcal',
                calculator: (values) => {
                    const hhSize = values.hhData.hhMember
                    return (Number(values.people || 0) / hhSize) * (Number(values.months_count ?? 0) / 12) * 100
                }
            }
        ]
    },
    [Project_Bss_Data_Type_Enum.HouseholdProfileBase]: {
        short: ['people_in_household'],
        large:
            ['wealth_breakdown' as any, 'people_in_household', 'land_owned', 'land_cultivated_food', 'land_cultivated_cash', 'children_primary_school', 'children_secondary_school'],
        computed: [],
    },
    [Project_Bss_Data_Type_Enum.HouseholdCerealReserves]: {
        short: ['leftover_cereal_total_calories'],
        large:
            ['leftover_cereal_amount', 'months_count', 'leftover_cereal_total_calories'],
        computed: []
    },
    [Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets]: {
        short: ['amount'],
        large:
            ['amount'],
        computed: [],
    },
    [Project_Bss_Data_Type_Enum.CropsProduction]: {
        short: ['total_kcal', 'total_cash_income'],
        large:
            ['total_produced', 'total_sold', 'get_sold_price_unit', 'get_other_use', 'total_consumed', 'total_kcal', 'total_cash_income'],
        computed: [{
            name: 'total_consumed',
            calculator: (values) => {
                return (values.total_produced || 0) - (values.total_sold || 0) - (values.get_other_use || 0)
            }
        }, {
            name: 'total_kcal',
            calculator: (values) => {
                const kcal = values.term?.kcal ?? 0
                const total = (values.total_produced || 0) - (values.total_sold || 0) - (values.get_other_use || 0)
                const hhNeed = calculateHouseholdNeeds(values.hhData)
                return ((kcal * total) / (hhNeed)) * 100
            }
        }, {
            name: 'total_cash_income',
            calculator: (values) => {
                return multiplyObjectFields(values, ['total_sold', 'get_sold_price_unit'])
            }
        }],
    },
    [Project_Bss_Data_Type_Enum.CropsRoot]: {
        short: ['total_kcal'],
        large: ['months_count', 'get_percent_month', 'total_kcal'],
        computed: [],
    },
    [Project_Bss_Data_Type_Enum.CropsGreen]: {
        short: ['total_kcal'],
        large: ['months_count', 'get_percent_month', 'total_kcal'],
        computed: [{
            name: 'total_kcal',
            calculator: (values) => {
                return (values.months_count || 0) * (values.get_percent_month || 0)
            }
        }]
    },
    [Project_Bss_Data_Type_Enum.MilkProductionPeak]:
    MilkProduction,
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeak]:
    MilkProduction,
    [Project_Bss_Data_Type_Enum.MilkProductionPeakSecondary]:
    MilkProduction,
    [Project_Bss_Data_Type_Enum.MilkProductionOffPeakSecondary]:
    MilkProduction,
    [Project_Bss_Data_Type_Enum.LivestockHerd]: {
        short: ['breeding_female'],
        large:
            ['breeding_female', 'animals_other', 'new_birth', 'new_birth_secondary', 'total_given_away', 'losses_death'],
        computed: [],
    },
    [Project_Bss_Data_Type_Enum.LivestockSlaughter]: {
        short: ["total_kcal", 'get_meat_income'],
        large:
            ['slaughtered', 'meat_per_carcass', 'meat_quantity_sold', 'meat_price_per_kg', 'meat_quantity_given_away', 'total_eggs_consumed', 'total_kcal', 'get_meat_income'],
        computed: [{
            name: 'total_kcal',
            calculator: (val) => {
                const values = val as unknown as InterviewLivestockBaseFragment & BssCalculaterProps
                const meatKcal = livestockFixtures[values.livestock_type as Livestock_Type_Enum]?.meatKcal ?? 0
                // const eggKcal = livestockFixtures[values.livestock_type as Livestock_Type_Enum]?.eggKcal
                const consumed = meatKcal * ((values.meat_per_carcass || 0) - (values.meat_quantity_given_away || 0) - (values.meat_quantity_sold || 0))
                const eggsConsumed = (values.total_eggs_consumed || 0) * 87.8
                const hhNeed = calculateHouseholdNeeds(values.hhData)
                return ((consumed + eggsConsumed) / hhNeed) * 100
            }
        }, {
            name: 'get_meat_income' as any,
            calculator: (values) => {
                return multiplyObjectFields(values, ['slaughtered', 'meat_quantity_sold', 'meat_price_per_kg'] as any)
            }
        }],
    },
    [Project_Interview_Data_Type_Enum.LivestockExpenditure]: {
        short: ['total_expenses'],
        large:
            ['get_animals', 'price', 'total_expenses'],
        computed: [{
            name: 'total_expenses',
            calculator: (values) => {
                return multiplyObjectFields(values, ['get_animals', 'price'])
            }
        }],
    },
    [Project_Interview_Data_Type_Enum.LivestockSale]: {
        short: ['total_cash_income'],
        large:
            ['get_animals', 'price', 'total_cash_income'],
        computed: [{
            name: 'total_cash_income',
            calculator: (values) => {
                return multiplyObjectFields(values, ['get_animals', 'price'])
            }
        }],
    },
    [Project_Bss_Data_Type_Enum.LivestockExpenditureOnInput]: {
        short: ['total_expenses'],
        large:
            ['quantity', 'price', 'total_expenses'],
        computed:
            [{
                name: 'total_expenses',
                calculator: (values) => {
                    return multiplyObjectFields(values, ['quantity', 'price'])
                }
            }],
    },
    [Project_Bss_Data_Type_Enum.LivestockIncomeOnProducts]: {
        short: ['total_cash_income'],
        large:
            ['quantity', 'price', 'total_cash_income'],
        computed:
            [{
                name: 'total_cash_income',
                calculator: (values) => {
                    return multiplyObjectFields(values, ['quantity', 'price'])
                }
            }],
    },
    [Project_Interview_Data_Type_Enum.LivestockExchanged]:
        {
            short: ['total_kcal'],
            large:
                ['get_nr_animals', 'get_amount_kg', 'total_kcal'],
            computed:
                [{
                    name: 'total_kcal',
                    calculator: (values) => {
                        const total = (values.term?.kcal ?? 0) * (values.get_amount_kg || 0) * (values.get_nr_animals || 0)
                        const hhNeed = calculateHouseholdNeeds(values.hhData)
                        return (total / (hhNeed)) * 100
                    }
                }]
        }
}

// const printAllFields = () => {
//     const fieldNames: string[] = []
//     Object.keys(GroupColumnsOnType).forEach((key) => {
//         const item = GroupColumnsOnType[key as BaselineDataOverviewType]
//         item?.large.forEach((field) => {
//             if (!fieldNames.includes(field)) {
//                 fieldNames.push(field)
//             }
//         })
//     })
//     console.log({fieldNames}, {length: fieldNames.length})
// }
//
// printAllFields()