import {useWatch} from "react-hook-form-mui";
import {GroupColumnsOnType} from "../baselineDataFields";
import {IconButton, Stack, TableCell, TableRow, Typography} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {useTranslation} from "react-i18next";
import {UnfoldLess, UnfoldMore} from "@mui/icons-material";
import {Fragment, useState} from "react";
import {ResolveDataSectionDetail} from "./ResolveDataSectionDetail";
import {CalculatedFieldFunctionProps, ComputedReadonlyFieldProps} from "../baselineOverviewTypes";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";
import {getDataField} from "../baselineGroupHelper";
import {useBaselineKcalData} from "../../state/useBaselineKcalData";

const fixedCellWidth = 140


type Fields = { [k: string]: CalculatedFieldFunctionProps };

export function ComputedReadonlyFields({
                                           type,
                                           showOneCard,
                                           groupedByWealthGroupData,
                                           disabledWealthGroups,
                                           outliers,
                                           term,
                                           livestock_type
                                       }: ComputedReadonlyFieldProps) {

    const {t} = useTranslation()
    const formFields = useWatch<Fields>()
    const {wealthGroups} = useBaselineOverviewContext()
    const getBaseData = useBaselineKcalData()
    const [openCell, setOpenCell] = useState<{ [k: string]: boolean }>({})

    // const wealthGroupData = fields[wealthGroupId]

    return (
        <>
            {GroupColumnsOnType[type]?.computed.map(({name: dataName, calculator}) => {
                return (
                    <Fragment key={dataName}>
                        <TableRow sx={{bgcolor: 'success.lighter'}}>
                            {!showOneCard && wealthGroups.map(wealthGroup => {
                                const hhData = getBaseData(wealthGroup.id, `avg${outliers}` as 'avg1')
                                const calculatedVal = calculator({
                                    term,
                                    livestock_type,
                                    hhData,
                                    ...formFields[wealthGroup.id]
                                } as CalculatedFieldFunctionProps)

                                return (
                                    <TableCell align={'center'} key={wealthGroup.id}>
                                        {getDataField(calculatedVal, dataName)}
                                    </TableCell>
                                )
                            })}
                            <TableCell align={'center'} variant={'head'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <span/>
                                    <span>{t('rowType.' + dataName)}</span>
                                    <IconButton size={'small'}
                                                onClick={() => {
                                                    setOpenCell(prevState => ({
                                                        ...prevState,
                                                        [dataName]: !prevState[dataName]
                                                    }))
                                                }}>
                                        {openCell[dataName] ? <UnfoldLess/> : <UnfoldMore/>}
                                    </IconButton>
                                </Stack>
                            </TableCell>
                            {wealthGroups.map(wealthGroup => {
                                const hhData = getBaseData(wealthGroup.id, `avg${outliers}` as 'avg1')
                                const calculatedVal = calculator({
                                    term,
                                    hhData,
                                    livestock_type,
                                    ...formFields[wealthGroup.id]
                                } as CalculatedFieldFunctionProps)
                                return (
                                    <TableCell key={wealthGroup.id} align={'center'}>
                                        <Stack spacing={1} direction={'column'} alignItems={'center'}>
                                            {showOneCard && (
                                                <Typography
                                                    fontWeight={disabledWealthGroups[wealthGroup.id] ? 'lighter' : 'bold'}
                                                    variant={disabledWealthGroups[wealthGroup.id] ? 'caption' : 'body2'}>
                                                    {getDataField(calculatedVal, dataName)}
                                                </Typography>
                                            )}
                                            <TextFieldHiddenInput name={`${wealthGroup.id}.${dataName}`}
                                                                  value={calculatedVal}/>
                                            <Typography
                                                fontWeight={disabledWealthGroups[wealthGroup.id] ? 'lighter' : 'normal'}
                                                variant={disabledWealthGroups[wealthGroup.id] ? 'caption' : 'body2'}>
                                                {getDataField(groupedByWealthGroupData[wealthGroup.id]?.[dataName]?.[`avg${outliers}` as 'avg1'], dataName)}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        {openCell[dataName] && (
                            <ResolveDataSectionDetail groupedByWealthGroupData={groupedByWealthGroupData}
                                                      showOneCard={showOneCard}
                                                      dataName={dataName}/>
                        )}
                    </Fragment>
                )
            })}
        </>
    )
}