import {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";
import {LoadingScreen} from "../theme/components/LoadingScreen";
import {Order_By, useAllBssVariablesQuery} from "../lib";
import {useIsOnline} from "./OnlineProvider";
import {useAllVariablesState} from "../lib/state/allVariablesState";

function allowedLocales(locale: string) {
    return ["en", "fr", "am", "id", "es", "pt"].includes(locale) ? locale : "en"
}


export default function AllBssVariableSetup({children}: PropsWithChildren) {
    const {i18n} = useTranslation()
    const locale = allowedLocales(i18n.language)
    const {isOnline} = useIsOnline()

    const ctx = useAllBssVariablesQuery({
        termOrderBy: {
            [locale]: Order_By.Asc
        },
        where: {
            term_category_mns: {
                category_id: {
                    _in: ['5c95d964-24d7-4d4a-9f27-a1a41085876f']
                }
            }
        }
    }, {
        enabled: isOnline
    })


    const {data, isLoading} = ctx

    if (data?.term) {
        useAllVariablesState.getState().initState({
            terms: data.term,
            cropSeasons: [],
            measureUnits: []
        })
    }
    if (isLoading && isOnline) {
        return (
            <LoadingScreen/>
        )
    }

    return (
        <>{children}</>
    )
}