import {MenuItem, Select, SelectProps} from "@mui/material";
import {Project_Bss_Data_Type_Enum} from "../../../lib";
import {useTranslation} from "react-i18next";
import {headlineTranslationMapping} from "../translationHelper";

const categories = Object.values(Project_Bss_Data_Type_Enum)
type BaselineResolveCategoryPickerProps = Omit<SelectProps, 'children'>

export function BaselineResolveCategoryPicker({size, ...props}: BaselineResolveCategoryPickerProps) {
    const {t} = useTranslation()
    return (
        <Select {...props} size={size || 'small'} placeholder={'select category'} sx={{minWidth: 150}} displayEmpty>
            <MenuItem value={''}>{t('selectCategory')}</MenuItem>
            {categories.map(category => (
                <MenuItem key={category} value={category}>{t(headlineTranslationMapping[category])}</MenuItem>
            ))}
        </Select>
    )
}