import {Button} from "@mui/material";
import {CloudOff} from "@mui/icons-material";
import {getCacheBaselineItem, useGetIdbQueryCache} from "../../state/bssQueryCacheState";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useIsOnline} from "../../../provider";

export function ResolveButtonBssList({projectId}: { projectId: string }) {
    const {isOnline} = useIsOnline()
    const {data, isFetching} = useGetIdbQueryCache({
        cacheName: getCacheBaselineItem(projectId as string)
    })
    const {t} = useTranslation()
    if (isFetching) {
        return null
    }

    return (
        <Button component={Link}
                color={'success'}
                disabled={!isOnline && !data}
                fullWidth={true} variant={'outlined'}
                startIcon={!data ? <CloudOff color={'warning'}/> : undefined}
                to={`/baseline?id=${projectId}`}>
            {t('resolve')}
        </Button>
    )
}