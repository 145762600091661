import {PropsWithChildren} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {ExpandMore} from "@mui/icons-material";

type BaselineCsvAccordionSectionProps = PropsWithChildren<{
    title: string
    expanded: boolean | string
    onChange: (expanded: boolean | string) => void
}>;

export function BaselineCsvAccordionSection({children, title, expanded, onChange}: BaselineCsvAccordionSectionProps) {
    const {wealthGroups} = useBaselineOverviewContext()

    return (
        <Accordion expanded={expanded === title}
                   onChange={(event, expanded) => {
                       onChange(expanded ? title : false)
                   }}
                   sx={{minWidth: 600, maxWidth: 600}}
        >
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                {wealthGroups.map(w => {
                                    return (
                                        <TableCell key={w.id}>
                                            {w.short}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {children}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}