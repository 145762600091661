import {Project_Bss_Data_Type_Enum} from "../../lib";
import {InterviewDataTableSection} from "./interviewData/InterviewDataTableSection";
import {Box} from "@mui/material";
import {CropsAccordion} from "./crops/CropsAccordion";
import {HouseholdProfileAccordion} from "./householdProfile/HouseholdProfileAccordion";
import {BaselineOverviewSettings} from "./BaselineOverviewSettings";
import {LivestockAccordion} from "./livestock/LivestockAccordion";
import {sortedDataTypes} from "./baselineHelpers";
import {BaselineStitchingFab} from "./baselineTableRow/BaselineStitchingFab";
import {BaselineStitchingAccordion} from "./baselineTableRow/BaselineStitchingAccordion";


export function BaselineOverviewTable() {
    return (
        <Box width={'100%'}>
            <BaselineOverviewSettings/>
            <HouseholdProfileAccordion/>
            <LivestockAccordion/>
            <CropsAccordion/>
            {sortedDataTypes.map((dataType) => (
                <InterviewDataTableSection key={dataType}
                                           type={dataType as Project_Bss_Data_Type_Enum}/>
            ))}
            <BaselineStitchingAccordion/>
            <BaselineStitchingFab/>
        </Box>
    )

}