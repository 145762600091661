import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {baselineSummarizeItems} from "./baselineSummarizeItems";
import {BaselineSummarizeTableContentItem} from "./BaselineSummarizeTableContentItem";
import {getBaselineResolvedTotals} from "./baselineSummarizeItemsProcess";
import {useBssState} from "../../state/bssState";


export function BaselineSummarizeTableContent() {
    const {wealthGroups, baselineId} = useBaselineOverviewContext()
    const {baselineData} = useBssState()

    const currentData = baselineData?.[baselineId]

    const baselineSums = getBaselineResolvedTotals(currentData)
    console.log({baselineSums})
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        {wealthGroups.map(item => (
                            <TableCell key={item.id}>
                                {item.short}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(baselineSummarizeItems).map((key) => {
                        const parentKey = key as 'kcal' | 'income' | 'expenditure'
                        return Object.keys(baselineSummarizeItems[parentKey]).map((item) => {
                            return (
                                <BaselineSummarizeTableContentItem key={`${parentKey}_${item}`}
                                                                   parentKey={parentKey as any}
                                                                   identifier={item}
                                    // @ts-ignore
                                                                   resolved={baselineSums?.[parentKey]?.[item]?.resolved}
                                />
                            )
                        })
                    })}
                </TableBody>
            </Table>

        </TableContainer>
    )
}