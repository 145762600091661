import groupBy from "lodash/groupBy";
import {BssBaselineDetailQuery, Project_Bss_Data_Type_Enum, TermSimpleFragment} from "../../../lib";
import {BaselineOverviewContextProps} from "../baselineOverviewTypes";
import {processHouseholdProfile, processProductiveAssets} from "../baselineGroupHelper";

type PrepareHouseholdDataProps = {
    data: BssBaselineDetailQuery['project_interview_base']
    interviewCountByWealthGroup: BaselineOverviewContextProps['interviewCountByWealthGroup']
}

export function prepareHouseholdData({data, interviewCountByWealthGroup}: PrepareHouseholdDataProps) {
    const dataByWealthGroup = groupBy(data, 'project_interview.wealth_group_id')
    const dataWithTermId = data.filter(row => row.leftover_cereal_term_id)
    const dataByTermId = groupBy(dataWithTermId, 'leftover_cereal_term_id')
    const groupedByWealthGroup: BaselineOverviewContextProps['householdProfile'] = {
        [Project_Bss_Data_Type_Enum.HouseholdCerealReserves]: {},
        [Project_Bss_Data_Type_Enum.HouseholdProfileBase]: {},
        [Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets]: {},
    }

    /**
     * productive assets
     */
    const allAssets = data
        .reduce((acc, row) => {
            return Array.isArray(row.project_interview_productive_assets) ? [
                ...acc,
                ...row.project_interview_productive_assets.map(item => ({
                    ...item,
                    project_interview: row.project_interview
                }))] : acc
        }, [] as BssBaselineDetailQuery['project_interview_base'][0]['project_interview_productive_assets'])

    const assetsGroupById = groupBy(allAssets, 'term_id')
    Object.keys(assetsGroupById).forEach(termId => {
        const assetsByWealthGroupId = groupBy(assetsGroupById[termId], 'project_interview.wealth_group_id')
        Object.keys(assetsByWealthGroupId).forEach(wealthGroupId => {
            const groupedProductiveAssetsItem = assetsByWealthGroupId[wealthGroupId]
            if (!groupedByWealthGroup[Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets][termId]) {
                groupedByWealthGroup[Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets][termId] = {
                    term: groupedProductiveAssetsItem[0].term,
                    data: {
                        [wealthGroupId]: processProductiveAssets({
                            data: groupedProductiveAssetsItem || [],
                            locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
                        })
                    }
                }
            } else {
                groupedByWealthGroup[Project_Bss_Data_Type_Enum.HouseholdProfileProductiveAssets][termId].data[wealthGroupId] = processProductiveAssets({
                    data: groupedProductiveAssetsItem || [],
                    locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
                })
            }
        })
    })


    /**
     * cereal reserves
     */
    Object.keys(dataByTermId).forEach(termId => {
        const gr = groupBy(dataByTermId[termId], 'project_interview.wealth_group_id')
        Object.keys(gr).forEach((wealthGroupId) => {
            groupedByWealthGroup[Project_Bss_Data_Type_Enum.HouseholdCerealReserves][termId] = {
                term: dataByTermId[termId][0].term as TermSimpleFragment,
                data: {
                    [wealthGroupId]: processHouseholdProfile({
                        data: gr[wealthGroupId],
                        type: Project_Bss_Data_Type_Enum.HouseholdCerealReserves,
                        locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
                    })
                }
            }
        })
    })

    /**
     * household profile
     */
    Object.keys(dataByWealthGroup).forEach(wealthGroupId => {
        groupedByWealthGroup[Project_Bss_Data_Type_Enum.HouseholdProfileBase][wealthGroupId] = processHouseholdProfile({
            data: dataByWealthGroup[wealthGroupId],
            type: Project_Bss_Data_Type_Enum.HouseholdProfileBase,
            locationCount: interviewCountByWealthGroup[wealthGroupId].interviewsCount
        })
    })


    return groupedByWealthGroup
}