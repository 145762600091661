import {TransitionProps} from "@mui/material/transitions";
import {forwardRef, PropsWithChildren, ReactElement, Ref} from "react";
import {Slide} from "@mui/material";

export const SlideUpTransition = forwardRef(function Transition(
    props: PropsWithChildren<TransitionProps> & {
        children: ReactElement<any, any>
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});