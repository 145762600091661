import {createContext, PropsWithChildren, useContext, useState} from "react";
import ExcelJS from "exceljs"
import {BaselineCsvCopyProps} from "./copyHelper";
import {CsvDataRowItem} from "./baselineCsvTypes";


type CsvSelectionProps = Omit<BaselineCsvCopyProps, "csvResolvedData" | "selectedCsvType"> & {
    // csvData: string,
    arrayData: (string | number)[][],
    sheetName?: string
};

type CsvSelectionState = Omit<CsvSelectionProps, "sheetName">
type SelectionComparisonProps = Pick<CsvSelectionProps, "term_id" | "type" | "livestock_type" | "seasonId">;
type BaselineCsvWorkbook = {
    workbook: ExcelJS.Workbook
    defaultWorkSheet: ExcelJS.Worksheet
    addCsvToSheet: (props: CsvSelectionProps) => void
    selectedResolver: CsvSelectionState[]
    isSelected: (props: SelectionComparisonProps) => boolean
    removeSelected: (index: number) => void
    selectedHeaders: CsvDataRowItem["csv"]
    unsetSelection: () => void
    getExcelDataBlob: (props?: { sheetName?: string }) => Promise<Blob>
}

const BaselineCsvContext = createContext<BaselineCsvWorkbook>({} as BaselineCsvWorkbook)

export const useBaselineWorkbook = () => {
    const context = useContext(BaselineCsvContext);
    if (!context) {
        throw new Error('useWorkbook must be used within a WorkbookProvider');
    }
    return context;
};

export function BaselineCsvProvider({children}: PropsWithChildren) {
    const [workbook] = useState(() => new ExcelJS.Workbook())
    const [defaultWorkSheet] = useState(() => workbook.addWorksheet("sheet1"))
    const [selectedResolver, setSelectedResolver] = useState<CsvSelectionState[]>([])
    const [selectedHeaders, setSelectedHeaders] = useState<CsvDataRowItem["csv"]>([])

    const isSelected = (props: SelectionComparisonProps) => {
        return selectedResolver?.some(item =>
            item.term_id === props.term_id && item.livestock_type === props.livestock_type && item.seasonId === props.seasonId && item.type === props.type)
    }

    const addCsvToSheet = (props: CsvSelectionProps) => {
        // split out the first 2 rows because they are the header
        const [firstRow, secondRow, ...restRows] = props.arrayData
        if (!selectedHeaders.length) {
            setSelectedHeaders([firstRow, secondRow])
        }
        props.arrayData = restRows

        // add to selectedResolver
        if (isSelected(props)) {
            // remove from selectedResolver
            setSelectedResolver(prevState => prevState?.filter(item => !(item.term_id === props.term_id &&
                item.livestock_type === props.livestock_type &&
                item.seasonId === props.seasonId &&
                item.type === props.type)));
        } else {
            setSelectedResolver(prevState => [...(prevState || []), props])
        }
    }

    const getExcelDataBlob = async (props?: { sheetName?: string }) => {
        const currentSheet = workbook.getWorksheet(props?.sheetName) || defaultWorkSheet;


        const convertCellValue = (cell: string | number | null) => {
            if (cell === '' || cell === null || cell === undefined) {
                return null; // Empty string becomes null
            }
            if (typeof cell === 'number') {
                return cell; // Return the number as is
            }
            const number = parseFloat(cell);
            return isNaN(number) ? cell : number; // Convert to number if possible
        };
        let startRow = 1;
        selectedHeaders.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
                const cellValue = convertCellValue(cell);
                currentSheet.getCell(startRow + rowIndex, cellIndex + 1).value = cellValue;
            })
        })
        startRow += selectedHeaders.length;

        // missing headers
        selectedResolver.forEach((item) => {
            item.arrayData.forEach((row, rowIndex) => {
                row.forEach((cell, cellIndex) => {
                    const cellValue = convertCellValue(cell);
                    currentSheet.getCell(startRow + rowIndex, cellIndex + 1).value = cellValue;
                })
            })
            startRow += item.arrayData.length;
        })

        const buffer = await workbook.xlsx.writeBuffer();
        return new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    }

    return (
        <BaselineCsvContext.Provider value={{
            workbook,
            defaultWorkSheet,
            selectedResolver,
            addCsvToSheet,
            isSelected,
            selectedHeaders,
            unsetSelection: () => {
                setSelectedResolver([]);
                setSelectedHeaders([]);
            },
            removeSelected: (index) => {
                setSelectedResolver(selectedResolver.filter((_, i) => i !== index));
            },
            getExcelDataBlob
        }}>
            {children}
        </BaselineCsvContext.Provider>
    )
}