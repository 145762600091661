import {Typography} from "@mui/material";
import {useCurrentUpdatedAt} from "../../state/bssState";
import {useDate} from "../../../hooks/useDate";
import {useTranslation} from "react-i18next";

export function BaselineLastUpdated({baselineId}: { baselineId: string }) {
    const dateStamp = useCurrentUpdatedAt(baselineId)
    const {t} = useTranslation()
    const {getDate} = useDate()
    if (!dateStamp) return null
    return (
        <Typography paddingTop={2} paddingLeft={2}>
            {t('lastUpdated')}&nbsp;
            {getDate(dateStamp, {
                timeStyle: 'short',
                dateStyle: 'short'
            })}
        </Typography>
    )
}