import {TssLayout} from "../../theme/layouts/tss";
import {useBssBaselineDetailQuery} from "../../lib/gql/__codegen__/react-query";
import {BaselineOverviewTable} from "./BaselineOverviewTable";
import {Alert, Container, Stack, Typography} from "@mui/material";
import {LoadingScreen} from "../../theme/components/LoadingScreen";
import {BaselineSyncDialog} from "./sync/BaselineSyncDialog";
import {BaselineSummarizeTable} from "./baselineSummarizeTable/BaselineSummarizeTable";
import {getCacheBaselineItem, useIdbQueryCacheFallback} from "../state/bssQueryCacheState";
import {useSearchParams} from "react-router-dom";
import {BaselineCsvTable} from "./baselineCsvTable/BaselineCsvTable";
import {BaselineOverviewProvider} from "./resolveView/BaselineOverviewContainer";

export function BaselineScreen() {
    const [query] = useSearchParams()
    const baselineId = query.get('id')

    const {data: onlineData, isFetching} = useBssBaselineDetailQuery({
        baselineId: baselineId
    }, {
        enabled: !!baselineId
    })
    const [data, isOnline, isLoading] = useIdbQueryCacheFallback({
        data: onlineData,
        cacheName: getCacheBaselineItem(baselineId as string)
    })

    if ((isOnline && isFetching) || (!isOnline && isLoading)) {
        return (
            <LoadingScreen/>
        )
    }
    if (!data) {
        return (
            <Alert severity={'warning'}>
                No data found for baseline {baselineId}
            </Alert>
        )
    }
    return (
        <BaselineOverviewProvider data={data} baselineId={baselineId as string}>
            <TssLayout
                title={<Typography variant={"subtitle1"}>{data?.project_by_pk?.name ?? 'Baseline'}</Typography>}
                appBarProps={{
                    sx: {
                        bgcolor: 'success.main'
                    }
                }}
                headerEndSection={
                    <Stack direction={'row'} spacing={2} marginRight={2}>
                        <BaselineCsvTable/>
                        <BaselineSummarizeTable/>
                        <BaselineSyncDialog baselineId={baselineId as string}/>
                    </Stack>
                }
            >
                <Container maxWidth={false}>
                    <BaselineOverviewTable/>
                </Container>
            </TssLayout>
        </BaselineOverviewProvider>
    )
}

