import {
    Alert,
    FormControlLabel,
    Stack,
    Switch,
    TableCell,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {AvailableBaselineTypes, BaselineDataOverviewType} from "../baselineOverviewTypes";
import {getNumberInFormat} from "../../../lib/helpers/numberFormat";
import {BaselineData} from "../../state/bssTypes/bssTypes";
import {CsvTypeName} from "../baselineDataFields";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {CsvDataRowItem} from "./baselineCsvTypes";
import {BaselineCsvCopy} from "./BaselineCsvCopy";
import {headlineTranslationMapping} from "../translationHelper";
import {useBaselineOverviewContext} from "../BaselineOverviewProvider";
import {Livestock_Type_Enum, Project_Bss_Data_Type_Enum} from "../../../lib";
import {BaselineCsvFieldHelper} from "../baselineDataFieldHelper/baselineCsvFieldHelper";
import {useBaselineWorkbook} from "./BaselineCsvWorkbookProvider";

type BaselineCsvDialogContentSectionProps = {
    data: BaselineData
    type: BaselineDataOverviewType
    isEven: boolean
    seasonId?: string
    livestock_type?: Livestock_Type_Enum
    term_id?: string
}

export function BaselineCsvDialogContentSection({
                                                    data,
                                                    isEven,
                                                    type,
                                                    seasonId,
                                                    term_id,
                                                    livestock_type
                                                }: BaselineCsvDialogContentSectionProps) {
    const {t} = useTranslation()

    const {baselineId, wealthGroups} = useBaselineOverviewContext()

    const {term, wealthGroupData} = data
    const availableCsvTypes = BaselineCsvFieldHelper[type]
    const availableTypes = BaselineCsvFieldHelper[type]?.map(i => i.type)
    const [selectedCsvType, setSelectedCsvType] = useState<CsvTypeName | ''>(availableTypes?.[0] ?? '')
    const [titleInline, setTitleInline] = useState(false)
    const selectedFields = availableCsvTypes?.find(i => i.type === selectedCsvType)?.fields
        ?? availableCsvTypes?.[0].fields
    const ctx = useBaselineWorkbook()
    const isSelected = ctx.isSelected({term_id, livestock_type, seasonId, type})
    if (type !== Project_Bss_Data_Type_Enum.LivestockExpenditure && Object.keys(wealthGroupData).every(i => wealthGroupData[i]['non_significant' as AvailableBaselineTypes])) {
        return null // dont render field if all wealth groups are non significant
    }
    if (!availableCsvTypes || !selectedFields) {
        return (
            <TableRow>
                <TableCell colSpan={wealthGroups.length + 1}>
                    <Alert severity={'warning'}>Missing csv section for type {type}</Alert>
                </TableCell>
            </TableRow>
        )
    }
    const csvData: CsvDataRowItem['csv'] = selectedFields.reduce((acc, columnName) => {
        const isObjectKey = typeof columnName === 'object';
        const columnKeyName = isObjectKey ? columnName?.key : columnName
        acc.push([
            t(`rowType.${columnKeyName.replace('__', '')}`),
            ...wealthGroups.map((wealthGroup) => {
                const wealthGroupSpecificData = wealthGroupData[wealthGroup.id];
                if (wealthGroupSpecificData?.['non_significant' as AvailableBaselineTypes]) {
                    return '-'
                }
                if (columnKeyName === '__kcal') {
                    return term?.kcal ?? null
                }
                if (columnKeyName.startsWith('__')) {
                    return null
                }
                const item = (isObjectKey && typeof columnName?.calculator === 'function')
                    ? wealthGroupSpecificData ? columnName.calculator({
                        ...wealthGroupSpecificData,
                        wealthGroupId: wealthGroup.id,
                        baselineId
                    } as any) : null
                    : wealthGroupSpecificData?.[columnKeyName as AvailableBaselineTypes]
                return typeof item === 'number' ? getNumberInFormat(item) : item
            })
        ])
        return acc
    }, [] as CsvDataRowItem['csv'])


    const sectionTitle = type === Project_Bss_Data_Type_Enum.LivestockSale ? t('livestockData.LIVESTOCK_SALE')
        : (term?.en || t(headlineTranslationMapping[type]));
    return (
        <>
            <TableRow sx={{bgcolor: isSelected ? "info.lighter" : isEven ? '#eee' : undefined}}>
                <TableCell sx={{fontWeight: 'bold'}}>
                    {sectionTitle}
                </TableCell>
                <TableCell colSpan={wealthGroups.length} align={'right'}>
                    <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                        <FormControlLabel
                            labelPlacement={"start"}
                            control={<Switch
                                size={"small"}
                                value={titleInline}
                                checked={titleInline}
                                onChange={(_, checked) => setTitleInline(checked)}/>}
                            label={t("titleInline")}/>

                        {(availableTypes?.length ?? 0) > 1 && (
                            <>
                                <ToggleButtonGroup
                                    exclusive={true}
                                    value={selectedCsvType}
                                    size={'small'}
                                    onChange={(_, value) => {
                                        setSelectedCsvType(value || selectedCsvType)
                                    }}>
                                    {availableTypes?.map((t) => {
                                        return (
                                            <ToggleButton value={t} key={t}>
                                                {t}
                                            </ToggleButton>
                                        )
                                    })}
                                </ToggleButtonGroup>
                            </>
                        )}
                        <BaselineCsvCopy
                            csvResolvedData={csvData}
                            selectedCsvType={selectedCsvType as CsvTypeName}
                            type={type}
                            selectedFields={selectedFields}
                            seasonId={seasonId}
                            livestock_type={livestock_type}
                            term_id={term_id}
                            sectionTitle={sectionTitle}
                            titleInline={titleInline}
                        />
                    </Stack>
                </TableCell>
            </TableRow>
            {csvData.map((columns, i) => {
                return (
                    <TableRow key={`${type}__${i}`}
                              sx={{bgcolor: isSelected ? "info.lighter" : isEven ? '#eee' : undefined}}>
                        {columns.map((column, j) => {
                            return (
                                <TableCell key={`${i}_${j}`}>
                                    {column}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                )
            })}
        </>
    )
}