export const getNumberFormat = (value: number | string,
                                countryCode = 'en') => {
    if (!value) return '-'
    const castNumber = Number(value)
    return new Intl.NumberFormat(
        countryCode, {
            maximumFractionDigits: castNumber > 99 ? 0 : 1
        })
        .format(castNumber)
}

export const getNumberInFormat = (value: number | string, countryCode = 'en') => {
    if (!value) return null
    const castNumber = Number(value)
    return new Intl.NumberFormat(
        countryCode, {
            maximumFractionDigits: castNumber > 99 ? 0 : 1
        })
        .format(castNumber)
}
